export const deviceFilterOptions = [
  {text: 'Installed Devices', value: ['_isInstalled', '==', true]},
  {text: 'Not Installed Devices', value: ['_isInstalled', '==', false]},
  {text: 'Labelled Devices', value: ['_isLabelled', '==', true]},
  {text: 'Not Labelled Devices', value: ['_isLabelled', '==', false]},
  {text: 'Label Ready Devices', value: ['_isLabelReady', '==', true]},
  {text: 'Not Label Ready Devices', value: ['_isLabelReady', '==', false]},
  {text: 'Label Required', value: ['_labelRequired', '==', true]},
  {text: 'Label Not Required', value: ['_labelRequired', '==', false]}
];
