export class Product {
  #data;
  #ref;

  /*
 * The following properties/fields array can be used for props that don't need any translation/computation when
 * getting/setting. Basic getters & setters will be created for these on init
 */
  static basicFields = [
    'manufacturer',
    'model',
    'hardwareVersion',
    'softwareVersion',
    'firmwareVersion',
    'serialNumber'];

  constructor(data) {
    this.#data = data || {};

    // dynamically generate standard getters/setters for basic properties
    Product.basicFields.forEach(prop => {
      Object.defineProperty(this, prop, {
        get: function() {
          return this.#data[prop] || '';
        },
        set: function(value) {
          this.#data[prop] = value;
        }
      });
    });
  }

  /**
   * A reference to the firestore document for this device (if it exists)
   *
   * @return {Firebase.Firestore.DocumentReference}
   */
  get firestoreRef() {
    return this.#ref;
  }

  set firestoreRef(ref) {
    this.#ref = ref;
  }

  /**
   *
   * @return {string}
   */
  get title() {
    return this.manufacturer + ' ' + this.model;
  }

  /**
   * The title for the type of this device (e.g. 'Air Handling Unit')
   *
   * @return {string}
   */
  get type() {
    return (this.#data.kind && this.#data.kind.title) || '';
  }

  /**
   * Sets the device type, in the form {title: '', code: ''}
   *
   * @param {Object} typeObj
   */
  set type(typeObj) {
    this.#data.kind = typeObj;
  }

  /**
   * Returns the shortcode for the type of this device (e.g. 'AHU')
   *
   * @return {string}
   */
  get typeCode() {
    return (this.#data.kind && this.#data.kind.code) || '';
  }
}

export const productConverter = {
  /**
   *
   * @param {Product} product
   * @return {dials.firestore.Product}
   */
  toFirestore: (product) => {
    /** @type {dials.firestore.Product} */
    const p = {};
    if (product.type !== '') p.kind = {title: product.type};
    if (product.typeCode !== '') p.kind = Object.assign({}, p.kind, {code: product.typeCode});

    Product.basicFields.forEach(prop =>{
      if (product[prop] !== '') p[prop] = product[prop];
    });

    return p;
  },
  /**
   *
   * @param {QueryDocumentSnapshot} snapshot
   * @param {SnapshotOptions} options
   * @return {Device}
   */
  fromFirestore: (snapshot, options) => {
    /** @type {dials.firestore.Product} */
    const p = snapshot.data(options);
    const prod = new Product(p);
    prod.id = snapshot.id;
    prod.firestoreRef = snapshot.ref;
    return prod;
  }
};
