<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="text-center pa-0">
        <img :src="logo" alt="DIALS" class="ma-5 logo">
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-center">
        <v-progress-circular indeterminate size="48" color="accent"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'Loading',
  computed: {
    ...mapGetters('app', ['loaded']),
    logo() {
      return require('@/assets/logo.svg?as-is');
    },
    redirectTo() {
      const redirect = this.$route.params.redirect;
      return (redirect && redirect !== '') ? {...redirect} : {name: 'Home'};
    }
  },
  watch: {
    loaded: {
      immediate: true,
      handler(l) {
        if (l) {
          this.redirect();
        }
      }
    }
  },
  methods: {
    redirect() {
      this.$logger.debug('redirect', this.redirectTo);
      this.$router.replace(this.redirectTo)
          .catch(err => this.$logger.debug('redirect aborted', err.message));
    }
  }
};
</script>

<style scoped>
  .logo {
    max-height: 150px;
    max-width: calc(90% - 40px); /* 20px margin each side */
  }
  .logo-placeholder {
    height: 150px;
  }
</style>
