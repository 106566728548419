import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"480px","fullscreen":_vm.$vuetify.breakpoint.mobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_vm._t("activator",function(){return [_c(VBtn,_vm._g(_vm._b({attrs:{"outlined":"","color":"red"}},'v-btn',_vm.$attrs,false),on),[_vm._v(" "+_vm._s(_vm.label)+" ")])]},{"on":on})]}}],null,true),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c(VCard,[_c(VCardTitle,[_vm._t("title",function(){return [_vm._v(" Are you sure you want to delete this? ")]})],2),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.open = false}}},[_vm._v("No")]),_c(VBtn,{attrs:{"color":"red"},on:{"click":_vm.confirm}},[_vm._v("Yes")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }