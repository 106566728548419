<template>
  <v-container fluid>
    <v-toolbar flat>
      <v-toolbar-title>Systems for {{ siteName }}</v-toolbar-title>
      <v-spacer/>
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn icon color="secondary" class="mx-2" v-on="on" @click="doRefresh">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
        Refresh the list
      </v-tooltip>
      <v-tooltip bottom v-if="hasSiteAdminAccess">
        <template #activator="{ on }">
          <v-btn class="ml-2" fab depressed small color="secondary" v-on="on" @click="create">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        Add a system to {{ siteName }}
      </v-tooltip>
    </v-toolbar>
    <v-data-table
        :headers="dataHeaders"
        :items="pageItems"
        disable-sort

        :items-per-page="perPage"
        @update:items-per-page="setPerPage"

        :options.sync="options"
        @pagination="onPaginationUpdate"
        :server-items-length="records.length"

        :footer-props="{'items-per-page-options': [25, 50, 100, 200]}"
        :loading="loading">
      <template #item.actions="{ item }" v-if="hasSiteAdminAccess">
        <v-icon
            small
            class="mr-2"
            @click.stop="edit(item)">
          mdi-pencil
        </v-icon>
      </template>

      <template #footer.page-text="{pageStart, pageStop}">
        {{ pageStart }}-{{ pageStop }} of {{ itemCount }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';

export default {
  name: 'SubsystemList',
  data() {
    return {
      dataHeaders: [
        {text: 'Name', value: 'title'},
        {text: 'Shortcode', value: 'id'},
        {text: 'Actions', value: 'actions', sortable: false}
      ],
      options: {},
      loading: false
    };
  },
  computed: {
    ...mapGetters('site/subsystems', ['hasMore']),
    ...mapState('site/subsystems', ['records', 'perPage']),
    ...mapGetters('user', ['siteRefPath', 'siteName']),
    ...mapGetters('user/selectedSite', ['hasSiteAdminAccess']),
    pageItems() {
      const page = this.options.page;
      const perPage = this.perPage;
      const start = (page - 1) * perPage;
      return this.records.slice(start, start + perPage);
    },
    itemCount() {
      const count = this.records.length;
      if (this.hasMore) {
        return `${count}+`;
      }
      return count;
    }
  },
  watch: {
    siteRefPath: {
      immediate: true,
      handler(site) {
        if (site) {
          this.onPaginationUpdate({page: 1, pageCount: 1});
        }
      }
    }
  },
  methods: {
    ...mapActions('site/subsystems', []),
    setPerPage(p) {
      this.$store.commit(`site/subsystems/setPerPage`, p);
    },
    async onPaginationUpdate({page, pageCount}) {
      if (page === pageCount && this.hasMore) {
        this.loading = true;
        try {
          this.$logger.debug('loading next page');
          await this.$store.dispatch(`site/subsystems/nextPage`);
        } catch (e) {
          this.$logger.error('next page', e);
        }
        this.loading = false;
      }
    },
    async doRefresh() {
      this.loading = true;
      try {
        this.$logger.debug('refresh');
        await this.$store.dispatch(`site/subsystems/refresh`);
      } catch (e) {
        this.$logger.error('refresh', e);
      }
      this.loading = false;
    },
    create() {
      this.$root.$emit('newSubsystem');
    },
    edit(subsystem) {
      this.$root.$emit('editSubsystem', subsystem);
    }
  }
};
</script>

<style scoped>

</style>
