import {copyProperties} from '@/util/vuex-firestore-util';

export class User {
  constructor(data, context, site = true) {
    Object.defineProperty(this, '_context', {
      enumerable: false,
      value: context
    });
    Object.defineProperty(this, '_site', {
      enumerable: false,
      value: site
    });
    for (const key of Object.keys(data)) {
      Object.defineProperty(this, key, {
        enumerable: true,
        value: data[key]
      });
    }
    // retain ref + other firestore-related properties
    copyProperties(data, this);
  }

  get isAdmin() {
    return this._site ? this.isSiteAdmin : this.isOrganisationAdmin;
  }

  get isOrganisationAdmin() {
    const organisationRef = this._context.getters['user/organisationRef'];
    if (organisationRef) {
      const access = this.memberships[organisationRef.id];
      if (access) {
        return access.role === 'admin';
      }
    }
    return false;
  }

  get isSiteAdmin() {
    const siteRef = this._context.getters['user/siteRef'];
    if (siteRef) {
      const access = this.sites[siteRef.id];
      if (access) {
        return access.role === 'admin';
      }
    }
    return false;
  }
}
