import {db} from '@/firebase';
import {doc, onSnapshot} from 'firebase/firestore';
import {Logger} from '@vanti/vue-logger';
import DeferUtil from '@/util/vuex-defer';

const log = Logger.get('versions');

const appName = 'app';

export default {
  namespaced: true,
  state: {
    VERSION: process.env.VUE_APP_VERSION || 'local',
    COMMITHASH: process.env.VUE_APP_COMMITHASH || '',
    l: {},
    remoteVersion: {}
  },
  getters: {
    upToDate(state, getters) {
      if (process.env.NODE_ENV === 'development') {
        return true;
      }
      if (!state.remoteVersion || !state.remoteVersion.gitVersion) {
        return true;
      }
      return getters.localVersionName === state.remoteVersion.gitVersion ||
          state.VERSION === state.remoteVersion.gitVersion;
    },
    versionName(state, getters) {
      if (getters.upToDate) {
        if (state.remoteVersion) {
          if (state.remoteVersion.displayName) {
            return state.remoteVersion.displayName;
          }
          if (state.remoteVersion.title) {
            return state.remoteVersion.title;
          }
        }
      }
      return getters.localVersionName;
    },
    localVersionName(state) {
      const slashIndex = state.VERSION.lastIndexOf('/');
      return state.VERSION.substr(slashIndex + 1);
    },
    appName() {
      return appName;
    }
  },
  mutations: {
    ...DeferUtil.mutations(log),
    setRemoteVersion(state, remoteVersion) {
      state.remoteVersion = remoteVersion;
    }
  },
  actions: {
    init: {
      root: true,
      handler({dispatch, rootGetters}) {
        this.watch(
            () => rootGetters['auth/hasAppAccess'],
            async hasAppAccess => {
              try {
                if (hasAppAccess) await dispatch('bind');
                else await dispatch('unbind');
              } catch (e) {
                log.error('bind after authStateChange', e);
              }
            },
            {immediate: true}
        );
      }
    },
    async bind({commit, dispatch, state}) {
      const versionDoc = doc(await db, `versions/${appName}`);
      return new Promise((resolve, reject) => {
        const unbindVersionDoc = onSnapshot(versionDoc,
            doc => {
              const hasRemoteVersion = Object.keys(state.remoteVersion || {}).length > 0;
              commit('setRemoteVersion', doc.data());

              // if this isn't the first time we've seen the remote version (i.e. we assume it's an update)
              if (hasRemoteVersion) {
                if (this.hasModule('serviceWorker')) {
                  dispatch('serviceWorker/update', null, {root: true})
                      .catch(err => log.warn('during service worker update after version change', err));
                }
              }
            },
            error => reject(error));

        commit('defer', {versionDoc: unbindVersionDoc});
      });
    },

    unbind({commit}) {
      commit('reset');
    }
  }
};
