import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from './views/Home.vue';

import Loading from '@/core/views/Loading';
import RouterWrapper from '@/core/components/RouterWrapper';

import DeviceList from '@/site/views/DeviceList';
import ProductList from '@/site/views/ProductList';
import Contractors from '@/users/views/Contractors';
import Users from '@/users/views/Users';
import NavBarSearch from '@/site/components/NavBarSearch';
import SiteDialogs from '@/site/components/SiteDialogs';
import DeviceSidebar from '@/site/components/DeviceSidebar';
import LocationList from '@/site/views/LocationTree';
import SubsystemList from '@/site/views/SubsystemList';
import MapView from '@/site/views/MapView';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'login',
    meta: {title: 'Login'},
    component() {
      return import(/* webpackChunkName: "login" */ '@/core/views/Login.vue');
    }
  },
  {
    path: '/loading',
    name: 'loading',
    component: Loading
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/site/:siteId',
    meta: {title: '{Site}'},
    name: 'site',
    components: {
      default: RouterWrapper,
      navbar: NavBarSearch,
      dialogs: SiteDialogs
    },
    children: [
      {
        path: 'map',
        name: 'site-map',
        meta: {title: 'Map | {Site}'},
        component: MapView,
        children: [
          {
            name: 'site-map-device',
            meta: {title: 'Map | {Site}'},
            path: ':id',
            component: DeviceSidebar
          }
        ]
      },
      {
        path: 'devices',
        name: 'site-devices',
        meta: {title: 'Devices | {Site}'},
        component: DeviceList,
        children: [
          {
            name: 'site-device',
            meta: {title: 'Devices | {Site}'},
            path: ':id',
            component: DeviceSidebar
          }
        ]
      },
      {
        path: 'products',
        name: 'site-products',
        meta: {title: 'Products | {Site}'},
        component: ProductList
      },
      {
        path: 'locations',
        name: 'site-locations',
        meta: {title: 'Locations | {Site}'},
        component: LocationList
      },
      {
        path: 'subsystems',
        name: 'site-subsystems',
        meta: {title: 'Systems | {Site}'},
        component: SubsystemList
      },
      {
        path: 'users',
        name: 'site-users',
        meta: {title: 'Users | {Site}'},
        component: Users
      },
      {
        path: 'contractors',
        name: 'site-contractors',
        meta: {title: 'Contractors | {Site}'},
        component: Contractors
      }
    ]
  },
  {
    path: '/organisations/:organisationId',
    name: 'organisation',
    meta: {title: '{Organisation}'},
    component: RouterWrapper,
    children: [
      {
        path: 'users',
        name: 'organisation-users',
        meta: {title: 'Users | {Organisation}'},
        component: Users
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});


export default router;
