<template>
  <v-dialog v-model="open" max-width="480" :fullscreen="$vuetify.breakpoint.mobile">
    <template #activator="{on}">
      <v-tooltip bottom>
        <template #activator="{ on: onTooltip }">
          <v-btn fab depressed small color="secondary" v-on="{...on, ...onTooltip}">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        Add a User to {{ groupTitle }}
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title>Add a User to {{ groupTitle }}</v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-text-field
                label="Email"
                @change="setEmail"
                :value="form.email"
                :loading="existingUserSearch"
                :error-messages="emailErrors"/>
          </v-row>
          <v-row>
            <v-text-field label="Name" v-model="title" :disabled="existingUser !== null"/>
          </v-row>
          <v-row>
            <v-text-field label="Job Role" v-model="jobRole" :disabled="existingUser !== null"/>
          </v-row>
          <v-row>
            <v-checkbox :label="`Admin Access to ${groupTitle}`" v-model="form.admin"/>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
            @click="open = false"
            :disabled="loading"
            text>
          Cancel
        </v-btn>
        <v-btn
            color="secondary"
            :loading="loading"
            :disabled="!valid"
            @click="add">
          Add
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {validationMixin} from 'vuelidate';
import {email, required} from 'vuelidate/lib/validators';
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'UserAdd',
  mixins: [validationMixin],
  props: {
    groupTitle: {
      type: String,
      default: ''
    },
    org: {
      type: Boolean,
      default: false
    },
    site: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      open: false,
      loading: false,
      form: {
        admin: false,
        email: null,
        title: null,
        jobRole: null
      },
      existingUserSearch: false,
      existingUser: null
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      }
    }
  },
  computed: {
    ...mapGetters('user', ['siteRef', 'organisationRef']),
    ...mapGetters('user', {
      selectedSite: 'selectedSite/site',
      selectedOrganisation: 'selectedOrganisation/organisation'
    }),
    email() {
      return this.form.email;
    },
    emailErrors() {
      const email = this.$v.form.email;
      if (email.$dirty && email.$invalid) {
        if (!email.required) {
          return ['Email is required'];
        }
        if (!email.email) {
          return ['Please enter a valid email address'];
        }
      }
      if (this.alreadyAdded) {
        return [`This user already has access to ${this.groupTitle}`];
      }
      return [];
    },

    title: {
      get() {
        return (this.existingUser && this.existingUser.title) || this.form.title;
      },
      set(t) {
        this.form.title = t;
      }
    },
    jobRole: {
      get() {
        return (this.existingUser && this.existingUser.jobRole) || this.form.jobRole;
      },
      set(t) {
        this.form.jobRole = t;
      }
    },
    alreadyAdded() {
      if (!this.existingUser) {
        return false;
      }
      if (this.site) {
        const siteId = this.siteRef.id;
        const sites = this.existingUser.sites || {};
        return sites.hasOwnProperty(siteId);
      }
      if (this.org) {
        const orgId = this.organisationRef.id;
        const memberships = this.existingUser.memberships || {};
        return memberships.hasOwnProperty(orgId);
      }
      return false;
    },
    valid() {
      if (this.existingUserSearch) {
        return false;
      }
      if (this.alreadyAdded) {
        return false;
      }
      return !this.$v.$invalid;
    },

    newUser() {
      if (this.existingUser) {
        return null;
      }
      const user = {};
      const props = ['title', 'jobRole', 'email'];
      for (const prop of props) {
        if (this.form[prop] !== null) {
          user[prop] = this.form[prop];
        }
      }
      if (this.site) {
        const siteId = this.siteRef.id;
        user.sites = {
          [siteId]: {
            role: this.form.admin ? 'admin' : 'user',
            site: {
              ref: this.siteRef.path
            }
          }
        };
      } else if (this.org) {
        const orgId = this.organisationRef.id;
        user.memberships = {
          [orgId]: {
            role: this.form.admin ? 'admin' : 'user',
            organisation: {
              ref: this.organisationRef.path
            }
          }
        };
      }
      return user;
    },
    updates() {
      const updates = {};

      if (this.site) {
        const siteId = this.siteRef.id;
        updates[`sites.${siteId}`] = {
          role: this.form.admin ? 'admin' : 'user',
          site: {
            ref: this.siteRef,
            title: this.selectedSite.title
          }
        };
      } else if (this.org) {
        const orgId = this.organisationRef.id;
        updates[`memberships.${orgId}`] = {
          role: this.form.admin ? 'admin' : 'user',
          organisation: {
            ref: this.organisationRef,
            title: this.selectedOrganisation.title
          }
        };
      }
      return updates;
    }
  },
  watch: {
    open(o) {
      if (!o) {
        this.form.email = null;
        this.form.title = null;
        this.form.jobRole = null;
        this.existingUserSearch = false;
        this.existingUser = null;
      }
    }
  },
  methods: {
    ...mapActions('users', ['userByEmail', 'updateUser', 'createUser']),
    setEmail(e) {
      this.form.email = e;
      this.$v.form.email.$touch();
      this.checkExistingUser();
    },
    async checkExistingUser() {
      if (this.email && !this.$v.form.email.$invalid) {
        this.existingUserSearch = true;
        try {
          this.existingUser = await this.userByEmail({email: this.email});
        } catch (e) {
          this.$logger.error('userByEmail', e);
        } finally {
          this.existingUserSearch = false;
        }
      }
    },
    async add() {
      this.loading = true;
      try {
        if (this.existingUser) {
          await this.updateUser({ref: this.existingUser.ref, updates: this.updates});
        } else {
          await this.createUser({email: this.form.email, props: this.newUser});
        }
        this.open = false;
      } catch (e) {
        this.$logger.error('add', e);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>

</style>
