<template>
  <v-container fluid :class="{'is-mobile': $vuetify.breakpoint.mobile}">
    <template v-if="loaded">
      <v-toolbar flat>
        <v-toolbar-title class="d-flex flex-column">
          <span>{{ floor && floor.title || 'Map' }} at {{ siteName }}</span>
          <span class="grey--text text-subtitle-2">Showing {{ deviceMarkers.length }} devices</span>
        </v-toolbar-title>
        <v-spacer/>
        <v-select
            v-if="!$vuetify.breakpoint.mobile"
            v-model="selectedFloorId"
            hide-details
            :items="floorsWithPlan"
            item-value="id"
            item-text="title"
            class="floor-selector"/>
        <device-filters tooltip="Device filters" store-path="site/floors/selected/devices" floor-view/>
      </v-toolbar>
      <div v-if="floorPlan && floor" class="floor-plan__container">
        <v-progress-linear indeterminate v-if="loading"/>
        <pinch-zoom>
          <template #default="{ scale }">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-html="floorPlan" ref="svgContainer" :style="{'--map-scale': scale, ...floorPlanStyles}"/>
          </template>
        </pinch-zoom>
      </div>
      <v-select
          v-if="$vuetify.breakpoint.mobile"
          v-model="selectedFloorId"
          hide-details
          :items="floorsWithPlan"
          item-value="id"
          item-text="title"
          class="floor-selector"/>
    </template>
    <template v-else>
      <v-row>
        <v-col cols="12" class="text-center">
          <v-progress-circular indeterminate size="48" color="accent"/>
        </v-col>
      </v-row>
    </template>
    <router-view/>
  </v-container>
</template>

<script>

import DeviceFilters from '@/site/components/DeviceFilters';
import PinchZoom from '@/site/components/map/PinchZoom';
import {addTap, removeTap} from '@/util/tap';
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';

/**
 * mdi-map-marker with the point at 0,0 and a filled white circle
 *
 * @see https://materialdesignicons.com/icon/map-marker
 * @type {string}
 */
// eslint-disable-next-line max-len
const mapMarker = `<path d="m -3.774e-4,-10.497437 a 2.5,2.5 0 0 1 -2.5000001,-2.5 2.5,2.5 0 0 1 2.5000001,-2.5 2.5,2.5 0 0 1 2.4999999,2.5 2.5,2.5 0 0 1 -2.4999999,2.5 m 0,-9.5 a 7,7 0 0 0 -7,7 c 0,5.2500001 7,13.00000005 7,13.00000005 0,0 6.9999999,-7.74999995 6.9999999,-13.00000005 a 7,7 0 0 0 -6.9999999,-7 z" />
<ellipse ry="2.5155005" rx="2.5623755" cy="-13.000001" cx="2.8855404e-11" style="fill:#ffffff;stroke:none;" />`;

export default {
  name: 'MapView',
  components: {PinchZoom, DeviceFilters},
  data() {
    return {
      loading: false,
      g: null,
      selectedDeviceMarker: null
    };
  },
  computed: {
    ...mapGetters('user', ['siteRefPath', 'siteName']),
    ...mapGetters('site/floors', ['floorsWithPlan', 'loaded']),
    ...mapGetters('site/floors/selected', ['floor', 'floorPlan', 'mapMarkerScale', 'mapMarkerScaleMobile']),
    ...mapGetters('site/floors/selected/devices', ['asDevices', 'whereConstraints']),
    ...mapState('site/floors', ['floorPlansById']),
    ...mapState('site/floors/selected', {currentFloorId: 'id'}),
    ...mapGetters('site/devices/selected', ['selectedDevice']),
    floorPlanStyles() {
      return {
        '--map-marker-scale-factor': this.mapMarkerScale || 100,
        '--map-marker-scale-factor-mobile': this.mapMarkerScaleMobile || 500
      };
    },
    deviceMarkers() {
      return this.asDevices
          .filter(d => d.planLocation && d.planLocation.centre)
          .map(d => {
            if (d.planLocation.element) {
              // interpret the element string within the context of an SVG
              const temp = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
              temp.innerHTML = d.planLocation.element;
              return temp.firstChild;
            }
            const {x, y} = d.planLocation.centre;
            const marker = document.createElementNS('http://www.w3.org/2000/svg', 'g');
            marker.setAttribute('id', d.uuid); // used to identify the device on tap
            // these values are used by CSS to perform the transform (translate and scale)
            marker.style.setProperty('--translate-x', `${x}px`);
            marker.style.setProperty('--translate-y', `${-y}px`);
            marker.innerHTML = mapMarker;
            return marker;
          });
    },
    selectedFloorId: {
      get() {
        return this.currentFloorId;
      },
      set(v) {
        this.setSelected(v);
      }
    }
  },
  watch: {
    '$route.name': {
      immediate: true,
      handler(n, o) {
        this.$logger.debug('$route.name', {n, o});
        if (n === 'site-map' && o === 'site-map-device') {
          this.onDeviceSelect(null);
        } else if (n === 'site-map-device') {
          // get device floor
          const device = this.selectedDevice;
          this.$logger.debug('device', device);
          if (device && device.parentLocationId) {
            this.$logger.debug('device.parentLocationId', device.parentLocationId);
          }
          if (device && device.parentLocationId) {
            this.setSelected(device.parentLocationId);
          }
        }
      }
    },
    'deviceMarkers': {
      immediate: true,
      handler() {
        this.addDeviceMarkers();
      }
    },
    'floorPlan': {
      immediate: true,
      handler() {
        this.$nextTick(() => this.addDeviceMarkers());
      }
    },
    'floorPlansById': {
      immediate: true,
      handler() {
        if (!this.floor) {
          const ids = Object.keys(this.floorPlansById);
          if (ids.length > 0) {
            this.setSelected(ids[0]);
          }
        }
      }
    },
    'selectedFloorId': {
      immediate: true,
      handler() {
        this.$nextTick(() => this.setFloorFilters());
      }
    },
    whereConstraints() {
      if (this.loading) return;
      this.loadDevices();
    }
  },
  methods: {
    ...mapMutations('site/floors/selected', ['setSelected']),
    ...mapMutations('site/floors/selected/devices', ['setFilters', 'clear']),
    ...mapActions('site/floors/selected/devices', ['nextPage', 'refresh']),
    ...mapMutations('site/floors/selected/devices', ['setPerPage', 'setFilters']),
    setFloorFilters() {
      this.clear();
      if (!this.floor) return;
      this.setFilters([
        ['_location_ancestors', 'array-contains', this.floor.ref]
      ]);
      this.loadDevices();
    },
    addDeviceMarkers() {
      if (this.g) {
        this.g.remove();
        this.g = null;
      }
      if (!this.$refs.svgContainer) return;
      const svg = this.$refs.svgContainer.children[0];
      const g = document.createElementNS('http://www.w3.org/2000/svg', 'g');
      g.classList.add('devices');
      let selectedDeviceMarker;
      for (const marker of this.deviceMarkers) {
        if (marker.id === this.selectedDevice?.uuid) {
          selectedDeviceMarker = marker;
        } else {
          g.appendChild(marker);
        }
      }
      if (selectedDeviceMarker) {
        g.appendChild(selectedDeviceMarker);
        this.onDeviceSelect(selectedDeviceMarker);
      }
      if (this.deviceMarkers.length > 0) {
        svg.appendChild(g);
        this.g = g;
        addTap(g, e => {
          const path = e.composedPath() || [];
          for (const el of path) {
            if (el.id) {
              this.onDeviceSelect(el);
              break;
            }
          }
        });
      }
    },
    cleanupDeviceMarkers() {
      if (this.g) {
        this.g.remove();
        removeTap(this.g);
      }
    },
    onDeviceSelect(el) {
      this.$logger.debug('onDeviceSelect:', el && el.id);
      if (this.selectedDeviceMarker) {
        this.selectedDeviceMarker.classList.remove('selected');
      }
      if (this.selectedDeviceMarker === el || !el) {
        this.selectedDeviceMarker = null;
        this.$router.push({name: 'site-map'});
      } else {
        el.classList.add('selected');

        this.selectedDeviceMarker = el;
        this.$router.push({name: 'site-map-device', params: {id: el.id}});
      }
    },
    async loadDevices() {
      this.loading = true;
      this.clear();
      try {
        this.$logger.debug('loading next page');
        await this.nextPage();
      } catch (e) {
        this.$logger.error('next page', e);
      }
      this.loading = false;
    }
  }
};
</script>

<style scoped>
.container {
  height: 100%;
  position: relative;
}

.floor-selector {
  flex: unset;
}

.is-mobile .floor-selector {
  position: absolute;
  left: 16px;
  bottom: 16px;
  max-width: 60vw;
}

.floor-plan__container {
  position: relative;
  /* fill the container, minus the toolbar */
  height: calc(100% - 64px);
}

.floor-plan__container .pinch-zoom {
  /* fill the container so that zoom controls show in the bottom-right */
  height: 100%;
  overflow: hidden;
}

.v-progress-linear {
  position: absolute;
}

.floor-plan__container >>> svg .devices {
  fill: var(--v-primary-base);
  cursor: pointer;
}

.pinch-zoom {
  /* defaults, overridden in the template & deviceMarkers() */
  --map-scale: 1;
  --translate-x: 0;
  --translate-y: 0;
  /* todo: this may need to be set depending on svg scale */
  --map-marker-scale-factor: 100;
}

.is-mobile .pinch-zoom {
  --map-marker-scale-factor: var(--map-marker-scale-factor-mobile);
}

.floor-plan__container >>> svg .devices > g {
  /* do 1 / map-scale so that marker size is the same regardless of map scale */
  --map-scale-value: calc(var(--map-marker-scale-factor) / var(--map-scale));
  transform: translate(var(--translate-x), var(--translate-y)) scale(var(--map-scale-value));
}

.floor-plan__container >>> svg .devices .selected {
  fill: var(--v-warning-base);
}
</style>
