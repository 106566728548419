<template>
  <v-menu
      ref="dateMenu"
      v-model="open"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto">
    <template #activator="{ on, attrs }">
      <v-text-field
          v-model="date"
          :label="label"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"/>
    </template>
    <v-date-picker
        v-model="date"
        no-title
        scrollable>
      <v-spacer/>
      <v-btn
          text
          color="primary"
          @click="cancel">
        Cancel
      </v-btn>
      <v-btn
          text
          color="primary"
          @click="save">
        OK
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: 'MenuDatePicker',
  props: {
    label: {
      type: String,
      default: 'Date'
    },
    value: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      local: null,
      open: false
    };
  },
  computed: {
    date: {
      get() {
        if (this.local) {
          return this.local;
        }
        return this.value;
      },
      set(v) {
        this.local = v;
      }
    }
  },
  methods: {
    cancel() {
      this.local = null;
      this.open = false;
    },
    save() {
      if (this.local) {
        this.$emit('input', this.local);
      }
      this.open = false;
      this.local = null;
    }
  }
};
</script>

<style scoped>

</style>
