import {Logger} from '@vanti/vue-logger';
import {pagedListStore} from '@/util/vuex-firestore-paged-list';
import {addDoc, collection, getDocs, query, updateDoc, where} from 'firebase/firestore';
import {Product, productConverter} from '@/site/Product';

const log = Logger.get(`site/store/products`);
const pagedList = pagedListStore({}, log);

export default {
  namespaced: true,
  state: {
    ...pagedList.state
  },
  getters: {
    ...pagedList.getters,
    whereConstraints(state) {
      return [];
    },

    asProducts(state) {
      return state.records.map(r => {
        const p = new Product(r);
        p.firestoreRef = r.ref;
        return p;
      });
    }
  },
  mutations: {
    ...pagedList.mutations
  },
  actions: {
    ...pagedList.actions,
    async collection({rootGetters}) {
      const siteRef = rootGetters['user/siteRef'];
      return collection(siteRef, 'products');
    },

    /**
     *
     * @param {*} context
     * @param {Product} product
     * @return {Promise<firebase.firestore.DocumentReference>}
     */
    async createProduct({dispatch, state, rootGetters}, product) {
      const siteRef = rootGetters['user/siteRef'];
      const col = collection(siteRef, 'products').withConverter(productConverter);
      const ref = await addDoc(col, product);
      await dispatch('refresh');
      return ref;
    },
    /**
     *
     * @param {*} context
     * @param {Product} product
     * @return {Promise<void>}
     */
    async updateProduct({dispatch}, product) {
      await updateDoc(product.firestoreRef, product);

      // see if this product is in the store, and update that copy
      dispatch('updateRecord', product.firestoreRef);
    },

    async searchKeyword({state, rootGetters, dispatch}, keyWord) {
      const col = await dispatch('collection');
      log.debug(`searching for '${keyWord}' in ${col.path}`);
      const q = query(col, where('_keywords', 'array-contains', keyWord.toLowerCase()));
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs;
    }
  }
};
