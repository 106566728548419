<template>
  <v-card>
    <v-card-title color="secondary">{{ addNew ? 'Add' : 'Edit' }} Location</v-card-title>
    <v-card-text>
      <v-form @submit="submit">
        <v-container>
          <v-row>
            <v-text-field
                v-model="title"
                label="Name"
                class="mr-4"/>
            <v-text-field
                v-model="architecturalReference"
                label="Architectural Reference"
                class="mr-4"/>
            <v-text-field
                v-model="description"
                label="Description"
                class="mr-4"/>
          </v-row>
          <v-row>
            <v-autocomplete
                v-model="parentLocationRefPath"
                auto-select-first
                hide-details
                no-filter
                @update:search-input="locationSearch"
                :loading="locations.loading"
                label="Parent Location (type to search)"
                no-data-text="No locations found"
                :items="locations.items"
                :item-text="locationTitle"
                :item-value="l => l.ref.path"/>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <confirmation-dialog @confirm="remove" v-if="!addNew">
        <template #title>
          Are you sure you want to delete {{ title }}?
        </template>
      </confirmation-dialog>
      <v-spacer/>
      <v-btn @click="cancel" text>Cancel</v-btn>
      <v-btn @click="submit" color="secondary" :disabled="!canSubmit">Submit</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {decorateSnapshot} from '@/util/vuex-firestore-util';
import {debounce} from 'lodash';
import {mapActions} from 'vuex';
import ConfirmationDialog from '@/site/components/ConfirmationDialog';

export default {
  name: 'LocationForm',
  components: {ConfirmationDialog},
  props: {
    location: {
      type: Object,
      default: () => {
        return {};
      }
    },
    parent: {
      type: Object,
      default: null
    },
    addNew: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      data: {},
      locations: {
        items: [],
        loading: false
      }
    };
  },
  computed: {
    title: {
      get() {
        return this.location.title;
      },
      set(v) {
        this.$set(this.data, 'title', v);
      }
    },
    architecturalReference: {
      get() {
        return this.location.architecturalReference;
      },
      set(v) {
        this.$set(this.data, 'architecturalReference', v);
      }
    },
    description: {
      get() {
        return this.location.description;
      },
      set(v) {
        this.$set(this.data, 'description', v);
      }
    },
    parentLocationRefPath: {
      get() {
        let ref;
        if (this.data.parentLocation && this.data.parentLocation.ref) {
          ref = this.data.parentLocation.ref;
        } else {
          ref = this.location.parentLocationRef;
        }

        if (ref) {
          return ref.path;
        }
        return undefined;
      },
      set(ref) {
        const location = this.locations.items.find(p => p.ref.path === ref);
        if (!location) return;
        const snippet = {
          ...location,
          ref: location.ref
        };
        delete snippet._keywords;
        this.$set(this.data, 'parentLocation', snippet);
      }
    },
    parentLocationSnippet() {
      if (this.data.parentLocation) {
        return this.data.parentLocation;
      }
      return this.location.parentLocationSnippet;
    },
    canSubmit() {
      return Object.values(this.data).length > 0;
    }
  },
  watch: {
    parentLocationRefPath: {
      immediate: true,
      handler() {
        if (this.parentLocationRefPath && this.locations.items.length === 0) {
          this.locations.items.push(this.parentLocationSnippet);
        }
      }
    }
  },
  mounted() {
    if (this.parent) {
      this.$set(this.data, 'parentLocation', {
        ref: this.parent.ref,
        title: this.parent.title
      });
    }
  },
  methods: {
    ...mapActions('site/locations', {
      searchLocationByKeyword: 'searchKeyword'
    }),
    locationSearch(val) {
      if (!val || val.length < 2) {
        return;
      }
      this.debounceDoLocationSearch(val);
    },
    debounceDoLocationSearch: debounce(function(val) {
      // eslint-disable-next-line no-invalid-this
      this.doLocationSearch(val);
    }, 200),
    async doLocationSearch(val) {
      // Items have already been requested
      if (this.locations.loading) return;
      this.locations.loading = true;
      try {
        const results = await this.searchLocationByKeyword(val);
        const locations = results.map(decorateSnapshot);
        if (this.locationSnippet) {
          locations.push(this.locationSnippet);
        }
        this.locations.items = locations;
        this.$logger.debug('search', this.locations.items);
      } catch (e) {
        this.$logger.error('search', e);
      } finally {
        this.locations.loading = false;
      }
    },
    submit() {
      // pre-populate computed prop
      this.data._hasParent = Boolean(this.data.parentLocation);
      if (this.addNew) {
        this.$emit('create', this.data);
      } else {
        // update needs the ref
        Object.defineProperty(this.data, 'ref', {value: this.location.ref});
        this.$emit('update', this.data);
      }
      this.$emit('close', {});
    },
    remove() {
      this.$emit('delete', this.location);
      this.$emit('close', {});
    },
    cancel() {
      this.$emit('close', {});
    },
    locationTitle(location) {
      let title = location.title;
      if (location.parentLocation && location.parentLocation.title) {
        title += ` (${location.parentLocation.title})`;
      }
      return title;
    }
  }
};
</script>

<style scoped>

</style>
