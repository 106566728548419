<template>
  <v-card class="py-2" :class="{'small-label': isSmallLabel}">
    <v-card-title>
      <span>Print {{ devices.length }} Label{{ devices.length > 1 ? 's' : '' }}</span>
      <v-spacer/>
      <v-btn icon @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <div v-if="!validUuid" style="color: red; text-align: center;">
      <v-icon color="red">mdi-alert</v-icon>
      Invalid GUID detected
    </div>
    <v-container
        id="label"
        class="pa-6 mt-2">
      <span class="device-name">{{ previewDeviceName }}</span>
      <span class="location">{{ previewDeviceLocation }}</span>
      <vue-qr-code
          class="qrcode"
          :value="previewDeviceQrCode"
          :margin="0"/>
    </v-container>
    <v-card-actions>
      <v-btn-toggle mandatory v-model="isSmallLabel" v-if="smallLabelOption && largeLabelOption">
        <v-btn :value="true">Small</v-btn>
        <v-btn :value="false">Large</v-btn>
      </v-btn-toggle>
      <v-spacer/>
      <v-btn icon small @click="preview--" :disabled="preview === 0">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <span>{{ preview + 1 }}/{{ devices.length }}</span>
      <v-btn icon small @click="preview++" :disabled="preview === devices.length - 1">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      <v-spacer/>
      <v-btn color="secondary" @click="exportCsv">Export</v-btn>
      <!--      <v-btn color="primary" @click="printLabel()">Print</v-btn> -->
    </v-card-actions>
  </v-card>
</template>

<script>
import {encodeCsvField} from '@/util/csv';
import {downloadFile} from '@/util/files';
import * as uuid from '@/util/uuid.mjs';
import * as qrcode from 'qrcode';
import VueQrCode from 'vue-qrcode';

export default {
  name: 'LabelPrinter',
  components: {
    VueQrCode
  },
  props: {
    /** @type {Device[]} */
    devices: {
      type: Array,
      default: () => []
    },
    labelSizes: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      smallLabel: true,
      preview: 0
    };
  },
  computed: {
    largeLabelOption() {
      return !this.labelSizes || this.labelSizes.includes('large');
    },
    smallLabelOption() {
      return !this.labelSizes || this.labelSizes.includes('small');
    },
    isSmallLabel: {
      get() {
        if (this.largeLabelOption && this.smallLabelOption) {
          return this.smallLabel;
        }
        return !this.largeLabelOption;
      },
      set(v) {
        this.smallLabel = v;
      }
    },
    validUuid() {
      return this.devices.every(d => uuid.validate(d.uuid) && d.uuid !== uuid.NIL);
    },
    previewDevice() {
      return this.devices[this.preview];
    },
    previewDeviceName() {
      return this.getName(this.previewDevice);
    },
    previewDeviceLocation() {
      return this.previewDevice.location;
    },
    previewDeviceQrCode() {
      return this.getQRPayload(this.previewDevice);
    }
  },
  methods: {
    getName(device) {
      if (this.isSmallLabel) return device.shortTitle;
      return device.title;
    },
    getQRPayload(device) {
      let str = device.compressedUuid;
      if (!this.isSmallLabel) {
        str = JSON.stringify({
          guid: device.compressedUuid,
          location: device.location,
          device: device.title
        });
      }
      return str;
    },
    async exportCsv() {
      const rows = [
        ['Code', 'Location', 'Floor', 'Label Type', 'Label Description', 'QR Code Data']
      ];
      for (const device of this.devices) {
        rows.push([
          device.title,
          device.location || '',
          device.parentLocation || '',
          device._labelType? device._labelType.title || '' : '',
          device._labelType? device._labelType.description || '' : '',
          this.getQRPayload(device)
        ]);
      }
      const content = rows.map(r => r.map(encodeCsvField).join(',')).join('\r\n');
      downloadFile('labels.csv', content, 'data:text/csv');
    },
    printLabel() {
      const popup = window.open('', '_blank', '');
      const classes = [];
      if (this.isSmallLabel) {
        classes.push('small-label');
      }
      let doc = `
<html>
  <head>
    <title>Label Printing | ${this.devices[0].title}</title>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900">
  </head>
  <body class="${classes.join(' ')}">
`;
      // doing this here rather than waiting for the qrcode image later on so that we can guarantee the order
      this.devices.forEach(device => {
        doc += `<div class="container">
    <span class="device-name">${this.getName(device)}</span>
    <span class="location">${device.location}</span>
    <img class="qrcode" id="${device.uuid}"/>
  </div>
`;
      });

      doc += `
  </body>
  <style>
  html {
    padding: 0;
    margin: 0;
    font-family: "Roboto", sans-serif;
    font-size: 3.2mm;
    line-height: 4.8mm;
  }
  body {
    margin: 0;
    padding: 0;
  }
  .container {
    margin-top: 1px;
    height: 40mm;
    width: 70mm;
    position: relative;
  }
  .small-label .container {
    height: 19mm;
    width: 38mm;
  }

  img {
    max-height: 100%;
  }

  .device-name {
    display:block;
    position: absolute;
    top: 2mm;
    left: 2mm;
    max-width: 28mm;
    overflow-wrap: break-word;
    max-height: 20mm;
    overflow: hidden;
  }
  .location {
    display:block;
    position: absolute;
    bottom: 2mm;
    left: 2mm;
    max-width: 28mm;
    overflow-wrap: break-word;
  }

  .qrcode {
    position: absolute;
    right: 1mm;
    top: 2.5mm;
    height: 35mm;
    width: 35mm;
  }

  .smallLabel .qrcode {
    top: 1mm;
    height: 17mm;
    width: 17mm;
  }

  /*@media print {*/
  /*  .container { page-break-after: always; }*/
  /*}*/

  </style>
</html>
      `;

      popup.document.write(doc);

      const opts = {
        quality: 1,
        margin: 1
      };

      const promises = [];

      this.devices.forEach(device => {
        promises.push(qrcode.toDataURL(this.getQRPayload(device), opts)
            .then(data => {
              const img = popup.document.getElementById(device.uuid);
              img.src = data;
            })
        );
      });

      Promise.all(promises)
          .then(() => {
            popup.focus();
            popup.print();
            popup.close();
          });
    }
  }
};
</script>

<style scoped>
#label {
  position: relative;
  height: 200px;
  width: 350px;
  border: 1px solid #000;
  border-radius: 3px;
}

.small-label #label {
  height: 98px;
  width: 196px;
}

img {
  max-height: 100%;
}

.device-name {
  display: block;
  position: absolute;
  top: 10px;
  left: 10px;
  max-width: 140px;
  overflow-wrap: break-word;
  max-height: 100px;
  overflow: hidden;
}

.location {
  display: block;
  position: absolute;
  bottom: 10px;
  left: 10px;
  max-width: 140px;
  overflow-wrap: break-word;
}

.qrcode {
  position: absolute;
  right: 5px;
  top: 7px;
  height: 175px;
  width: 175px;
}

.small-label .qrcode {
  top: 2px;
  height: 90px;
  width: 90px;
}
</style>
