import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"400","fullscreen":_vm.$vuetify.breakpoint.mobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"outlined":"","text":""}},'v-btn',_vm.$attrs,false),on),[_vm._v(" Change Password ")])]}}]),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" Update Password "),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.open = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[(!_vm.linkSent)?[_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.sendResetLink}},[_vm._v("Send reset email")])]:[_c('div',{staticClass:"mb-2"},[_vm._v("Follow the link in the email to update your password.")]),(_vm.linkTimeout)?[_c('div',[_vm._v("Don't have the email? Try again.")]),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.sendResetLink}},[_vm._v("Resend email")])]:_vm._e()]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }