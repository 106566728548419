import {deleteField, doc, updateDoc} from 'firebase/firestore';
import {db} from '@/firebase';
import {decorateSnapshot} from '@/util/vuex-firestore-util';
import {Logger} from '@vanti/vue-logger';
import DeferUtil from '@/util/vuex-defer';

import selectedOrganisation from '@/user/store/selected-organisation';
import selectedSite from '@/user/store/selected-site';
import {compareByPath} from '@/util/compare';
import router from '@/core/router';
import {awaitFirstSnapshot} from '@/util/await-snapshot';

const log = Logger.get('user/store');

export default {
  namespaced: true,
  state: {
    user: {},
    site: null,
    organisation: null
  },
  getters: {
    userDocLoaded(state) {
      return Object.keys(state.user).length > 0;
    },
    displayName(state) {
      const u = state.user || {};
      if (u.hasOwnProperty('displayName')) {
        return u.displayName;
      }
      return u.title;
    },
    organisationName(state) {
      return state.organisation && state.organisation.title;
    },
    organisationRef(state) {
      return state.organisation && state.organisation.ref;
    },
    organisationRefPath(state, getters) {
      return getters.organisationRef && getters.organisationRef.path;
    },
    siteName(state) {
      return state.site && state.site.title;
    },
    siteRef(state) {
      return state.site && state.site.ref;
    },
    siteRefPath(state, getters) {
      return getters.siteRef && getters.siteRef.path;
    },

    memberships(state) {
      return state.user.memberships || {};
    },
    sites(state) {
      return state.user.sites || {};
    },

    /**
     * User organisation memberships split into owners and contractors.
     *
     * @type {dials.firestore.OrganisationRole[]}
     */
    userOrganisations(state, getters) {
      return Object.values(getters.memberships)
          .sort((a, b) => compareByPath(a, b, 'organisation.title'));
    },
    userOrganisationRefs(state, getters) {
      return getters.userOrganisations
          .map(m => m.organisation && m.organisation.ref)
          .filter(Boolean);
    },
    userSites(state, getters) {
      return Object.values(getters.sites)
          .sort((a, b) => compareByPath(a, b, 'site.title'));
    },
    userSiteRefs(state, getters) {
      return Object.values(getters.sites)
          .map(s => s.site && s.site.ref)
          .filter(Boolean);
    },

    userPreferences(state) {
      return state.user.preferences || {};
    },
    userPreferredOrganisation(state, getters) {
      return getters.userPreferences.preferredOrganisation;
    },
    userPreferredSite(state, getters) {
      return getters.userPreferences.preferredSite;
    }
  },
  mutations: {
    ...DeferUtil.mutations(log),
    setUser(state, user) {
      state.user = user;
    },
    setOrganisation(state, organisation) {
      state.organisation = organisation;
    },
    setSite(state, site) {
      state.site = site;
    },
    clear(state) {
      state.user = {};
      state.site = null;
      state.organisation = null;
    }
  },
  actions: {
    onAuthStateChanged: {
      root: true,
      async handler({state, commit, dispatch, getters}, authUser) {
        if (!authUser) {
          commit('reset');
          commit('clear');
          commit('app/clear', 'user', {root: true});
          return;
        }
        commit('app/loading', 'user', {root: true});
        try {
          commit('defer', await dispatch('bindUserDoc', authUser.email));
        } catch (e) {
          log.error('bindUserDoc after authStateChanged', e);
        } finally {
          commit('app/loaded', 'user', {root: true});
        }
        const _db = await db;

        const userOrganisations = getters.userOrganisationRefs;
        /** @type {firebase.firestore.DocumentReference|null} */
        let selectedOrganisation = null;
        if (userOrganisations.length === 1) {
          selectedOrganisation = userOrganisations[0];
        } else {
          selectedOrganisation = getters.userPreferredOrganisation;
        }
        if (selectedOrganisation) {
          try {
            log.debug(`auto selecting organisation`, selectedOrganisation.path);
            await dispatch('chooseOrganisation', selectedOrganisation);
          } catch (e) {
            log.error(`error auto-selecting organisation`, e);
          }
        }

        const userSites = getters.userSiteRefs;
        const {siteId} = router.currentRoute.params;
        let siteRef;
        if (siteId) {
          siteRef = doc(_db, `sites/${siteId}`);
          if (userSites.findIndex(ref => ref.path === siteRef.path) === -1) {
            // todo handle links with sites the user don't have access to
            siteRef = undefined;
          }
        }
        if (!siteRef && userSites.length === 1) {
          siteRef = userSites[0];
        } else {
          siteRef = getters.userPreferredSite;
        }
        if (siteRef) {
          try {
            log.debug(`auto selecting site`, siteRef.path);
            await dispatch('chooseSite', siteRef);
          } catch (e) {
            log.error(`error auto-selecting site`, e);
          }
        }

        if (Boolean(state.organisation && state.site) && router.currentRoute.name === 'Home') {
          // site & organisation auto-selected, go to devices list
          await router.push({
            name: 'site-devices',
            params: {
              siteId: state.site.id
            }
          });
        }
      }
    },

    async chooseOrganisation({commit}, ref) {
      log.debug('chooseOrganisation', ref.path);
      const defer = {};
      defer.organisation = await awaitFirstSnapshot(ref,
          snap => {
            commit('setOrganisation', decorateSnapshot(snap));
          }, err => {
            log.warn(`snapshot error for ${ref.path}`, err);
          }
      );
      commit('setSite', null); // reset site selection
      commit('defer', defer);
    },

    async chooseSiteById({dispatch}, id) {
      const ref = doc(await db, `sites/${id}`);
      return dispatch('chooseSite', ref);
    },

    async chooseSite({commit}, ref) {
      log.debug('chooseSite', ref.path);
      const defer = {};
      defer.site = await awaitFirstSnapshot(ref,
          snap => {
            commit('setSite', decorateSnapshot(snap));
          }, err => {
            log.warn(`snapshot error for ${ref.path}`, err);
          }
      );
      commit('defer', defer);
    },

    async bindUserDoc({commit, getters, dispatch, rootGetters}, email) {
      log.debug('bindUserDoc email=' + email);

      const defer = {};
      const docRef = doc(await db, `/users/${email}`);
      defer.user = await awaitFirstSnapshot(docRef,
          userSnap => {
            commit('setUser', decorateSnapshot(userSnap));
          }, err => {
            commit('reset');
            commit('clear');
          }
      );
      return defer;
    },

    async updateUserTitle({state}, title) {
      const ref = state.user && state.user.ref;
      if (!ref) {
        return Promise.resolve();
      }
      await updateDoc(ref, {title});
    },

    async updateUserPreferredOrganisation({state}, organisationRef) {
      const ref = state.user && state.user.ref;
      if (!ref) {
        return Promise.resolve();
      }
      await updateDoc(ref, {
        'preferences.preferredOrganisation': organisationRef,
        // clear preferred site when setting org
        'preferences.preferredSite': deleteField()
      });
    },

    async updateUserPreferredSite({state}, siteRef) {
      const ref = state.user && state.user.ref;
      if (!ref) {
        return Promise.resolve();
      }
      await updateDoc(ref, {'preferences.preferredSite': siteRef});
    }
  },
  modules: {
    selectedOrganisation,
    selectedSite
  }
};
