import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import { VTreeview } from 'vuetify/lib/components/VTreeview';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VToolbar,{attrs:{"flat":""}},[_c(VToolbarTitle,[_vm._v("Locations for "+_vm._s(_vm.siteName))]),_c(VSpacer),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"mx-2",attrs:{"icon":"","color":"secondary"},on:{"click":_vm.doRefresh}},on),[_c(VIcon,[_vm._v("mdi-refresh")])],1)]}}])},[_vm._v(" Refresh the list ")]),(_vm.hasSiteAdminAccess)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"ml-2",attrs:{"fab":"","depressed":"","small":"","color":"secondary"},on:{"click":function($event){return _vm.create()}}},on),[_c(VIcon,[_vm._v("mdi-plus")])],1)]}}],null,false,2552457315)},[_vm._v(" Add a location to "+_vm._s(_vm.siteName)+" ")]):_vm._e()],1),_c(VTreeview,{attrs:{"open-on-click":"","items":_vm.records,"load-children":_vm.loadLocationsChildren,"item-text":"title"},scopedSlots:_vm._u([(_vm.hasSiteAdminAccess)?{key:"append",fn:function( ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"ml-2",attrs:{"fab":"","depressed":"","small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.create(item)}}},on),[_c(VIcon,[_vm._v("mdi-plus")])],1)]}}],null,true)},[_vm._v(" Add a location to "+_vm._s(item.title)+" ")]),_c(VBtn,{staticClass:"ml-2",attrs:{"fab":"","depressed":"","small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.edit(item)}}},[_c(VIcon,[_vm._v("mdi-pencil")])],1)]}}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }