<template>
  <v-container fluid>
    <v-toolbar flat>
      <v-toolbar-title>Products for {{ siteName }}</v-toolbar-title>
      <v-spacer/>
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn icon color="secondary" class="mx-2" v-on="on" @click="doRefresh">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
        Refresh the list
      </v-tooltip>
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn class="ml-2" fab depressed small color="secondary" v-on="on" @click="create">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        Add a Product to {{ siteName }}
      </v-tooltip>
    </v-toolbar>
    <v-data-table
        :headers="dataHeaders"
        :items="pageItems"
        disable-sort

        :items-per-page="perPage"
        @update:items-per-page="setPerPage"

        :options.sync="options"
        @pagination="onPaginationUpdate"
        :server-items-length="asProducts.length"

        :footer-props="{'items-per-page-options': [25, 50, 100, 200]}"
        :loading="loading">
      <template #item.actions="{item}">
        <v-icon
            small
            class="mr-2"
            @click.stop="edit(item)">
          mdi-pencil
        </v-icon>
      </template>

      <template #footer.page-text="{pageStart, pageStop}">
        {{ pageStart }}-{{ pageStop }} of {{ itemCount }}
      </template>
    </v-data-table>
    <v-dialog
        v-model="editProductForm"
        persistent
        ref="editProductForm"
        max-width="480px"
        :fullscreen="$vuetify.breakpoint.mobile">
      <edit-product-form
          :product="editProduct"
          :add-new="newProduct"
          v-if="editProductForm"
          @close="closeHandler"
          @update="updateHandler"
          @create="createHandler"/>
    </v-dialog>
  </v-container>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import EditProductForm from '@/site/components/edit/EditProductForm';
import {Product} from '@/site/Product';

export default {
  name: 'ProductList',
  components: {EditProductForm},
  data() {
    return {
      dataHeaders: [
        {text: 'Name', value: 'title'},
        {text: 'Type', value: 'type'},
        {text: 'Actions', value: 'actions', sortable: false}
      ],
      editProductForm: false,
      editProduct: {},
      newProduct: false,
      options: {},
      loading: false
    };
  },
  computed: {
    ...mapGetters('site/products', ['asProducts', 'hasMore']),
    ...mapState('site/products', ['perPage']),
    ...mapGetters('user', ['siteRefPath', 'siteName']),
    pageItems() {
      const page = this.options.page;
      const perPage = this.perPage;
      const start = (page - 1) * perPage;
      return this.asProducts.slice(start, start + perPage);
    },
    itemCount() {
      const count = this.asProducts.length;
      if (this.hasMore) {
        return `${count}+`;
      }
      return count;
    }
  },
  watch: {
    siteRefPath: {
      immediate: true,
      handler(site) {
        if (site) {
          this.onPaginationUpdate({page: 1, pageCount: 1});
        }
      }
    }
  },
  methods: {
    ...mapActions('site/products', ['updateProduct', 'createProduct']),
    edit(product) {
      this.editProduct = product;
      this.editProductForm = true;
    },
    create() {
      this.newProduct = true;
      this.editProductForm = true;
    },
    createHandler(product) {
      const p = new Product(product);
      this.createProduct(p);
    },
    updateHandler(product) {
      this.updateProduct(product);
    },
    closeHandler() {
      this.editProductForm = false;
      this.editProduct = {};
      this.newProduct = false;
    },

    setPerPage(p) {
      this.$store.commit(`site/products/setPerPage`, p);
    },
    async onPaginationUpdate({page, pageCount}) {
      if (page === pageCount && this.hasMore) {
        this.loading = true;
        try {
          this.$logger.debug('loading next page');
          await this.$store.dispatch(`site/products/nextPage`);
        } catch (e) {
          this.$logger.error('next page', e);
        }
        this.loading = false;
      }
    },
    async doRefresh() {
      this.loading = true;
      try {
        this.$logger.debug('refresh');
        await this.$store.dispatch(`site/products/refresh`);
      } catch (e) {
        this.$logger.error('refresh', e);
      }
      this.loading = false;
    }
  }
};
</script>

<style scoped>

</style>
