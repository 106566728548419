import { render, staticRenderFns } from "./ProductForm.vue?vue&type=template&id=13f29fa6&scoped=true&"
import script from "./ProductForm.vue?vue&type=script&lang=js&"
export * from "./ProductForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13f29fa6",
  null
  
)

export default component.exports