<template>
  <v-tooltip bottom>
    <template #activator="{ on }">
      <v-btn icon v-on="on" @click="remove" :loading="loading">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </template>
    Revoke {{ contractor.title }}'s access to {{ site.title }}
  </v-tooltip>
</template>

<script>
import {mapActions} from 'vuex';

export default {
  name: 'ContractorDelete',
  props: {
    contractor: {
      type: Object,
      required: true
    },
    site: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    ...mapActions('users/organisations', ['revokeAccessToOrganisation']),
    async remove() {
      this.loading = true;
      try {
        await this.revokeAccessToOrganisation({ref: this.contractor.ref, siteId: this.site.id});
      } catch (e) {
        this.$logger.error('revoke', e);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>

</style>
