import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VNavigationDrawer,{attrs:{"app":"","clipped":"","value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event)}}},[_c(VToolbar,{staticClass:"nav-toolbar",attrs:{"flat":"","dense":""},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c('working-for-header')]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.displayName)+" "),_c(VSpacer),_c('account-menu')],1),_c(VList,{attrs:{"dense":""}},_vm._l((_vm.menu),function(i){return _c(VListGroup,{key:i.title,attrs:{"color":"secondary","group":i.group},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(i.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(i.title))])],1)]},proxy:true}],null,true)},[_c(VListItemGroup,{staticClass:"sub-menu-item"},_vm._l((i.links),function(link){return _c(VListItem,{key:link.title,attrs:{"color":"primary","to":link.to,"link":""}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(link.icon))])],1),_c(VListItemTitle,[_vm._v(" "+_vm._s(link.title)+" ")]),(link.badge)?_c(VChip,{style:({flexBasis: '35%'}),attrs:{"small":"","color":"accent darken-1"}},[_vm._v(" "+_vm._s(link.badge)+" ")]):_vm._e()],1)}),1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }