<template>
  <div class="d-flex">
    <device-search/>
    <q-r-dialog :open="scanQr"/>
  </div>
</template>

<script>
import DeviceSearch from '@/site/components/DeviceSearch.vue';
import QRDialog from '@/site/components/QRDialog.vue';

export default {
  name: 'NavBarSearch',
  components: {DeviceSearch, QRDialog},
  data() {
    return {
      scanQr: false
    };
  }
};
</script>

<style scoped>
.container {
  width: unset;
}
</style>
