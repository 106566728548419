import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c(VDialog,{attrs:{"max-width":"400","max-height":"400","value":_vm.showDialog,"fullscreen":_vm.$vuetify.breakpoint.mobile},on:{"click:outside":_vm.onClose,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.onClose.apply(null, arguments)}}},[_c(VCard,[_c(VCardTitle,[_vm._v("Scan a Device Label (QR Code)")]),_c(VCardText,[(_vm.error)?_c(VAlert,{attrs:{"color":"error","dark":""}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e(),(_vm.renderDialog)?_c('qr-scanner',{on:{"decode":_vm.onDecode,"init":_vm.onInit}}):_vm._e(),(_vm.loading)?_c(VProgressCircular,{attrs:{"indeterminate":""}}):_vm._e(),(!_vm.loading && !_vm.error)?_c(VAlert,{staticClass:"mt-2 mb-0",attrs:{"color":_vm.status.color,"dark":""}},[_vm._v(" "+_vm._s(_vm.status.text)+" ")]):_vm._e()],1),_c(VCardActions,[_c(VBtn,{attrs:{"text":""},on:{"click":_vm.onClose}},[_vm._v("Cancel")])],1)],1)],1),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.onOpen}},[_c(VIcon,[_vm._v("mdi-qrcode-scan")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }