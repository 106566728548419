let env = () => {
  const has = {
    pointEvents: typeof window.PointerEvent === 'function',
    touchEvents: typeof window.TouchEvent === 'function'
  };
  env = () => has;
  return has;
};

/**
 * @typedef {TouchEvent|MouseEvent} TapEvent
 */

/**
 * @typedef {function} OnTap
 * @param {TapEvent} e
 */

/**
 * @param {HTMLElement} el
 * @param {OnTap} func
 */
export function addTap(el, func) {
  const has = env();
  if (has.pointEvents || !has.touchEvents) {
    // just use click as browser support is fine at this level
    const l = e => func(e);
    el.$$tap = {
      unbind() {
        el.removeEventListener('click', l);
      }
    };
    el.addEventListener('click', l);
  } else {
    let startPosition;
    let moved = false;
    const start = e => {
      startPosition = {x: e.touches[0].clientX, y: e.touches[0].clientY};
      moved = false;
    };
    const move = e => {
      if (!moved) {
        const position = {x: e.touches[0].clientX, y: e.touches[0].clientY};
        moved = Math.hypot(position.x - startPosition.x, position.y - startPosition.y) > 5;
      }
    };
    const end = e => {
      if (!moved) {
        func(e);
      }
    };

    el.addEventListener('touchstart', start, {passive: true});
    el.addEventListener('touchmove', move, {passive: true});
    el.addEventListener('touchend', end, {passive: true});
    el.$$tap = {
      unbind() {
        el.removeEventListener('touchstart', start);
        el.removeEventListener('touchmove', move);
        el.removeEventListener('touchend', end);
      }
    };
  }
}

/**
 * @param {HTMLElement} el
 */
export function removeTap(el) {
  if (el.$$tap) el.$$tap.unbind();
  delete el.$$tap;
}
