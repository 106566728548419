import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,{attrs:{"color":"secondary"}},[_vm._v(_vm._s(_vm.addNew ? 'Add' : 'Edit')+" Location")]),_c(VCardText,[_c(VForm,{on:{"submit":_vm.submit}},[_c(VContainer,[_c(VRow,[_c(VTextField,{staticClass:"mr-4",attrs:{"label":"Name"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c(VTextField,{staticClass:"mr-4",attrs:{"label":"Architectural Reference"},model:{value:(_vm.architecturalReference),callback:function ($$v) {_vm.architecturalReference=$$v},expression:"architecturalReference"}}),_c(VTextField,{staticClass:"mr-4",attrs:{"label":"Description"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_c(VRow,[_c(VAutocomplete,{attrs:{"auto-select-first":"","hide-details":"","no-filter":"","loading":_vm.locations.loading,"label":"Parent Location (type to search)","no-data-text":"No locations found","items":_vm.locations.items,"item-text":_vm.locationTitle,"item-value":function (l) { return l.ref.path; }},on:{"update:search-input":_vm.locationSearch},model:{value:(_vm.parentLocationRefPath),callback:function ($$v) {_vm.parentLocationRefPath=$$v},expression:"parentLocationRefPath"}})],1)],1)],1)],1),_c(VCardActions,[(!_vm.addNew)?_c('confirmation-dialog',{on:{"confirm":_vm.remove},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Are you sure you want to delete "+_vm._s(_vm.title)+"? ")]},proxy:true}],null,false,1346715494)}):_vm._e(),_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":_vm.cancel}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"color":"secondary","disabled":!_vm.canSubmit},on:{"click":_vm.submit}},[_vm._v("Submit")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }