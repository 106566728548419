import Vue from 'vue';
import App from './App.vue';
import '@/registerServiceWorker';
import store from '@/core/store';
import router from '@/core/router';
import routerGuard from '@/core/router/guard';
import pageTitles from '@/core/router/page-titles';
import vuetify from '@/plugins/vuetify';
import '@/plugins/logging';
import {Logger} from '@vanti/vue-logger';

Vue.config.productionTip = false;

const log = Logger.get('main');
log.info(`Current application version: ${store.state.versions.VERSION} @ ${store.state.versions.COMMITHASH}`);

// handles global redirects to loading/login
routerGuard(router, store);
pageTitles(router, store);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
