import {collection, onSnapshot, query, where} from 'firebase/firestore';
import DeferUtil from '@/util/vuex-defer';
import {Logger} from '@vanti/vue-logger';
import {decorateSnapshot} from '@/util/vuex-firestore-util';
import Vue from 'vue';
import {loadFileText} from '@/util/storage-util';
import {devicesStore} from '@/util/vuex-devices-store';
import {LoadingUtil} from '@/util/vuex-loading';

const log = Logger.get('site/floors');

const devices = devicesStore({perPage: false}, Logger.get(`site/floors/selected/devices`));

export default {
  namespaced: true,
  state: {
    floors: [],
    floorPlansById: {},
    ...LoadingUtil.state()
  },
  getters: {
    ...LoadingUtil.getters(log),
    floorsWithPlan(state) {
      return state.floors.filter(f => f && state.floorPlansById.hasOwnProperty(f.id));
    },
    floorPlanRefsById(state) {
      const byId = {};
      for (const floor of state.floors) {
        const storageRef = floor.planSvg && floor.planSvg.storageRef;
        if (storageRef) {
          byId[floor.id] = storageRef;
        }
      }
      return byId;
    }
  },
  mutations: {
    ...DeferUtil.mutations(log),
    ...LoadingUtil.mutations(),
    setFloors(state, floors) {
      state.floors = floors;
    },
    setFloorPlanById(state, {id, plan}) {
      Vue.set(state.floorPlansById, id, plan);
    },
    clear(state) {
      state.floors = [];
      state.floorPlansById = {};
    }
  },
  actions: {
    onAuthStateChanged: {
      root: true,
      async handler({state, commit, dispatch, getters, rootGetters}, authUser) {
        if (!authUser) {
          commit('reset');
          commit('clear');
          return;
        }
        const defer = {};
        defer.siteChange = this.watch(
            () => [rootGetters['user/siteRef'], rootGetters['user/selectedSite/config/enableMapView']],
            ([_, enableMapView]) => {
              if (enableMapView) {
                dispatch('bind');
              }
            }
        );
        defer.floorPlansChange = this.watch(
            () => getters.floorPlanRefsById,
            () => dispatch('loadFloorPlans')
        );
        commit('defer', defer);
      }
    },
    bind({rootGetters, commit}) {
      log.debug('bind');
      commit('loading', 'floors');
      const siteRef = rootGetters['user/siteRef'];
      const locations = collection(siteRef, 'locations');
      const q = query(locations, where('type', '==', 'floor'));
      const defer = {};
      defer.floors = onSnapshot(q,
          snap => {
            commit('setFloors', snap.docs.map(decorateSnapshot));
            commit('loaded', 'floors');
          },
          err => {
            log.error('floors.onSnapshot', err);
            commit('loaded', 'floors');
          });
      commit('defer', defer);
    },
    loadFloorPlans({getters, commit, dispatch}) {
      commit('loading', 'floorPlans');
      const byId = getters.floorPlanRefsById;
      for (const [id, storageRef] of Object.entries(byId)) {
        dispatch('loadFloorPlan', {id, storageRef});
      }
      commit('loaded', 'floorPlans');
    },
    async loadFloorPlan({commit}, {id, storageRef}) {
      commit('loading', 'floorPlans' + id);
      const svgText = await loadFileText(storageRef);
      commit('setFloorPlanById', {id, plan: svgText});
      commit('loaded', 'floorPlans' + id);
    }
  },
  modules: {
    selected: {
      namespaced: true,
      state: {
        id: null
      },
      getters: {
        floor(state, getters, rootState) {
          if (!state.id) return;
          const floors = rootState.site.floors.floors;
          return floors.find(f => f.id === state.id);
        },
        mapMarkerScale(state, getters) {
          if (!getters.floor) return;
          return getters.floor.planSvg && getters.floor.planSvg.mapMarkerScale;
        },
        mapMarkerScaleMobile(state, getters) {
          if (!getters.floor) return;
          return getters.floor.planSvg && getters.floor.planSvg.mapMarkerScale;
        },
        floorPlan(state, getters, rootState) {
          if (!state.id) return;
          const floorPlans = rootState.site.floors.floorPlansById;
          return floorPlans[state.id];
        }
      },
      mutations: {
        ...DeferUtil.mutations(log),
        setSelected(state, id) {
          state.id = id;
        },
        clear(state) {
          state.id = null;
        }
      },
      modules: {
        devices: {
          namespaced: true,
          // spread each part so that IntelliJ still finds it all
          state: {
            ...devices.state
          },
          getters: {
            ...devices.getters
          },
          mutations: {
            ...devices.mutations
          },
          actions: {
            ...devices.actions
          }
        }
      }
    }
  }
};
