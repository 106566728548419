<template>
  <div class="flex-fill working-for-header">
    <div class="d-flex text-subtitle-2">
      <span class="label">Working For:</span>
      <component
          :is="isHome ? 'span' : 'router-link'"
          to="/"
          class="flex-fill text-right font-weight-regular text-truncate">
        {{ organisationName || fallbackText }}
      </component>
    </div>
    <div class="d-flex text-subtitle-2">
      <span class="label">Selected Site:</span>
      <component
          :is="isHome ? 'span' : 'router-link'"
          to="/"
          class="flex-fill text-right font-weight-regular text-truncate">
        {{ siteName || fallbackText }}
      </component>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'WorkingForHeader',
  computed: {
    ...mapGetters('user', ['organisationName', 'displayName', 'siteName']),
    isHome() {
      return this.$route.name === 'Home';
    },
    fallbackText() {
      return this.isHome ? '(none selected)' : '(click to select)';
    }
  }
};
</script>

<style scoped>
.working-for-header {
  max-width: 100%;
}
.label {
  min-width: 90px;
}
</style>
