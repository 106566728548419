import {storage} from '@/firebase';
import {ref, getBlob} from 'firebase/storage';

/**
 * @param {string} path
 * @return {Promise<string>}
 */
export async function loadFileText(path) {
  const api = await storage;
  const sRef = ref(api, path);
  const blob = await getBlob(sRef);
  return await blob.text();
}
