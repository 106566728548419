import {Logger} from '@vanti/vue-logger';
import Vue from 'vue';
import Vuex from 'vuex';

import app from '@/core/store/app';
import auth from '@/core/store/auth';
import serviceWorker from '@/core/store/service-worker';
import versions from '@/core/store/versions';
import site from '@/site/store';
import user from '@/user/store';
import users from '@/users/store';
import router from '@/core/router';

const log = Logger.get('core/store');

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
    init() {
    },
    onAuthStateChanged(context, authUser) {
      if (!authUser) {
        router.push({name: 'login'}).catch(e => log.warn('on logout', e));
      }
    }
  },
  modules: {
    app,
    auth,
    site,
    serviceWorker,
    user,
    users,
    versions
  }
});

store.dispatch('init')
    .catch(err => log.error('in store init', err));

export default store;
