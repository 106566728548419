<template>
  <v-dialog v-model="open" max-width="400" :fullscreen="$vuetify.breakpoint.mobile">
    <template #activator="{on}">
      <v-btn v-on="on" outlined v-bind="$attrs" text>
        Change Password
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        Update Password
        <v-spacer/>
        <v-btn icon @click="open = false"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        <template v-if="!linkSent">
          <v-btn color="primary" @click="sendResetLink">Send reset email</v-btn>
        </template>
        <template v-else>
          <div class="mb-2">Follow the link in the email to update your password.</div>
          <template v-if="linkTimeout">
            <div>Don't have the email? Try again.</div>
            <v-btn color="primary" @click="sendResetLink">Resend email</v-btn>
          </template>
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {sendPasswordResetEmail} from 'firebase/auth';
import {auth} from '@/firebase';

export default {
  name: 'PasswordUpdate',
  props: {
    email: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      open: false,
      linkSent: false,
      linkTimeout: false
    };
  },
  methods: {
    async sendResetLink() {
      this.linkTimeout = false;
      await sendPasswordResetEmail(await auth, this.email);
      this.linkSent = true;
      setTimeout(() => this.linkTimeout = true, 5 * 1000);
    }
  }
};
</script>

<style scoped>

</style>
