import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,{staticClass:"px-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"px-0 text-subtitle-1 d-flex"},[_vm._v(" Network Interfaces "),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.add}},[_c(VIcon,[_vm._v("mdi-plus")])],1)],1)]),_c(VCol,{staticClass:"px-0",attrs:{"cols":"12"}},[_vm._l((_vm.interfaces),function(int){return [_c(VRow,{key:int.name,staticClass:"cols"},[_c(VCol,{attrs:{"cols":"4"}},[_c(VTextField,{attrs:{"dense":"","hide-details":"","label":"Name","value":_vm.getField(int.name, 'name')},on:{"change":function($event){return _vm.editField(int.name, 'name', $event)}}})],1),_c(VCol,{attrs:{"cols":"3"}},[_c(VTextField,{attrs:{"dense":"","hide-details":"","label":"MAC Address","value":_vm.getField(int.name, 'mac')},on:{"change":function($event){return _vm.editField(int.name, 'mac', $event)}}})],1),_c(VCol,{attrs:{"cols":"3"}},[_c(VTextField,{attrs:{"dense":"","hide-details":"","label":"IP Address","value":_vm.getField(int.name, 'ip')},on:{"change":function($event){return _vm.editField(int.name, 'ip', $event)}}})],1),_c(VCol,{attrs:{"cols":"2"}},[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteInterface(int.name)}}},[_c(VIcon,[_vm._v("mdi-delete")])],1)],1)],1)]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }