<template>
  <span>
    <v-dialog
        max-width="400"
        max-height="400"
        :value="showDialog"
        @click:outside="onClose"
        @keydown.esc="onClose"
        :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-card-title>Scan a Device Label (QR Code)</v-card-title>
        <v-card-text>
          <v-alert v-if="error" color="error" dark>
            {{ errorMessage }}
          </v-alert>
          <!--          <qrcode-stream v-if="renderDialog" @decode="onDecode" @init="onInit"/>-->
          <qr-scanner v-if="renderDialog" @decode="onDecode" @init="onInit"/>
          <v-progress-circular v-if="loading" indeterminate/>
          <v-alert v-if="!loading && !error" :color="status.color" dark class="mt-2 mb-0">
            {{ status.text }}
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="onClose">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn icon @click="onOpen">
      <v-icon>mdi-qrcode-scan</v-icon>
    </v-btn>
  </span>
</template>

<script>
import QrScanner from '@/site/components/QrScanner.vue';

// import {QrcodeStream} from 'vue-qrcode-reader';
import * as uuid from '@/util/uuid.mjs';
import {mapActions} from 'vuex';

/**
 * Note: this may seem overly complicated with the opening/closing logic - it would be simpler to use an activator,
 * but it needs to be as otherwise the camera stays active after you close the dialog
 */
export default {
  name: 'QRDialog',
  components: {
    QrScanner

  },
  data() {
    return {
      showDialog: false,
      renderDialog: false,
      loading: true,
      error: null,
      status: {
        text: 'Scan a device label',
        color: 'info',
        timeout: 0
      }
    };
  },
  computed: {
    errorMessage() {
      if (!this.error) return;
      switch (this.error) {
        case 'NotAllowedError':
          return 'Camera access denied';
        case 'NotFoundError':
          return 'Camera not found';
        case 'NotReadableError':
          return 'Camera in use';
      }
      return this.error;
    }
  },
  methods: {
    ...mapActions('site/devices', ['getDevice']),
    async onInit(promise) {
      this.loading = true;
      try {
        await promise;
        this.error = null;
      } catch (e) {
        this.$logger.debug('onInit', e);
        this.error = e.name;
      } finally {
        this.loading = false;
      }
    },
    onOpen() {
      this.renderDialog = true;
      this.showDialog = true;
    },
    onClose() {
      this.renderDialog = false;
      this.showDialog = false;
      this.setStatus('Scan a device label', 'info');
    },
    async onDecode(qrStr) {
      this.$logger.debug(`scanned: ${qrStr}`);
      let encUid;
      try {
        const obj = JSON.parse(qrStr);
        if (obj.hasOwnProperty('guid')) {
          encUid = obj.guid;
        } else { // not a valid qrcode
          this.$logger.warn('invalid QR error', {error, encUid});
          this.setStatus('Invalid device label. Please try again.', 'warning', 10 * 1000);
          return;
        }
      } catch (e) {
        // short-form label (i.e. UID only)
        encUid = qrStr;
      }
      try {
        const uid = uuid.base64ToUuid(encUid);
        this.setStatus(`Scanned: ${uid}`, 'info');
        this.$logger.debug(`uuid: ${encUid}  ->  ${uid}`);
        const device = await this.getDevice(uid);
        if (device) {
          await this.$router.push({
            name: 'site-device',
            params: {id: uid}
          });
          this.onClose();
        }
      } catch (error) {
        this.$logger.warn('base64ToUuid error', {error, encUid});
        this.setStatus('Invalid device label. Please try again.', 'warning', 10 * 1000);
      }
    },
    setStatus(text, color, timeout) {
      clearTimeout(this.status.timeout);
      this.status.text = text;
      this.status.color = color;
      if (timeout) {
        this.status.timeout = setTimeout(() => {
          this.status.text = 'Scan a device label';
          this.status.color = 'info';
        }, timeout);
      }
    }
  }
};
</script>

<style scoped>
.v-card__text {
  position: relative;
}
.v-progress-circular {
  position: absolute;
  top: calc(50% - 16px);
  left: calc(50% - 16px);
}
</style>
