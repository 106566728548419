<script>
import QrScanner from 'qr-scanner';

let qrScanner;

export default {
  name: 'QrScanner',
  mounted() {
    // To enforce the use of the new api with detailed scan results, call the constructor with an options object
    qrScanner = new QrScanner(
        this.$refs.videoElem,
        result => this.$emit('decode', result.data),
        {
          returnDetailedScanResult: true,
          highlightScanRegion: true,
          highlightCodeOutline: true
        }
    );
    qrScanner.setInversionMode('both');
    const initPromise = qrScanner.start();
    this.$emit('init', initPromise);
    this.$logger.debug('scanning...');
  },
  beforeDestroy() {
    qrScanner.stop();
    qrScanner.destroy();
    qrScanner = null;
    this.$logger.debug('QR scanner destroyed');
  }
};
</script>

<template>
  <div>
    <video ref="videoElem" width="100%"/>
  </div>
</template>

<style scoped>

</style>
