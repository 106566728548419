/**
 * @param {string} name
 * @param {string | Blob} content
 * @param {string} type
 */
export function downloadFile(name, content, type) {
  if (typeof content === 'string') {
    content = new Blob([content], {type});
  }
  const file = window.URL.createObjectURL(content);
  const a = document.createElement('a');
  a.href = file;
  a.download = name;
  a.click();
  window.URL.revokeObjectURL(file);
}
