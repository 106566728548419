import schema from '@/site/store/schema';
import devices from '@/site/store/devices';
import locations from '@/site/store/locations';
import products from '@/site/store/products';
import subsystems from '@/site/store/subsystems';
import floors from '@/site/store/floors';

export default {
  namespaced: true,
  modules: {
    devices,
    floors,
    locations,
    products,
    schema,
    subsystems
  }
};
