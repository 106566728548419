import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,{attrs:{"color":"secondary"}},[_vm._v(_vm._s(_vm.addNew ? 'Add' : 'Edit')+" Product")]),_c(VCardText,[_c('product-form',{attrs:{"product":_vm.product,"add-new":_vm.addNew},on:{"input":function($event){_vm.data = $event},"update:canSubmit":function($event){_vm.canSubmit = $event}}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":_vm.cancel}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"color":"secondary","disabled":!_vm.canSubmit},on:{"click":_vm.submit}},[_vm._v("Submit")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }