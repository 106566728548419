<template>
  <v-form @submit="submit" ref="deviceForm">
    <v-card class="d-flex flex-column edit-device-form" :class="{mobile: $vuetify.breakpoint.mobile}">
      <v-toolbar flat color="primary" dark class="mb-4">
        <v-toolbar-title class="d-flex flex-fill toolbar-title">
          <template v-if="newDevice">
            <span class="mr-2">New Device</span>
            <v-text-field
                class="mt-4"
                v-model="uuid"
                placeholder="UUID"
                dense
                required/>
          </template>
          <template v-else>
            Edit Device:<span class="text-subtitle-1 ml-1">{{ uuid }}</span>
          </template>
        </v-toolbar-title>
        <template #extension>
          <v-tabs v-model="tab">
            <v-tab>Info</v-tab>
            <v-tab>Product</v-tab>
            <v-tab>Network</v-tab>
            <v-tab>Alias</v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
      <v-card-text>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-container>
              <v-row>
                <v-text-field
                    v-model="deviceTitle"
                    label="Device Name"
                    required
                    :error-messages="titleErrors"/>
              </v-row>
              <v-row>
                <v-autocomplete
                    v-model="locationRefPath"
                    auto-select-first
                    no-filter
                    @update:search-input="locationSearch"
                    :loading="location.loading"
                    label="Location (type to search)"
                    no-data-text="No locations found"
                    :items="location.items"
                    :item-text="locationTitle"
                    :item-value="l => l.ref.path"/>
              </v-row>
              <v-row>
                <v-autocomplete
                    v-model="subsystemRefPath"
                    auto-select-first
                    no-filter
                    @update:search-input="subsystemSearch"
                    :loading="subsystems.loading"
                    label="System (type to search)"
                    no-data-text="No systems found"
                    :items="subsystems.items"
                    item-text="title"
                    :item-value="l => l.ref.path"/>
              </v-row>
              <v-row>
                <menu-date-picker v-model="manufactureDate" label="Date of Manufacture"/>
              </v-row>
              <v-row>
                <v-checkbox
                    v-model="labelled"
                    label="Labelled"
                    class="mr-8"/>
                <menu-date-picker v-model="labelledDate" label="Label Date"/>
              </v-row>
              <v-row>
                <v-checkbox
                    v-model="installed"
                    label="Installed"
                    class="mr-8"/>
                <menu-date-picker v-model="installationDate" label="Install Date"/>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item>
            <v-container v-if="newProduct === null">
              <v-row>
                <v-autocomplete
                    v-model="productRefPath"
                    auto-select-first
                    hide-details
                    no-filter
                    @update:search-input="productSearch"
                    :loading="productsLoading"
                    :items="products"
                    :item-text="p => `${p.manufacturer} ${p.model}`"
                    :item-value="p => p.ref.path"
                    label="Product (type to search)"
                    no-data-text="No products found"/>
              </v-row>
              <v-row>
                <a class="mt-1" @click="newProduct = {}">Or, create a new product</a>
              </v-row>
              <v-row>
                <v-col class="mr-2">
                  <v-row>
                    <v-text-field
                        v-model="deviceSerialNumber"
                        label="Serial Number"/>
                  </v-row>
                  <v-row>
                    <v-text-field
                        v-model="deviceHardwareVersion"
                        label="Hardware Version"/>
                  </v-row>
                </v-col>
                <v-col class="ml-2">
                  <v-row>
                    <v-text-field
                        v-model="deviceSoftwareVersion"
                        label="Software Version"/>
                  </v-row>
                  <v-row>
                    <v-text-field
                        v-model="deviceFirmwareVersion"
                        label="Firmware Version"/>
                  </v-row>
                </v-col>
              </v-row>
              <template v-if="device.productSnippet">
                <v-row>
                  <v-col class="pa-0">
                    <v-divider class="mb-2"/>
                    <h4>Product Details</h4>
                    <dl>
                      <dt>Manufacturer</dt>
                      <dd>{{ productSnippet.manufacturer }}</dd>
                      <dt>Model</dt>
                      <dd>{{ productSnippet.model }}</dd>
                      <dt>Kind</dt>
                      <dd>{{ productSnippet.kind && productSnippet.kind.title }}</dd>
                      <dt>Hardware Version</dt>
                      <dd>{{ productSnippet.hardwareVersion }}</dd>
                      <dt>Software Version</dt>
                      <dd>{{ productSnippet.softwareVersion }}</dd>
                      <dt>Firmware Version</dt>
                      <dd>{{ productSnippet.firmwareVersion }}</dd>
                    </dl>
                  </v-col>
                </v-row>
              </template>
            </v-container>
            <product-form
                v-else
                @input="newProduct = $event"
                @update:canSubmit="canSubmitProduct = $event"
                add-new>
              <template #prepend>
                <v-row>
                  <h4>New Product</h4>
                </v-row>
              </template>
              <template #append>
                <v-row>
                  <a class="mt-1" @click="newProduct = null">Or, find a product</a>
                </v-row>
              </template>
            </product-form>
          </v-tab-item>
          <v-tab-item>
            <v-container>
              <network-editor v-model="networkInterfaces"/>
            </v-container>
          </v-tab-item>
          <v-tab-item>
            <v-container>
              <v-row>
                <v-text-field
                    v-model="commissionedName"
                    label="Commissioned Name"
                    required/>
              </v-row>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-spacer/>
      <v-card-actions>
        <confirmation-dialog @confirm="remove" v-if="!newDevice">
          <template #title>
            Are you sure you want to delete {{ deviceTitle }}?
          </template>
        </confirmation-dialog>
        <v-spacer/>
        <v-btn @click="cancel" text>Cancel</v-btn>
        <v-btn @click="submit" color="secondary" :disabled="!canSubmit">Submit</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import ConfirmationDialog from '@/site/components/ConfirmationDialog.vue';
import MenuDatePicker from '@/site/components/edit/MenuDatePicker.vue';
import NetworkEditor from '@/site/components/edit/NetworkEditor.vue';
import ProductForm from '@/site/components/edit/ProductForm.vue';
import {fromYearMonthDay, toYearMonthDay} from '@/util/dates.js';
import {decorateSnapshot} from '@/util/vuex-firestore-util.js';
import {debounce, isEqual} from 'lodash';
import {v4 as uuid} from 'uuid';
import {validationMixin} from 'vuelidate';
import {helpers, required} from 'vuelidate/lib/validators';
import {mapActions} from 'vuex';

// custom validators
// todo: this should be taken from the site's naming schema
const validName = helpers.regex('validName', /^[A-Z]{3,4}-[A-Z0-9]{3,5}(?:-[A-Za-z0-9_$]+){0,1}$/);

export default {
  name: 'EditDeviceForm',
  components: {ProductForm, NetworkEditor, ConfirmationDialog, MenuDatePicker},
  mixins: [validationMixin],
  props: {
    device: {
      type: Object,
      default: () => {
        return {type: {}, location: {}};
      }
    }
  },
  data() {
    return {
      dateMenu: false,
      tab: null,
      saveData: {},
      products: [],
      productsLoading: false,
      canSubmitProduct: false,
      newProduct: null,
      location: {
        items: [],
        loading: false
      },
      subsystems: {
        items: [],
        loading: false
      }
    };
  },
  validations: {
    saveData: {
      title: {
        required,
        validName
      }
    }
  },
  computed: {
    newDevice() {
      return !Boolean(this.device.firestoreRef);
    },
    uuid: {
      get() {
        if (this.device.uuid) {
          return this.device.uuid;
        }
        if (this.saveData.uuid) {
          return this.saveData.uuid;
        }
        return '';
      },
      set(v) {
        this.saveData.uuid = v;
      }
    },
    productRefPath: {
      get() {
        let ref;
        if (this.saveData.product && this.saveData.product.ref) {
          ref = this.saveData.product.ref;
        } else {
          ref = this.device.productRef;
        }

        if (ref) {
          return ref.path;
        }
        return undefined;
      },
      set(ref) {
        const product = this.products.find(p => p.ref.path === ref);
        if (!product) return;
        const snippet = {
          ...product,
          ref: product.ref
        };
        delete snippet._keywords;
        this.$set(this.saveData, 'product', snippet);
      }
    },
    productSnippet() {
      if (this.saveData.product) {
        return this.saveData.product;
      }
      return this.device.productSnippet;
    },
    locationRefPath: {
      get() {
        let ref;
        if (this.saveData.location && this.saveData.location.ref) {
          ref = this.saveData.location.ref;
        } else {
          ref = this.device.locationRef;
        }

        if (ref) {
          return ref.path;
        }
        return undefined;
      },
      set(ref) {
        const location = this.location.items.find(p => p.ref.path === ref);
        if (!location) return;
        const snippet = {
          ...location,
          ref: location.ref
        };
        delete snippet._keywords;
        this.$set(this.saveData, 'location', snippet);
      }
    },
    locationSnippet() {
      if (this.saveData.location) {
        return this.saveData.location;
      }
      return this.device.locationSnippet;
    },
    subsystemRefPath: {
      get() {
        let ref;
        if (this.saveData.subsystem && this.saveData.subsystem.ref) {
          ref = this.saveData.subsystem.ref;
        } else {
          ref = this.device.subsystemRef;
        }

        if (ref) {
          return ref.path;
        }
        return undefined;
      },
      set(ref) {
        const subsystem = this.subsystems.items.find(p => p.ref.path === ref);
        if (!subsystem) {
          this.$delete(this.saveData, 'subsystem');
          return;
        }
        const snippet = {
          ...subsystem,
          ref: subsystem.ref
        };
        delete snippet._keywords;
        this.$set(this.saveData, 'subsystem', snippet);
      }
    },
    subsystemSnippet() {
      if (this.saveData.subsystem) {
        return this.saveData.subsystem;
      }
      return this.device.subsystemSnippet;
    },
    deviceTitle: {
      get() {
        return this.device.title;
      },
      set(value) {
        this.$set(this.saveData, 'title', value);
        this.$v.saveData.title.$touch();
      }
    },
    commissionedName: {
      get() {
        if (this.device.aliases && this.device.aliases.hasOwnProperty('commissionedName')) {
          return this.device.aliases['commissionedName'];
        }
        return '';
      },
      set(value) {
        const aliases = this.device.aliases || {};
        aliases['commissionedName'] = value;
        this.$set(this.saveData, 'aliases', aliases);
      }
    },
    deviceSerialNumber: {
      get() {
        return this.device.serialNumber;
      },
      set(value) {
        this.$set(this.saveData, 'serialNumber', value);
      }
    },
    deviceHardwareVersion: {
      get() {
        return this.device.hardwareVersion;
      },
      set(value) {
        this.$set(this.saveData, 'hardwareVersion', value);
      }
    },
    deviceSoftwareVersion: {
      get() {
        return this.device.softwareVersion;
      },
      set(value) {
        this.$set(this.saveData, 'softwareVersion', value);
      }
    },
    deviceFirmwareVersion: {
      get() {
        return this.device.firmwareVersion;
      },
      set(value) {
        this.$set(this.saveData, 'firmwareVersion', value);
      }
    },
    installed: {
      get() {
        return this.installationDate !== '';
      },
      set(installed) {
        if (installed) {
          this.installationDate = new Date();
        } else {
          this.installationDate = null;
        }
      }
    },
    manufactureDate: {
      get() {
        if (this.saveData.manufactureDate) return toYearMonthDay(this.saveData.manufactureDate);
        if (this.device.manufactureDate) return toYearMonthDay(this.device.manufactureDate);
        return '';
      },
      set(date) {
        if (typeof date === 'string') {
          date = fromYearMonthDay(date);
        }
        this.$set(this.saveData, 'manufactureDate', date);
      }
    },
    installationDate: {
      get() {
        if (this.saveData.installationDate) return toYearMonthDay(this.saveData.installationDate);
        if (this.device.installationDate) return toYearMonthDay(this.device.installationDate);
        return '';
      },
      set(date) {
        if (typeof date === 'string') {
          date = fromYearMonthDay(date);
        }
        this.$set(this.saveData, 'installationDate', date);
      }
    },
    labelled: {
      get() {
        return this.labelledDate !== '';
      },
      set(labelled) {
        if (labelled) {
          this.labelledDate = new Date();
        } else {
          this.labelledDate = null;
        }
      }
    },
    labelledDate: {
      get() {
        if (this.saveData.labelledDate) return toYearMonthDay(this.saveData.labelledDate);
        if (this.device.labelledDate) return toYearMonthDay(this.device.labelledDate);
        return '';
      },
      set(date) {
        if (typeof date === 'string') {
          date = fromYearMonthDay(date);
        }
        this.$set(this.saveData, 'labelledDate', date);
      }
    },
    networkInterfaces: {
      get() {
        return this.saveData.nics || this.device.nics || {};
      },
      set(nics) {
        if (isEqual(nics, this.device.nics)) {
          this.$delete(this.saveData, 'nics');
        } else {
          this.$set(this.saveData, 'nics', nics);
        }
      }
    },
    titleErrors() {
      const errors = [];
      if (!this.$v.saveData.title.$dirty) return errors;
      if (!this.$v.saveData.title.required) errors.push('Name is required.');
      if (!this.$v.saveData.title.validName) {
        errors.push('Name must match the format: \n' +
            '<3-4 character type abbr>-<3-5 digit number>-<suffix chars: a-z,_,$>');
      }
      return errors;
    },
    canSubmit() {
      // can submit if:
      // - there is some data to save; and
      // - if the title has changed, this must be valid
      // - if making a new product, this must be valid
      const newProductValid = this.newProduct === null || this.canSubmitProduct;
      const hasDeviceChanges = Object.values(this.saveData).length > 0;
      return newProductValid &&
          (hasDeviceChanges || this.newProduct !== null) &&
          !(this.saveData.hasOwnProperty('title') && this.$v.$invalid);
    }
  },
  watch: {
    locationRefPath: {
      immediate: true,
      handler() {
        if (this.locationRefPath && this.location.items.length === 0) {
          this.location.items.push(this.locationSnippet);
        }
      }
    },
    productRefPath: {
      immediate: true,
      handler() {
        if (this.productRefPath && this.products.length === 0) {
          this.products.push(this.productSnippet);
        }
      }
    },
    subsystemRefPath: {
      immediate: true,
      handler() {
        if (this.subsystemRefPath && this.subsystems.items.length === 0) {
          this.subsystems.items.push(this.subsystemSnippet);
        }
      }
    }
  },
  mounted() {
    if (this.newDevice) {
      // generate a uuid for new devices
      this.$set(this.saveData, 'uuid', uuid());
    }
  },
  methods: {
    ...mapActions('site/locations', {
      searchLocationByKeyword: 'searchKeyword'
    }),
    ...mapActions('site/products', {
      searchProductByKeyword: 'searchKeyword'
    }),
    ...mapActions('site/subsystems', {
      searchSubsystemsByKeyword: 'searchKeyword'
    }),
    locationSearch(val) {
      if (!val || val.length < 2) {
        return;
      }
      this.debounceDoLocationSearch(val);
    },
    debounceDoLocationSearch: debounce(function(val) {
      // eslint-disable-next-line no-invalid-this
      this.doLocationSearch(val);
    }, 200),
    async doLocationSearch(val) {
      // Items have already been requested
      if (this.location.loading) return;
      this.location.loading = true;
      try {
        const results = await this.searchLocationByKeyword(val);
        const locations = results.map(decorateSnapshot);
        if (this.locationSnippet) {
          locations.push(this.locationSnippet);
        }
        this.location.items = locations;
        this.$logger.debug('search', this.location.items);
      } catch (e) {
        this.$logger.error('search', e);
      } finally {
        this.location.loading = false;
      }
    },
    productSearch(val) {
      if (!val || val.length < 2) {
        return;
      }
      this.debounceDoProductSearch(val);
    },
    debounceDoProductSearch: debounce(function(val) {
      // eslint-disable-next-line no-invalid-this
      this.doProductSearch(val);
    }, 200),
    async doProductSearch(val) {
      // Items have already been requested
      if (this.productsLoading) return;
      this.productsLoading = true;
      try {
        const results = await this.searchProductByKeyword(val);
        const products = results.map(decorateSnapshot);
        if (this.productSnippet) {
          products.push(this.productSnippet);
        }
        this.products = products;
        this.$logger.debug('search', this.products);
      } catch (e) {
        this.$logger.error('search', e);
      } finally {
        this.productsLoading = false;
      }
    },
    subsystemSearch(val) {
      if (!val || val.length < 2) {
        return;
      }
      this.debounceDoSubsystemSearch(val);
    },
    debounceDoSubsystemSearch: debounce(function(val) {
      // eslint-disable-next-line no-invalid-this
      this.doSubsystemSearch(val);
    }, 200),
    async doSubsystemSearch(val) {
      // Items have already been requested
      if (this.subsystems.loading) return;
      this.subsystems.loading = true;
      try {
        const results = await this.searchSubsystemsByKeyword(val);
        const subsystems = results.map(decorateSnapshot);
        if (this.subsystemSnippet) {
          subsystems.push(this.subsystemSnippet);
        }
        this.subsystems.items = subsystems;
        this.$logger.debug('search', this.subsystems.items);
      } catch (e) {
        this.$logger.error('search', e);
      } finally {
        this.subsystems.loading = false;
      }
    },
    submit() {
      const payload = {device: this.saveData};
      if (this.newProduct !== null) {
        payload.newProduct = this.newProduct;
      }
      this.$logger.debug('submit', payload);
      if (this.newDevice) {
        this.$emit('create', payload);
      } else {
        // update needs the ref
        Object.defineProperty(this.saveData, 'firestoreRef', {value: this.device.firestoreRef});
        this.$emit('update', payload);
      }
      this.$emit('close', {});
    },
    cancel() {
      this.$emit('close', {});
    },
    remove() {
      this.$emit('delete', this.device);
      this.$emit('close', {});
    },
    locationTitle(location) {
      let title = location.title;
      if (location.parentLocation && location.parentLocation.title) {
        title += ` (${location.parentLocation.title})`;
      }
      return title;
    }
  }
};
</script>

<style scoped>
.v-toolbar {
  flex: none;
}

dl {
  display: grid;
  grid-template-columns: auto 1fr;
}

dt {
  margin-right: 4px;
}

.toolbar-title {
  align-items: baseline;
}
.edit-device-form {
  min-height: 600px;
}
.edit-device-form.mobile {
  min-height: 100vh;
}

.edit-device-form >>> .v-card__text {
  padding: 0;
}
.edit-device-form >>> .row {
  padding: 0 16px;
}
.edit-device-form >>> .row > .col > .row {
  padding: 0;
}
</style>
