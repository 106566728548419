import store from '@/core/store';
import {Logger} from '@vanti/vue-logger';
import {register} from 'register-service-worker';

const log = Logger.get('registerServiceWorker');

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready(registration) {
      log.debug(
          'App is being served from cache by a service worker.\n' +
          'For more details, visit https://goo.gl/AFskqB'
      );
      store.dispatch('serviceWorker/ready', registration);
    },
    registered(registration) {
      log.debug('Service worker has been registered.');
      store.dispatch('serviceWorker/registered', registration);
    },
    cached(registration) {
      log.debug('Content has been cached for offline use.');
      store.dispatch('serviceWorker/cached', registration);
    },
    updatefound(registration) {
      log.debug('New content is downloading.');
      store.dispatch('serviceWorker/updatefound', registration);
    },
    updated(registration) {
      log.debug('New content is available; please refresh.');
      store.dispatch('serviceWorker/updated', registration);
    },
    offline(registration) {
      log.debug('No internet connection found. App is running in offline mode.');
      store.dispatch('serviceWorker/offline', registration);
    },
    error(error) {
      log.error('Error during service worker registration:', error);
    }
  });
}
