/**
 *  Converts a camel case string to a sentence
 *
 *  @param {string} str
 *  @return {string}
 */
export function camelToSentence(str) {
  return str
      .replace(/([A-Z])/g, ' $1')
      .replace(/^./, function(str) {
        return str.toUpperCase();
      })
      .trim();
}
