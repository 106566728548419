import DeferUtil from '@/util/vuex-defer';
import {Logger} from '@vanti/vue-logger';
import {onSnapshot} from 'firebase/firestore';
import {decorateSnapshot} from '@/util/vuex-firestore-util';

const log = Logger.get('user/store/selectedSite');

export default {
  namespaced: true,
  state: {
    owner: null
  },
  getters: {
    site(state, getters, rootState) {
      return rootState.user.site;
    },
    siteId(state, getters) {
      return getters.site && getters.site.id;
    },
    hasSelectedSite(state, getters) {
      return Boolean(getters.site);
    },
    siteSchemas(state, getters) {
      const site = getters.site;
      return site && site.schemas || {};
    },
    siteRole(state, getters, rootState, rootGetters) {
      const sites = rootGetters['user/sites'];
      const site = getters.site;
      return (site && sites[site.id]) || {};
    },
    siteAccess(state, getters) {
      return getters.siteRole && getters.siteRole.role;
    },
    hasSiteAdminAccess(state, getters) {
      return getters.siteAccess === 'admin';
    },
    owner(state, getters) {
      const site = getters.site;
      return site && site.owner;
    },
    ownerRef(state, getters) {
      return getters.owner && getters.owner.ref;
    },
    ownerId(state, getters) {
      return getters.ownerRef && getters.ownerRef.id;
    },

    hasSiteOwnerAccess(state, getters, rootState, rootGetters) {
      const memberships = rootGetters['user/memberships'];
      return Boolean(getters.ownerId && memberships.hasOwnProperty(getters.ownerId));
    },
    hasSiteOwnerAdminAccess(state, getters, rootState, rootGetters) {
      if (!getters.hasSiteOwnerAccess) return false;
      const memberships = rootGetters['user/memberships'];
      const membership = memberships[getters.ownerId];
      return membership.role && membership.role === 'admin';
    }
  },
  mutations: {
    ...DeferUtil.mutations(log),
    setOwner(state, owner) {
      state.owner = owner;
    },
    clear(state) {
      state.owner = null;
    }
  },
  actions: {
    init: {
      root: true,
      async handler({commit, getters, dispatch}) {
        this.watch(
            () => getters.hasSiteOwnerAccess,
            hasSiteOwnerAccess => {
              if (!hasSiteOwnerAccess) {
                commit('reset');
                commit('clear');
              } else {
                dispatch('bindOwner', getters.ownerRef);
              }
            },
            {immediate: true}
        );
      }
    },

    async bindOwner({commit}, ownerRef) {
      const defer = {};
      defer.owner = onSnapshot(ownerRef,
          snap => {
            commit('setOwner', decorateSnapshot(snap));
          },
          err => {
            log.error('bindOwner', err);
          }
      );
      commit('defer', defer);
    }
  },
  modules: {
    config: {
      namespaced: true,
      getters: {
        siteConfig(state, getters, rootState, rootGetters) {
          const site = rootGetters['user/selectedSite/site'];
          return site && site.config || {};
        },
        disableAddDevices(state, getters) {
          return Boolean(getters.siteConfig.disableAddDevices);
        },
        enableMapView(state, getters) {
          return getters.siteConfig.enableMapView;
        },
        labelSizes(state, getters) {
          return getters.siteConfig.labelSizes;
        }
      }
    }
  }
};
