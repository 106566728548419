import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_vm._v("Add a Contractor to "+_vm._s(_vm.site.title))]),_c(VCardText,[_c(VContainer,[_c(VRow,[_c(VAutocomplete,{attrs:{"auto-select-first":"","chips":"","hide-no-data":"","value":_vm.form.organisationId,"loading":_vm.search.loading || _vm.existing.loading,"search-input":_vm.search.term,"items":_vm.search.results,"item-text":"title","item-value":"id","label":"Start typing to search","error-messages":_vm.contractorErrors,"required":""},on:{"change":_vm.setOrganisationId,"update:searchInput":function($event){return _vm.$set(_vm.search, "term", $event)},"update:search-input":function($event){return _vm.$set(_vm.search, "term", $event)}}})],1),_c(VRow,[_c('a',{on:{"click":function($event){return _vm.$emit('add-new')}}},[_vm._v("Can't find a contractor? Click here to add a new one")])])],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"disabled":_vm.loading,"text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Cancel ")]),_c(VBtn,{attrs:{"color":"secondary","loading":_vm.loading,"disabled":!_vm.valid},on:{"click":_vm.add}},[_vm._v(" Add ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }