import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"480","fullscreen":_vm.$vuetify.breakpoint.mobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c(VBtn,_vm._g({attrs:{"fab":"","depressed":"","small":"","color":"secondary"}},Object.assign({}, on, onTooltip)),[_c(VIcon,[_vm._v("mdi-plus")])],1)]}}],null,true)},[_vm._v(" Add a Contractor to "+_vm._s(_vm.site.title)+" ")])]}}]),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[(_vm.showNew)?_c('contractor-add-new',{attrs:{"site":_vm.site,"open":_vm.open},on:{"close":_vm.close}}):_c('contractor-add-existing',{attrs:{"site":_vm.site,"open":_vm.open},on:{"close":_vm.close,"add-new":function($event){_vm.showNew = true}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }