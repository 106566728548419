<template>
  <v-container fluid>
    <v-toolbar flat>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-spacer/>
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn icon color="secondary" class="mx-2" v-on="on" @click="doRefresh">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
        Refresh the list
      </v-tooltip>
      <contractor-add :site="site"/>
    </v-toolbar>
    <v-data-table
        :headers="headers"
        :items="pageItems"
        disable-sort

        :items-per-page="perPage"
        @update:items-per-page="setPerPage"

        :options.sync="options"
        @pagination="onPaginationUpdate"
        :server-items-length="pageItems.length"

        :footer-props="{'items-per-page-options': [25, 50, 100, 200]}"
        :loading="loading">
      <template #item.subsystems="{item}">
        {{ subsystems(item, site).map(s => s.title).join(', ') }}
      </template>
      <template #item.actions="{item}">
        <contractor-edit :contractor="item" :site="site"/>
        <contractor-delete :contractor="item" :site="site"/>
      </template>

      <template #footer.page-text="{pageStart, pageStop}">
        {{ pageStart }}-{{ pageStop }} of {{ itemCount }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import ContractorDelete from '@/users/components/ContractorDelete';
import ContractorAdd from '@/users/components/ContractorAdd';
import ContractorEdit from '@/users/components/ContractorEdit';

export default {
  name: 'Organisations',
  components: {ContractorEdit, ContractorAdd, ContractorDelete},
  data() {
    return {
      headers: [
        {text: 'Name', value: 'title'},
        {text: 'Systems', value: 'subsystems'},
        {text: 'Actions', value: 'actions', sortable: false}
      ],
      options: {},
      loading: false
    };
  },
  computed: {
    ...mapGetters('users/organisations', ['hasMore']),
    ...mapState('users/organisations', ['perPage', 'records']),
    ...mapGetters('user', ['siteName', 'siteRefPath']),
    ...mapGetters('user/selectedSite', ['site']),
    title() {
      return `Contractors with Access to ${this.siteName}`;
    },
    pageItems() {
      const page = this.options.page;
      const perPage = this.perPage;
      const start = (page - 1) * perPage;
      return this.records.slice(start, start + perPage);
    },
    itemCount() {
      const count = this.records.length;
      if (this.hasMore) {
        return `${count}+`;
      }
      return count;
    }
  },
  watch: {
    siteRefPath: {
      immediate: true,
      handler(site) {
        if (site) {
          this.onPaginationUpdate({page: 1, pageCount: 1});
        }
      }
    }
  },
  methods: {
    ...mapActions('users/organisations', ['nextPage', 'refresh']),
    ...mapMutations('users/organisations', ['setPerPage']),

    async onPaginationUpdate({page, pageCount}) {
      if (page === pageCount && this.hasMore) {
        this.loading = true;
        try {
          this.$logger.debug('loading next page');
          await this.nextPage();
        } catch (e) {
          this.$logger.error('next page', e);
        }
        this.loading = false;
      }
    },

    async doRefresh() {
      this.loading = true;
      try {
        this.$logger.debug('refresh');
        await this.refresh();
      } catch (e) {
        this.$logger.error('refresh', e);
      }
      this.loading = false;
    },

    subsystems(organisation, site) {
      const sites = organisation.sites || {};
      const access = sites[site.id];
      return access.subsystems && Object.values(access.subsystems) || [];
    }
  }
};
</script>

<style scoped>

</style>
