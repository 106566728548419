export default {
  namespaced: true,
  state: {
    /** @type {ServiceWorkerRegistration | null} */
    registration: null,
    ready: false,
    updated: false
  },
  mutations: {
    setReady(state, registration) {
      state.registration = registration;
      state.ready = true;
    },
    setUpdated(state, registration) {
      state.registration = registration;
      state.updated = true;
    }
  },
  actions: {
    ready({commit}, registration) {
      commit('setReady', registration);
    },
    registered() {
    },
    cached() {
    },
    updatefound() {
    },
    updated({commit}, registration) {
      commit('setUpdated', registration);
    },
    offline() {
    },
    error(error) {
    },
    // asks the browser to check for a new service worker version and get it.
    update({state}) {
      if (state.registration) {
        return state.registration.update();
      }
      return Promise.reject(new Error('no SW registered'));
    },
    // activates any waiting service worker without waiting for all managed pages to be cleared.
    async reload({state}) {
      if (state.registration && state.registration.waiting) {
        return new Promise((res, rej) => {
          const t = setTimeout(() => rej(new Error('Timeout waiting for service worker to skip waiting')), 5000);
          navigator.serviceWorker.addEventListener('controllerchange', () => {
            // the service worker has reloaded
            clearTimeout(t);
            res();
          }, {once: true});
          state.registration.waiting.postMessage({type: 'SKIP_WAITING'});
        });
      } else {
        // fallback to just reloading
        window.location.reload();
      }
    }
  }
};
