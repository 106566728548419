import {Logger} from '@vanti/vue-logger';
import Vue from 'vue';

/**
 * Adds simple defer logic to any store module
 */
export default class DeferUtil {
  /**
   * Returns whether there are any defer functions in the given state object. If a key is provided then it returns
   * whether that key specifically is recorded in the defer state object.
   *
   * @param {Object} state
   * @param {string} [key]
   * @return {boolean}
   */
  static hasDefer(state, key) {
    if (!state.defer) return false;
    if (key) {
      return key in state.defer;
    } else {
      return Object.keys(state.defer).length > 0;
    }
  }

  /**
   * Mutations that should be applied to your store module.
   *
   * @param {Logger} [log]
   * @return {{defer:function(Object, Object<string, Function>):void,reset:function(*, (string[])?):void}}
   */
  static mutations(log = Logger.get('DeferUtil')) {
    return {
      /**
       * @param {*} state
       * @param {string[]} [names]
       */
      reset(state, names = []) {
        if (!state.defer) return;
        if (names.length === 0) {
          state.floor = null;
          // reset everything
          names = Object.keys(state.defer);
        }
        for (const name of names) {
          if (state.defer.hasOwnProperty(name)) {
            const fn = state.defer[name];
            Vue.delete(state.defer, name);
            try {
              fn();
            } catch (e) {
              log.error('reset ' + name, e);
            }
          }
        }
      },
      /**
       * Add a function that will be invoked during unbind or reset.
       *
       * @param {Object} state
       * @param {Object.<string,function>} fns
       */
      defer(state, fns) {
        const defer = state.defer || Vue.set(state, 'defer', {});
        Object.entries(fns).forEach(([name, fn]) => {
          const old = defer[name];
          if (typeof old === 'function') {
            try {
              old();
            } catch (e) {
              log.error('during defer replace ' + name, e);
            }
          }
          Vue.set(defer, name, fn);
        });
      }
    };
  }
}
