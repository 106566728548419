<template>
  <v-text-field
      :value="searchValue"
      @change="onInput"
      @click:clear="onInput()"
      dense
      rounded
      filled
      clearable
      single-line
      hide-details
      prepend-inner-icon="mdi-magnify"
      placeholder="Search devices"
      class="mr-4 align-center"/>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';

const devicesRoute = 'site-devices';

export default {
  name: 'DeviceSearch',
  computed: {
    ...mapGetters('site/devices', ['filterValues']),
    searchValue() {
      const filter = this.filterValues.find(v => v[0] === '_keywords');
      return filter && filter[2];
    }
  },
  methods: {
    ...mapMutations('site/devices', ['setFilters']),
    async onInput(value) {
      if (this.$route.name !== devicesRoute) {
        await this.$router.push({name: devicesRoute});
      }
      const filters = this.filterValues.filter(v => v[0] !== '_keywords');
      if (value) {
        filters.push(['_keywords', 'array-contains', value.toLowerCase()]);
      }
      this.setFilters(filters);
    }
  }
};
</script>

<style scoped>

</style>
