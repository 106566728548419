<template>
  <v-card>
    <v-card-title color="secondary">{{ addNew ? 'Add' : 'Edit' }} System</v-card-title>
    <v-card-text>
      <v-form @submit="submit">
        <v-container>
          <v-row>
            <v-text-field
                v-model="title"
                label="Name"
                class="mr-4"/>
            <v-text-field
                v-model="shortcode"
                :readonly="!addNew"
                :disabled="!addNew"
                label="Shortcode (e.g. bms)"
                class="mr-4"/>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <confirmation-dialog @confirm="remove" v-if="!addNew">
        <template #title>
          Are you sure you want to delete {{ title }}?
        </template>
      </confirmation-dialog>
      <v-spacer/>
      <v-btn @click="cancel" text>Cancel</v-btn>
      <v-btn @click="submit" color="secondary" :disabled="!canSubmit">Submit</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ConfirmationDialog from '@/site/components/ConfirmationDialog';

export default {
  name: 'SubsystemForm',
  components: {ConfirmationDialog},
  props: {
    subsystem: {
      type: Object,
      default: () => {
        return {};
      }
    },
    addNew: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      data: {}
    };
  },
  computed: {
    title: {
      get() {
        return this.subsystem.title;
      },
      set(v) {
        this.$set(this.data, 'title', v);
      }
    },
    shortcode: {
      get() {
        if (this.subsystem) {
          return this.subsystem.id;
        }
        return this.data.shortcode;
      },
      set(v) {
        this.$set(this.data, 'shortcode', v);
      }
    },
    canSubmit() {
      return Object.values(this.data).length > 0;
    }
  },
  methods: {
    submit() {
      if (this.addNew) {
        this.$emit('create', {
          subsystem: this.data,
          id: this.data.shortcode
        });
      } else {
        // update needs the ref
        Object.defineProperty(this.data, 'ref', {value: this.subsystem.ref});
        this.$emit('update', this.data);
      }
      this.$emit('close', {});
    },
    remove() {
      this.$emit('delete', this.subsystem);
      this.$emit('close', {});
    },
    cancel() {
      this.$emit('close', {});
    }
  }
};
</script>

<style scoped>

</style>
