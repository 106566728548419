<template>
  <v-dialog v-model="open" max-width="480" :fullscreen="$vuetify.breakpoint.mobile">
    <template #activator="{on}">
      <v-tooltip bottom>
        <template #activator="{ on: onTooltip }">
          <v-btn fab depressed small color="secondary" v-on="{...on, ...onTooltip}">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        Add a Contractor to {{ site.title }}
      </v-tooltip>
    </template>
    <contractor-add-new v-if="showNew" :site="site" :open="open" @close="close"/>
    <contractor-add-existing v-else :site="site" :open="open" @close="close" @add-new="showNew = true"/>
  </v-dialog>
</template>

<script>
import ContractorAddExisting from '@/users/components/ContractorAddExisting';
import ContractorAddNew from '@/users/components/ContractorAddNew';

export default {
  name: 'ContractorAdd',
  components: {ContractorAddNew, ContractorAddExisting},
  props: {
    site: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      open: false,
      showNew: false
    };
  },
  methods: {
    close() {
      this.open = false;
      this.showNew = false;
    }
  }
};
</script>

<style scoped>

</style>
