<template>
  <v-row>
    <v-col cols="12">
      <v-autocomplete
          v-model="subsystemsSearch.selected"
          @change="addSubsystem"
          auto-select-first
          chips
          no-filter
          @update:search-input="subsystemSearchInput"
          :loading="subsystemsSearch.loading"
          label="System (type to search)"
          no-data-text="No systems found"
          :items="subsystemsSearch.items"
          item-text="title"
          :item-value="l => l"/>
    </v-col>
    <v-col cols="12" class="px-0">
      <v-list dense>
        <v-list-item v-for="s in subsystems" :key="s.id">
          <v-list-item-title>{{ s.title }}</v-list-item-title>
          <v-list-item-action>
            <v-btn icon @click="deleteInterface(s.id)"><v-icon>mdi-delete</v-icon></v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-col>
  </v-row>
</template>

<script>
import {mapActions} from 'vuex';
import {debounce} from 'lodash';
import {decorateSnapshot} from '@/util/vuex-firestore-util';
import {compareByPath} from '@/util/compare';

/**
 * @typedef {dials.firestore.NIC} NetworkInterface
 * @property {string} name
 */

export default {
  name: 'ContractorSubsystemsEditor',
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      /** @type {NetworkInterface[]} */
      added: [],
      /** @type {string[]} - id of the deleted subsystem */
      deleted: [],
      subsystemsSearch: {
        items: [],
        loading: false,
        selected: null
      }
    };
  },
  computed: {
    existing() {
      return Object.keys(this.value)
          .map(id => {
            const info = this.value[id];
            return {
              id,
              ...info
            };
          })
          .sort((a, b) => compareByPath(a, b, 'title'));
    },
    subsystems: {
      get() {
        const deleted = this.deleted;
        const existing = this.existing.filter(i => !deleted.includes(i.id));
        return existing.concat(this.added);
      }
    }
  },
  methods: {
    ...mapActions('site/subsystems', {
      searchSubsystemsByKeyword: 'searchKeyword'
    }),
    reset() {
      this.added = [];
      this.deleted = [];
    },
    onChange() {
      this.$emit('input', this.newValue());
      this.reset();
    },
    addSubsystem(subsystem) {
      if (!subsystem) return;
      this.added.push(subsystem);
      this.onChange();
      this.$nextTick(() => this.subsystemsSearch.selected = null);
    },
    deleteInterface(id) {
      const index = this.added.findIndex(a => a.id === id);
      if (index !== -1) {
        this.added.splice(index, 1);
      } else {
        this.deleted.push(id);
      }
      this.onChange();
    },

    getField(name, property) {
      if (this.value && this.value.hasOwnProperty(name)) {
        if (property === 'name') {
          return name;
        }
        return this.value[name][property];
      } else {
        const nic = this.added.find(a => a.name === name);
        return nic && nic[property];
      }
    },

    newValue() {
      const subsystems = {};
      for (const subsystem of this.subsystems) {
        subsystems[subsystem.id] = {
          title: subsystem.title,
          ref: subsystem.ref
        };
      }
      return subsystems;
    },

    subsystemSearchInput(val) {
      if (!val || val.length < 2) {
        return;
      }
      this.debounceDoSubsystemSearch(val);
    },
    debounceDoSubsystemSearch: debounce(function(val) {
      // eslint-disable-next-line no-invalid-this
      this.doSubsystemSearch(val);
    }, 200),
    async doSubsystemSearch(val) {
      // Items have already been requested
      if (this.subsystemsSearch.loading) return;
      this.subsystemsSearch.loading = true;
      try {
        const results = await this.searchSubsystemsByKeyword(val);
        this.subsystemsSearch.items = results.map(decorateSnapshot);
        this.$logger.debug('search', this.subsystemsSearch.items);
      } catch (e) {
        this.$logger.error('search', e);
      } finally {
        this.subsystemsSearch.loading = false;
      }
    }
  }
};
</script>

<style scoped>

</style>
