<template>
  <v-card>
    <v-card-title>Add a New Contractor to {{ site.title }}</v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-text-field label="Company Name" v-model="form.organisation.title"/>
        </v-row>
        <v-row>
          <h3>First User Details for {{ form.organisation.title || 'New Contractor' }}</h3>
        </v-row>
        <v-row>
          <v-text-field
              label="Email"
              @change="setEmail"
              :value="form.email"
              :loading="existingUserSearch"
              :error-messages="emailErrors"/>
        </v-row>
        <v-row>
          <v-text-field label="Name" v-model="title" :disabled="existingUser !== null"/>
        </v-row>
        <v-row>
          <v-text-field label="Job Role" v-model="jobRole" :disabled="existingUser !== null"/>
        </v-row>
        <v-row>
          <v-checkbox :label="`Admin Access to ${form.organisation.title}`" :input-value="form.user.admin" readonly/>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn
          @click="$emit('close')"
          :disabled="loading"
          text>
        Cancel
      </v-btn>
      <v-btn
          color="secondary"
          :loading="loading"
          :disabled="!valid"
          @click="add">
        Add
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {validationMixin} from 'vuelidate';
import {email, required} from 'vuelidate/lib/validators';
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'UserAdd',
  mixins: [validationMixin],
  props: {
    open: {
      type: Boolean,
      default: false
    },
    site: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      form: {
        organisation: {
          title: ''
        },
        user: {
          admin: true,
          email: null,
          title: null,
          jobRole: null
        }
      },
      existingUserSearch: false,
      existingUser: null
    };
  },
  validations: {
    form: {
      user: {
        email: {
          required,
          email
        }
      }
    }
  },
  computed: {
    ...mapGetters('user', ['siteRef']),
    email() {
      return this.form.user.email;
    },
    emailErrors() {
      const email = this.$v.form.user.email;
      if (email.$dirty && email.$invalid) {
        if (!email.required) {
          return ['Email is required'];
        }
        if (!email.email) {
          return ['Please enter a valid email address'];
        }
      }
      return [];
    },

    title: {
      get() {
        return (this.existingUser && this.existingUser.title) || this.form.user.title;
      },
      set(t) {
        this.form.user.title = t;
      }
    },
    jobRole: {
      get() {
        return (this.existingUser && this.existingUser.jobRole) || this.form.user.jobRole;
      },
      set(t) {
        this.form.user.jobRole = t;
      }
    },
    valid() {
      return !this.$v.$invalid;
    },

    organisationDoc() {
      return {
        ...this.form.organisation,
        sites: {
          [this.siteRef.id]: {
            role: 'user',
            site: {
              ref: this.site.ref,
              title: this.site.title
            }
          }
        }
      };
    }
  },
  watch: {
    open(o) {
      if (!o) {
        this.form.user.admin = true;
        this.form.user.email = null;
        this.form.user.title = null;
        this.form.user.jobRole = null;
        this.existingUserSearch = false;
        this.existingUser = null;
      }
    }
  },
  methods: {
    ...mapActions('users', ['userByEmail', 'updateUser', 'createUser']),
    ...mapActions('users/organisations', ['addNewOrganisation']),
    setEmail(e) {
      this.form.user.email = e;
      this.$v.form.user.email.$touch();
      this.checkExistingUser();
    },
    async checkExistingUser() {
      if (this.email && !this.$v.form.user.email.$invalid) {
        this.existingUserSearch = true;
        try {
          this.existingUser = await this.userByEmail({email: this.email});
        } catch (e) {
          this.$logger.error('userByEmail', e);
        } finally {
          this.existingUserSearch = false;
        }
      }
    },
    newUser() {
      if (this.existingUser) {
        return null;
      }
      const user = {};
      const props = ['title', 'jobRole', 'email'];
      for (const prop of props) {
        if (this.form.user[prop] !== null) {
          user[prop] = this.form.user[prop];
        }
      }
      return user;
    },
    async add() {
      this.loading = true;
      try {
        const organisationRef = await this.addNewOrganisation(this.organisationDoc);
        const membership = {
          role: this.form.user.admin ? 'admin' : 'user',
          organisation: {
            ref: organisationRef,
            title: this.organisationDoc.title
          }
        };
        if (this.existingUser) {
          const updates = {
            [`memberships.${organisationRef.id}`]: membership
          };
          await this.updateUser({ref: this.existingUser.ref, updates});
        } else {
          const newUser = this.newUser();
          membership.organisation.ref = organisationRef.path;
          newUser.memberships = {
            [`${organisationRef.id}`]: membership
          };
          await this.createUser({email: this.form.user.email, props: newUser});
        }
        this.$emit('close');
      } catch (e) {
        this.$logger.error('add', e);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>

</style>
