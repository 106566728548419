<template>
  <v-row>
    <v-col cols="12" class="px-0">
      <div class="px-0 text-subtitle-1 d-flex">
        Network Interfaces
        <v-spacer/>
        <v-btn icon @click="add">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
    </v-col>
    <v-col cols="12" class="px-0">
      <template v-for="int in interfaces">
        <v-row class="cols" :key="int.name">
          <v-col cols="4">
            <v-text-field
                dense
                hide-details
                label="Name"
                @change="editField(int.name, 'name', $event)"
                :value="getField(int.name, 'name')"/>
          </v-col>
          <v-col cols="3">
            <v-text-field
                dense
                hide-details
                label="MAC Address"
                @change="editField(int.name, 'mac', $event)"
                :value="getField(int.name, 'mac')"/>
          </v-col>
          <v-col cols="3">
            <v-text-field
                dense
                hide-details
                label="IP Address"
                @change="editField(int.name, 'ip', $event)"
                :value="getField(int.name, 'ip')"/>
          </v-col>
          <v-col cols="2">
            <v-btn icon @click="deleteInterface(int.name)"><v-icon>mdi-delete</v-icon></v-btn>
          </v-col>
        </v-row>
      </template>
    </v-col>
  </v-row>
</template>

<script>
/**
 * @typedef {dials.firestore.NIC} NetworkInterface
 * @property {string} name
 */

export default {
  name: 'NetworkEditor',
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      /** @type {NetworkInterface[]} */
      added: [],
      /** @type {Object<string, NetworkInterface>} */
      edited: {},
      /** @type {string[]} - name of the deleted interface */
      deleted: []
    };
  },
  computed: {
    existing() {
      return Object.keys(this.value)
          .map(name => {
            const info = this.value[name];
            return {
              name,
              ...info
            };
          });
    },
    interfaces: {
      get() {
        const deleted = this.deleted;
        const existing = this.existing.filter(i => !deleted.includes(i.name));
        return existing.concat(this.added);
      }
    }
  },
  methods: {
    reset() {
      this.added = [];
      this.edited = {};
      this.deleted = [];
    },
    onChange() {
      this.$emit('input', this.newValue());
      this.reset();
    },
    add() {
      this.added.push({name: `Network ${this.interfaces.length + 1}`});
      this.onChange();
    },
    deleteInterface(name) {
      const index = this.added.findIndex(a => a.name === name);
      if (index !== -1) {
        this.added.splice(index, 1);
      } else {
        this.deleted.push(name);
      }
      this.onChange();
    },
    editField(name, property, value) {
      if (this.value && this.value.hasOwnProperty(name)) {
        // existing NIC
        if (this.edited.hasOwnProperty(name)) {
          this.$set(this.edited[name], property, value);
        } else {
          this.$set(this.edited, name, {[property]: value});
        }
      } else {
        const index = this.added.findIndex(a => a.name === name);
        if (index !== -1) {
          this.$set(this.added[index], property, value);
        }
      }
      this.onChange();
    },

    getField(name, property) {
      if (this.value && this.value.hasOwnProperty(name)) {
        // existing NIC
        if (this.edited.hasOwnProperty(name) && this.edited[name].hasOwnProperty(property)) {
          return this.edited[name][property];
        }
        if (property === 'name') {
          return name;
        }
        return this.value[name][property];
      } else {
        const nic = this.added.find(a => a.name === name);
        return nic && nic[property];
      }
    },

    newValue() {
      const nics = {};
      for (const int of this.interfaces) {
        let value = {...int};
        if (this.edited.hasOwnProperty(int.name)) {
          value = {
            ...value,
            ...this.edited[int.name]
          };
        }
        const newName = value.name;
        delete value.name;
        nics[newName] = value;
      }
      return nics;
    }
  }
};
</script>

<style scoped>

</style>
