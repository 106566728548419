<template>
  <v-card>
    <v-card-title>Add a Contractor to {{ site.title }}</v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-autocomplete
              auto-select-first
              chips
              hide-no-data
              @change="setOrganisationId"
              :value="form.organisationId"
              :loading="search.loading || existing.loading"
              :search-input.sync="search.term"
              :items="search.results"
              item-text="title"
              item-value="id"
              label="Start typing to search"
              :error-messages="contractorErrors"
              required/>
        </v-row>
        <v-row>
          <a @click="$emit('add-new')">Can't find a contractor? Click here to add a new one</a>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn
          @click="$emit('close')"
          :disabled="loading"
          text>
        Cancel
      </v-btn>
      <v-btn
          color="secondary"
          :loading="loading"
          :disabled="!valid"
          @click="add">
        Add
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {debounce} from 'lodash';

export default {
  name: 'ContractorAddExisting',
  props: {
    open: {
      type: Boolean,
      default: false
    },
    site: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      form: {
        organisationId: null
      },
      search: {
        term: null,
        results: [],
        loading: false
      },
      existing: {
        organisation: null,
        loading: false
      }
    };
  },
  computed: {
    ...mapGetters('user', ['siteRef', 'organisationRef']),
    alreadyHasAccess() {
      if (!this.form.organisationId || !this.existing.organisation) {
        return false;
      }
      const sites = this.existing.organisation.sites || {};
      return Object.keys(sites).includes(this.siteRef.id);
    },
    contractorErrors() {
      if (this.alreadyHasAccess) {
        return [`This contractor already has access to ${this.site.title}`];
      }
      return [];
    },
    valid() {
      if (this.alreadyHasAccess) {
        return false;
      }
      return Boolean(this.form.organisationId);
    }
  },
  watch: {
    open(o) {
      if (!o) {
        this.form.organisationId = null;
      }
    },
    'search.term'(val) {
      if (!val || val.length < 3) {
        return;
      }
      this.debounceDoSearch(val);
    }
  },
  methods: {
    ...mapActions('users/organisations', ['searchKeyword', 'addAccessToOrganisation', 'fetch']),
    setOrganisationId(id) {
      this.form.organisationId = id;
      this.checkExistingAccess();
    },
    async add() {
      this.loading = true;
      try {
        await this.addAccessToOrganisation({organisationId: this.form.organisationId, site: this.site});
        this.$emit('close');
      } catch (e) {
        this.$logger.error('add', e);
      } finally {
        this.loading = false;
      }
    },

    debounceDoSearch: debounce(function(val) {
      // eslint-disable-next-line no-invalid-this
      this.doSearch(val);
    }, 500),
    async doSearch(val) {
      // Items have already been requested
      if (this.search.loading) return;
      this.search.loading = true;
      try {
        this.search.results = await this.searchKeyword(val);
        this.$logger.debug('doSearch got', this.search.results.length);
      } catch (e) {
        this.$logger.error('doSearch', e);
      } finally {
        this.search.loading = false;
      }
    },
    async checkExistingAccess() {
      if (this.form.organisationId) {
        this.existing.loading = true;
        try {
          this.existing.organisation = await this.fetch(this.form.organisationId);
        } catch (e) {
          this.$logger.error('checkExistingAccess', e);
        } finally {
          this.existing.loading = false;
        }
      }
    }
  }
};
</script>

<style scoped>

</style>
