/**
 * Compare a and b by the value of a[path] and b[path]
 * path should be . separated for each key depth
 * e.g. `my.nested.value` would compare
 * `a[my][nested][value]` with `b[my][nested][value]`
 *
 * @param {*} a
 * @param {*} b
 * @param {string} path
 * @return {number}
 */
export function compareByPath(a, b, path) {
  const keys = path.split('.');
  let c1 = a;
  let c2 = b;
  for (const k of keys) {
    c1 = c1[k];
    c2 = c2[k];
  }
  return compare(c1, c2);
}

/**
 * @param {*} a
 * @param {*} b
 * @return {number}
 */
export function compare(a, b) {
  if (a > b) return +1;
  if (a < b) return -1;
  return 0;
}
