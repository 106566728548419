import {Logger} from '@vanti/vue-logger';
import {getDoc, getDocs, collection, query, where} from 'firebase/firestore';
import Vue from 'vue';
import {decorateSnapshot} from '@/util/vuex-firestore-util';

const log = Logger.get('site/store/schema');

export default {
  namespaced: true,
  state: () => ({
    schemas: {}
  }),
  mutations: {
    addSchema(state, {property, schema}) {
      log.debug('addSchema: ', property, schema);
      Vue.set(state.schemas, property, schema);
    }
  },
  actions: {
    async searchKeyword({state, rootGetters}, {property, key}) {
      if (state.schemas.hasOwnProperty(property)) {
        const optionsCol = collection(state.schemas[property].ref, 'options');
        log.debug(`searching for '${key}' in ${optionsCol.path}`);
        const q = query(optionsCol, where('_keywords', 'array-contains', key.toLowerCase()));
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs;
      }
    },
    updateSchemas({commit, rootGetters}, siteSchemas) {
      log.debug('updateSchemas: fetching schema list');
      return Promise.all(Object.entries(siteSchemas).map(async ([property, schema]) => {
        log.debug('schema', {property, schema});
        const schemaDoc = await getDoc(schema.ref);
        commit('addSchema', {property, schema: decorateSnapshot(schemaDoc)});
      }));
    },
    init: {
      root: true,
      handler({dispatch, rootGetters}) {
        this.watch(
            () => rootGetters['user/selectedSite/siteSchemas'],
            siteSchemas => dispatch('updateSchemas', siteSchemas)
        );
      }
    }
  }
};
