<template>
  <v-container fluid>
    <v-toolbar class="elevation-0">
      <div v-if="selectedDevices.length > 0" class="d-flex flex-column">
        <div>
          <v-btn outlined @click="printAll" v-if="!$vuetify.breakpoint.mobile" class="mr-2">
            <v-icon left>mdi-printer</v-icon>
            Print All
          </v-btn>
          <v-btn outlined @click="bulkEdit">
            <v-icon left>mdi-pencil</v-icon>
            Bulk Edit
          </v-btn>
        </div>
        <div class="grey--text">
          <template v-if="selectedDevices.length === perPage">
            All {{ selectedDevices.length }} devices on this page are selected.
          </template>
          <template v-else>
            {{ selectedDevices.length }} devices selected
          </template>
        </div>
      </div>
      <v-spacer v-if="!$vuetify.breakpoint.mobile"/>
      <template v-if="!$vuetify.breakpoint.mobile || selectedDevices.length === 0">
        <v-select
            multiple
            @change="setFilters"
            :value="filterValues"
            :items="filterOptions"
            placeholder="All Devices"
            hide-details
            clearable
            prepend-inner-icon="mdi-check-circle-outline"
            style="max-width: 220px"/>
        <device-filters/>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn icon color="secondary" class="mx-2" v-on="on" @click="doRefresh">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          Refresh the list
        </v-tooltip>
        <v-tooltip bottom v-if="!disableAddDevices">
          <template #activator="{ on }">
            <v-btn class="ml-2" fab depressed small color="secondary" v-on="on" @click="$root.$emit('newDevice')">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          Add a Device to {{ siteName }}
        </v-tooltip>
      </template>
    </v-toolbar>
    <v-data-table
        :class="{desktop: !$vuetify.breakpoint.mobile}"
        :dense="$vuetify.breakpoint.mobile"
        :headers="headers"
        :items="pageItems"
        disable-sort

        :items-per-page="perPage"
        @update:items-per-page="setPerPage"

        :options.sync="options"
        @pagination="onPaginationUpdate"
        :server-items-length="asDevices.length"

        :footer-props="{'items-per-page-options': [25, 50, 100, 200, 1000]}"
        :loading="loading"

        v-model="selectedDevices"
        @click:row="$router.push({name: 'site-device', params: {id: $event.uuid}})"
        show-select
        item-key="uuid">
      <template #item.data-table-select="{item, isSelected, select}" v-if="$vuetify.breakpoint.mobile">
        <div class="d-flex align-center">
          <v-checkbox
              @click.stop
              @change="select"
              :value="isSelected"
              hide-details
              color="grey darken-1"
              class="pa-0 ma-0"/>
          <v-spacer/>
          <v-chip v-if="item.isInstalled" color="green" small dark class="mx-1">
            <v-icon left small>mdi-check-circle</v-icon>
            Installed
          </v-chip>
          <v-chip v-if="item.isLabelled" color="green" small dark class="ml-1">
            <v-icon left small>mdi-label</v-icon>
            Labelled
          </v-chip>
        </div>
      </template>
      <template #item.isInstalled="{item}">
        <v-tooltip bottom>
          <template #activator="{on}">
            <v-icon
                v-if="item.isInstalled"
                v-on="on"
                color="green">
              mdi-check-circle
            </v-icon>
          </template>
          Installed on: {{ item.installationDateString }}
        </v-tooltip>
      </template>
      <template #item.isLabelled="{item}">
        <v-tooltip bottom v-if="item.isLabelled">
          <template #activator="{on}">
            <v-icon
                v-on="on"
                color="green">
              mdi-label
            </v-icon>
          </template>
          Labelled on: {{ item.labelledDateString }}
        </v-tooltip>
        <v-tooltip bottom v-else-if="item.notLabelReady">
          <template #activator="{on}">
            <v-icon
                v-on="on"
                color="warning">
              mdi-alert
            </v-icon>
          </template>
          Device is missing information: {{ item.notLabelReadySummary }}
        </v-tooltip>
      </template>
      <template #item.title="{item}"><span class="devicename">{{ item.title }}</span></template>
      <template #item.actions="{item}">
        <v-icon
            small
            class="mr-2"
            @click.stop="$root.$emit('editDevice', item)">
          mdi-pencil
        </v-icon>
        <v-icon
            v-if="!$vuetify.breakpoint.mobile"
            small
            @click.stop="print(item)">
          mdi-printer
        </v-icon>
      </template>

      <template #footer.page-text="{pageStart, pageStop}">
        {{ pageStart }}-{{ pageStop }} of {{ itemCount }}
      </template>
    </v-data-table>
    <router-view/>
  </v-container>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import DeviceFilters from '@/site/components/DeviceFilters';
import {deviceFilterOptions} from '@/site/components/device-filters';

export default {
  name: 'DeviceList',
  components: {DeviceFilters},
  data() {
    return {
      dataHeaders: [
        {text: 'Name', value: 'title'},
        {text: 'Manufacturer', value: 'productManufacturer'},
        {text: 'Model', value: 'productModel'},
        {text: 'Location', value: 'parentLocation'},
        {text: '', value: 'location'},
        {text: 'Actions', value: 'actions', sortable: false}
      ],
      filterOptions: deviceFilterOptions,
      selectedDevices: [],
      options: {},
      loading: false
    };
  },
  computed: {
    ...mapGetters('site/devices', ['asDevices', 'hasMore', 'filterValues', 'whereConstraints']),
    ...mapState('site/devices', ['perPage']),
    ...mapGetters('user/selectedSite/config', ['disableAddDevices']),
    ...mapGetters('user', ['siteRefPath', 'siteName']),
    headers() {
      if (this.$vuetify.breakpoint.mobile) {
        return this.dataHeaders;
      }
      return [
        {text: '', value: 'isInstalled', width: 24},
        {text: '', value: 'isLabelled', width: 24},
        ...this.dataHeaders
      ];
    },
    pageItems() {
      const page = this.options.page;
      const perPage = this.perPage;
      const start = (page - 1) * perPage;
      return this.asDevices.slice(start, start + perPage);
    },
    itemCount() {
      const count = this.asDevices.length;
      if (this.hasMore) {
        return `${count}+`;
      }
      return count;
    }
  },
  watch: {
    whereConstraints() {
      if (this.loading) return;
      this.$store.commit('site/devices/clear');
      this.options.page = 1;
      this.onPaginationUpdate({page: 1, pageCount: 1});
    },
    siteRefPath: {
      immediate: true,
      handler(site) {
        if (site) {
          if (this.loading) return;
          this.$store.commit('site/devices/clear');
          this.options.page = 1;
          this.onPaginationUpdate({page: 1, pageCount: 1});
        }
      }
    }
  },
  methods: {
    ...mapActions('site/devices', ['nextPage', 'refresh']),
    ...mapMutations('site/devices', ['setPerPage', 'setFilters']),
    print(device) {
      this.$root.$emit('printLabels', [device]);
    },
    printAll() {
      this.$root.$emit('printLabels', this.selectedDevices);
    },
    bulkEdit() {
      this.$root.$emit('bulkEditDevice', this.selectedDevices);
    },
    async onPaginationUpdate({page, pageCount}) {
      if (page === pageCount && this.hasMore) {
        this.loading = true;
        try {
          this.$logger.debug('loading next page');
          await this.nextPage();
        } catch (e) {
          this.$logger.error('next page', e);
        }
        this.loading = false;
      }
    },

    async doRefresh() {
      this.loading = true;
      try {
        this.$logger.debug('refresh');
        await this.refresh();
      } catch (e) {
        this.$logger.error('refresh', e);
      }
      this.loading = false;
    }
  }
};
</script>

<style scoped>
.devicename {
  overflow-wrap: anywhere;
}
.v-data-table >>> .v-data-table__wrapper tbody tr:hover {
  cursor: pointer;
}
.v-data-table.desktop >>> tr td:nth-child(2),
.v-data-table.desktop >>> tr td:nth-child(3),
.v-data-table.desktop >>> tr th:nth-child(2),
.v-data-table.desktop >>> tr th:nth-child(3) {
  /* no padding for installed and labelled columns */
  padding: 0;
}

.v-data-table >>> .v-data-table__wrapper .v-data-table-header-mobile__select {
  /* align header and row selects */
  min-width: unset;
  margin-left: -3px;
}
.v-data-table >>> .v-data-table__wrapper .v-data-table-header-mobile__select:after {
  content: 'Select All';
}
.v-data-table >>> .v-data-table__wrapper .v-data-table__mobile-row .v-input--selection-controls__input {
  /* align header and row selects */
  margin-left: -3px;
}

/*noinspection CssMissingComma*/
.v-data-table >>> .v-data-table__wrapper .v-data-table__mobile-table-row .v-data-table__mobile-row:first-child
  .v-data-table__mobile-row__header  {
  /* allow the first row (with the select) to fill the whole width */
  padding-right: 0;
}
/*noinspection CssMissingComma*/
.v-data-table >>> .v-data-table__wrapper .v-data-table__mobile-table-row .v-data-table__mobile-row:first-child
  .v-data-table__mobile-row__cell {
  /* allow the first row (with the select) to fill the whole width */
  width: 100%;
}

.v-select--is-multi >>> .v-select__selections {
  max-height: 32px;
  overflow-y: hidden;
}
</style>
