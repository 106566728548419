<template>
  <v-dialog v-model="open" max-width="480px" :fullscreen="$vuetify.breakpoint.mobile">
    <template #activator="{on}">
      <slot name="activator" :on="on">
        <v-btn v-on="on" outlined color="red" v-bind="$attrs">
          {{ label }}
        </v-btn>
      </slot>
    </template>

    <v-card>
      <v-card-title>
        <slot name="title">
          Are you sure you want to delete this?
        </slot>
      </v-card-title>
      <v-card-actions>
        <v-spacer/>
        <v-btn @click="open = false" text>No</v-btn>
        <v-btn @click="confirm" color="red">Yes</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmationDialog',
  props: {
    label: {
      type: String,
      default: 'Delete'
    }
  },
  data() {
    return {
      open: false
    };
  },
  methods: {
    confirm() {
      this.$emit('confirm');
      this.open = false;
    }
  }
};
</script>

<style scoped>

</style>
