import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"480","fullscreen":_vm.$vuetify.breakpoint.mobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c(VBtn,_vm._g({attrs:{"fab":"","depressed":"","small":"","color":"secondary"}},Object.assign({}, on, onTooltip)),[_c(VIcon,[_vm._v("mdi-plus")])],1)]}}],null,true)},[_vm._v(" Add a User to "+_vm._s(_vm.groupTitle)+" ")])]}}]),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Add a User to "+_vm._s(_vm.groupTitle))]),_c(VCardText,[_c(VContainer,[_c(VRow,[_c(VTextField,{attrs:{"label":"Email","value":_vm.form.email,"loading":_vm.existingUserSearch,"error-messages":_vm.emailErrors},on:{"change":_vm.setEmail}})],1),_c(VRow,[_c(VTextField,{attrs:{"label":"Name","disabled":_vm.existingUser !== null},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1),_c(VRow,[_c(VTextField,{attrs:{"label":"Job Role","disabled":_vm.existingUser !== null},model:{value:(_vm.jobRole),callback:function ($$v) {_vm.jobRole=$$v},expression:"jobRole"}})],1),_c(VRow,[_c(VCheckbox,{attrs:{"label":("Admin Access to " + _vm.groupTitle)},model:{value:(_vm.form.admin),callback:function ($$v) {_vm.$set(_vm.form, "admin", $$v)},expression:"form.admin"}})],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"disabled":_vm.loading,"text":""},on:{"click":function($event){_vm.open = false}}},[_vm._v(" Cancel ")]),_c(VBtn,{attrs:{"color":"secondary","loading":_vm.loading,"disabled":!_vm.valid},on:{"click":_vm.add}},[_vm._v(" Add ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }