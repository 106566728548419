import Vue from 'vue';
import {DocumentReference} from 'firebase/firestore';

const applyVueDevtoolsFix = process.env.NODE_ENV !== 'production';

/**
 * Returns the id of the document.
 *
 * @param {firebase.firestore.DocumentSnapshot|DecoratedData} doc
 * @return {string}
 */
export const byId = doc => doc && doc.id;

/**
 * Returns the ref.path of the document.
 *
 * @param {firebase.firestore.DocumentSnapshot|DecoratedData} doc
 * @return {string}
 */
export const byRef = doc => doc && doc.ref && doc.ref.path;

/**
 * Copy metadata properties from one snapshot to another.
 *
 * @param {firebase.firestore.DocumentSnapshot} from
 * @param {Object} to
 */
export function copyProperties(from, to) {
  Object.defineProperty(to, 'raw', {value: from, enumerable: false});
  Object.defineProperty(to, 'ref', {value: removeCircularReferences(from.ref), enumerable: false});
  Object.defineProperty(to, 'id', {value: from.id, enumerable: false});
  Object.defineProperty(to, 'exists', {value: from.exists, enumerable: false});
  Object.defineProperty(to, 'get', {value: from.get.bind(from), enumerable: false});
}

/**
 * Given a firestore DocumentReference, make all properties non-enumerable which fixes circular references
 *
 * @param {firebase.firestore.DocumentReference} ref
 * @return {firebase.firestore.DocumentReference}
 */
export function removeCircularReferences(ref) {
  if (!ref || ref.hasOwnProperty(':path')) return ref; // already safe
  for (const [key, value] of Object.entries(ref)) {
    Object.defineProperty(ref, key, {
      enumerable: false,
      writable: false,
      value
    });
  }
  Object.defineProperty(ref, ':path', {
    enumerable: true,
    value: ref.path
  });
  return ref;
}

/**
 * Makes all DocumentReferences in the nested object have their firestore properties non-enumerable.
 *
 * @param {*} obj
 */
export function replaceReferences(obj) {
  if (!applyVueDevtoolsFix) return;
  if (obj && typeof obj === 'object') {
    for (const key of Object.keys(obj)) {
      const val = obj[key];
      if (val instanceof DocumentReference) {
        removeCircularReferences(val);
      } else {
        replaceReferences(val);
      }
    }
  }
}

/**
 * Extracts the data from the snapshot but adds the ref and id properties to it.
 *
 * @param {firebase.firestore.DocumentSnapshot<T>} snapshot
 * @return {DecoratedData<T>}
 * @template T
 */
export function decorateSnapshot(snapshot) {
  const doc = snapshot.data() || {};
  copyProperties(snapshot, doc);
  replaceReferences(doc);
  return /** @type {DecoratedData} */ doc;
}


/**
 * Utilities relating to firestore and vuex.
 */
export default class FirestoreUtil {
  /**
   * Call this before committing the query snapshot.
   *
   * @param {firebase.firestore.QuerySnapshot} snapshot
   * @return {firebase.firestore.DocumentChange[]}
   */
  static prepareQuerySnapshot(snapshot) {
    return snapshot.docChanges().map(change => {
      return {
        type: change.type,
        oldIndex: change.oldIndex,
        newIndex: change.newIndex,
        doc: decorateSnapshot(change.doc)
      };
    });
  }

  /**
   * Apply the changes in snapshot to the array property of the given object.
   *
   * @param {Object} obj
   * @param {string} name The name of the array property we are to update
   * @param {firebase.firestore.DocumentChange[]} docChanges
   */
  static applyQuerySnapshotUpdates(obj, name, docChanges) {
    const array = obj[name] || Vue.set(obj, name, []);
    const actions = {
      /** @param {firebase.firestore.DocumentChange} change */
      added({newIndex, doc}) {
        array.splice(newIndex, 0, doc);
      },
      /** @param {firebase.firestore.DocumentChange} change */
      modified({oldIndex, newIndex, doc}) {
        if (oldIndex === newIndex) {
          // hasn't moved
          Vue.set(array, newIndex, doc);
        } else {
          Vue.delete(array, oldIndex);
          array.splice(newIndex, 0, doc);
        }
      },
      /** @param {firebase.firestore.DocumentChange} change */
      removed({oldIndex}) {
        Vue.delete(array, oldIndex);
      }
    };

    docChanges.forEach(c => actions[c.type](c));
  }

  /**
   * Index the updates in snapshot into the dict object. The indexFn will be used to create keys, by default the
   * document id will be used.
   *
   * @param {Object.<string,T>|function(firebase.firestore.DocumentSnapshot,string):Object.<string,T>} dict Either an
   *     object where the indexed document will be placed or a function that takes in the DocumentSnapshot and the
   *     update type and returns the object to store the indexed data.
   * @param {firebase.firestore.DocumentChange[]} docChanges
   * @param {function(firebase.firestore.DocumentSnapshot):string} [indexFn]
   * @param {function(firebase.firestore.DocumentSnapshot):T} [decorate] A function that converts a document snapshot
   *     into data that we care about. Defaults to decorateSnapshot. If the function returns a falsy value then the
   *     data will not be indexed.
   * @template {DecoratedData} T
   */
  static indexQuerySnapshotUpdates(dict, docChanges, indexFn = byId, decorate = doc => doc) {
    // normalise the dict
    if (typeof dict !== 'function') {
      const origDict = dict;
      dict = () => origDict;
    }
    if (!indexFn) indexFn = byId;

    const actions = {
      /** @param {firebase.firestore.DocumentChange} change */
      added({doc, type}) {
        const target = dict(doc, type);
        if (!target) return;
        const data = decorate(doc);
        if (!data) return;
        const key = indexFn(doc);
        if (!key) return;
        Vue.set(target, key, data);
      },
      /** @param {firebase.firestore.DocumentChange} change */
      modified({doc, type}) {
        const data = decorate(doc);
        if (!data) return;
        const target = dict(doc, type);
        if (!target) return;
        const key = indexFn(doc);
        if (!key) return;
        Vue.set(target, key, data);
      },
      /** @param {firebase.firestore.DocumentChange} change */
      removed({doc, type}) {
        const target = dict(doc, type);
        if (target) {
          const key = indexFn(doc);
          if (key) {
            Vue.delete(target, key);
          }
        }
      }
    };

    docChanges.forEach(c => actions[c.type](c));
  }
}
