<template>
  <v-dialog v-model="open" max-width="480" @input="!open && close()" :fullscreen="$vuetify.breakpoint.mobile">
    <template #activator="{on}">
      <v-tooltip bottom>
        <template #activator="{ on: onTooltip }">
          <v-btn icon v-on="{...on, ...onTooltip}">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        Edit {{ contractor.title }}
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title>Edit "{{ site.title }}" Access for "{{ contractor.title }}"</v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-subheader class="px-0">Systems Access</v-subheader>
          </v-row>
          <v-row>
            <contractor-subsystems-editor v-model="subsystems"/>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
            @click="close"
            :disabled="loading"
            text>
          Cancel
        </v-btn>
        <v-btn
            color="secondary"
            :loading="loading"
            :disabled="!hasEdits"
            @click="save">
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions} from 'vuex';
import ContractorSubsystemsEditor from '@/users/components/ContractorSubsystemsEditor';
import {isEqual} from 'lodash';

export default {
  name: 'ContractorEdit',
  components: {ContractorSubsystemsEditor},
  props: {
    contractor: {
      type: Object,
      required: true
    },
    site: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      open: false,
      saveData: {},
      loading: false
    };
  },
  computed: {
    existing() {
      const sites = this.contractor && this.contractor.sites || {};
      const site = sites[this.site.id] || {};
      return site.subsystems || {};
    },
    subsystems: {
      get() {
        if (this.saveData.hasOwnProperty('subsystems')) {
          return this.saveData.subsystems;
        }
        return this.existing;
      },
      set(subsystems) {
        if (isEqual(subsystems, this.existing)) {
          this.$delete(this.saveData, 'subsystems');
        } else {
          this.$set(this.saveData, 'subsystems', subsystems);
        }
      }
    },
    hasEdits() {
      return Object.values(this.saveData).length > 0;
    }
  },
  methods: {
    ...mapActions('users/organisations', ['updateOrganisationSiteSubsystems']),
    async save() {
      if (!this.hasEdits) return;
      if (this.saveData.hasOwnProperty('subsystems')) {
        const organisationId = this.contractor.id;
        const siteId = this.site.id;
        const subsystems = this.saveData.subsystems;
        try {
          this.loading = true;
          await this.updateOrganisationSiteSubsystems({organisationId, siteId, subsystems});
          this.open = false;
        } catch (e) {
          this.$logger.error('save', e);
        } finally {
          this.loading = false;
        }
      }
    },
    close() {
      this.open = false;
      this.saveData = {};
    }
  }
};
</script>

<style scoped>

</style>
