import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"offset-y":"","close-on-content-click":false,"content-class":_vm.isMobile ? 'is-mobile' : ''},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"ml-2",attrs:{"icon":"","color":"secondary"}},Object.assign({}, on, onMenu)),[_c(VIcon,[_vm._v("mdi-filter-variant")])],1)]}}],null,true)},[_vm._v(" More filters ")])]}}]),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c(VCard,[_c(VCardTitle,[_vm._v("All Device Filters")]),_c(VCardText,{staticClass:"filters-container"},[_c(VTextField,{staticClass:"search",attrs:{"label":"Search","clearable":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c(VList,{attrs:{"dense":""}},[_vm._l((_vm.filterOptions),function(f){return [_c(VListItem,{key:f.text,staticClass:"pa-0",attrs:{"ripple":""},on:{"click":function($event){_vm.setFilter({prop: f.value[0], value: f.value[2], remove: _vm.hasFilter(f.value)})}}},[_c(VListItemTitle,[_vm._v(_vm._s(f.text))]),_c(VListItemAction,{staticClass:"ma-0 pa-0"},[_c(VCheckbox,{staticClass:"pa-0 ma-0",attrs:{"input-value":_vm.hasFilter(f.value),"dense":""}})],1)],1)]})],2),_c('div'),_vm._l((_vm.fieldFilters),function(f){return [_c(VAutocomplete,{key:f.label,attrs:{"no-filter":"","items":f.items(f.prop, f.field),"label":f.label,"item-text":f.field,"item-value":f.field,"hide-details":"","clearable":""},on:{"change":function($event){return _vm.setFilter({prop: f.prop, value: $event})},"update:search-input":f.onSearch},model:{value:(_vm.fields[f.prop]),callback:function ($$v) {_vm.$set(_vm.fields, f.prop, $$v)},expression:"fields[f.prop]"}})]})],2),_c(VCardActions,[_c(VBtn,{attrs:{"text":""},on:{"click":_vm.clear}},[_vm._v("Clear All")]),_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.open = false}}},[_vm._v("Done")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }