<template>
  <v-container fluid>
    <v-toolbar flat>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-spacer/>
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn icon color="secondary" class="mx-2" v-on="on" @click="doRefresh">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
        Refresh the list
      </v-tooltip>
      <user-add
          :group-title="groupTitle"
          :site="isSiteUsers"
          :org="!isSiteUsers"/>
    </v-toolbar>
    <v-data-table
        :headers="headers"
        :items="usersPage"
        disable-sort

        :items-per-page="perPage"
        @update:items-per-page="setPerPage"

        :options.sync="options"
        @pagination="onPaginationUpdate"
        :server-items-length="asUsers.length"

        :footer-props="{'items-per-page-options': [25, 50, 100, 200]}"

        :loading="loading">
      <template #item.title="{ item }">
        <span v-if="item.title">{{ item.title }}</span>
        <span v-else>{{ item.email }}</span>
      </template>
      <template #item.isAdmin="{ item }">
        <v-simple-checkbox :ripple="false" :value="item.isAdmin" disabled/>
      </template>
      <template #item.actions="{ item }">
        <user-edit
            :user="item"
            :group-title="groupTitle"
            :site="isSiteUsers"
            :org="!isSiteUsers"/>
        <user-delete
            :user="item"
            :group-title="groupTitle"
            :site="isSiteUsers"
            :org="!isSiteUsers"/>
      </template>

      <template #footer.page-text="{pageStart, pageStop}">
        {{ pageStart }} - {{ pageStop }} of {{ itemCount }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex';
import UserEdit from '@/users/components/UserEdit';
import UserDelete from '@/users/components/UserDelete';
import UserAdd from '@/users/components/UserAdd';

export default {
  name: 'Users',
  components: {UserAdd, UserDelete, UserEdit},
  data() {
    return {
      headers: [
        {text: 'Name', value: 'title'},
        {text: 'Job Role', value: 'jobRole'},
        {text: 'Admin Access', value: 'isAdmin'},
        {text: 'Actions', value: 'actions'}
      ],
      options: {},
      loading: false
    };
  },
  computed: {
    ...mapGetters('user', ['siteRefPath', 'siteName', 'organisationRefPath', 'organisationName']),
    ...mapGetters('user/selectedSite', ['hasSiteOwnerAdminAccess']),
    groupTitle() {
      return this.isSiteUsers ? this.siteName : this.organisationName;
    },
    title() {
      const title = `Members of ${this.organisationName}`;
      if (this.isSiteUsers) {
        if (this.hasSiteOwnerAdminAccess) {
          return `Users with Access to ${this.siteName}`;
        }
        return `${title} with Access to ${this.siteName}`;
      }
      return title;
    },
    isSiteUsers() {
      return this.$route.name === 'site-users';
    },
    storePath() {
      return this.isSiteUsers ? `users/site` : `users/org`;
    },
    state() {
      return this.$store.state.users[this.isSiteUsers ? 'site' : 'org'];
    },
    asUsers() {
      return this.$store.getters[`${this.storePath}/asUsers`];
    },
    hasMore() {
      return this.$store.getters[`${this.storePath}/hasMore`];
    },
    hasResults() {
      return this.$store.getters[`${this.storePath}/hasResults`];
    },
    perPage() {
      return this.state.perPage;
    },
    usersPage() {
      const page = this.options.page;
      const perPage = this.perPage;
      const start = (page - 1) * perPage;
      return this.asUsers.slice(start, start + perPage);
    },
    itemCount() {
      const count = this.asUsers.length;
      if (this.hasMore) {
        return `${count}+`;
      }
      return count;
    }
  },
  watch: {
    organisationRefPath: {
      immediate: true,
      handler(organisation) {
        if (organisation && !this.isSiteUsers) {
          this.onPaginationUpdate({page: 1, pageCount: 1});
        }
      }
    },
    siteRefPath: {
      immediate: true,
      handler(site) {
        if (site && this.isSiteUsers) {
          this.onPaginationUpdate({page: 1, pageCount: 1});
        }
      }
    },
    isSiteUsers() {
      if (!this.hasResults) {
        this.onPaginationUpdate({page: 1, pageCount: 1});
      }
    }
  },
  methods: {
    setPerPage(p) {
      this.$store.commit(`${this.storePath}/setPerPage`, p);
    },
    async onPaginationUpdate({page, pageCount}) {
      if (page === pageCount && this.hasMore) {
        this.loading = true;
        try {
          this.$logger.debug('loading next page');
          await this.$store.dispatch(`${this.storePath}/nextPage`);
        } catch (e) {
          this.$logger.error('next page', e);
        }
        this.loading = false;
      }
    },

    async doRefresh() {
      this.loading = true;
      try {
        this.$logger.debug('refresh');
        await this.$store.dispatch(`${this.storePath}/refresh`);
      } catch (e) {
        this.$logger.error('refresh', e);
      }
      this.loading = false;
    }
  }
};
</script>

<style scoped>
.v-data-table >>> .v-data-table__wrapper .v-data-table__mobile-row .v-input--selection-controls__input {
  margin-right: -3px;
}
.v-data-table >>> .v-data-table__wrapper .v-data-table__mobile-row .v-data-table__mobile-row__cell .v-btn:last-child {
  margin-right: -11px;
}
</style>
