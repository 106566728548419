import {Logger} from '@vanti/vue-logger';
import {pagedListStore} from '@/util/vuex-firestore-paged-list';
import {collection, deleteDoc, doc, getDocs, query, setDoc, updateDoc, where} from 'firebase/firestore';

const log = Logger.get(`site/store/subsystems`);
const pagedList = pagedListStore({}, log);

export default {
  namespaced: true,
  state: {
    ...pagedList.state
  },
  getters: {
    ...pagedList.getters,
    whereConstraints() {
      return [];
    }
  },
  mutations: {
    ...pagedList.mutations
  },
  actions: {
    ...pagedList.actions,
    async collection({rootGetters}) {
      const siteRef = rootGetters['user/siteRef'];
      return collection(siteRef, 'subsystems');
    },

    async searchKeyword({state, rootGetters, dispatch}, keyWord) {
      const col = await dispatch('collection');
      log.debug(`searching for '${keyWord}' in ${col.path}`);
      const q = query(col, where('_keywords', 'array-contains', keyWord.toLowerCase()));
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs;
    },

    /**
     * @param {*} context
     * @param {Object} opts
     * @param {dials.firestore.Subsystem} opts.subsystem
     * @param {string} opts.id
     * @return {Promise<void>}
     */
    async addSubsystem({rootGetters}, {subsystem, id}) {
      log.debug('addSubsystem', subsystem);
      const siteRef = rootGetters['user/siteRef'];
      const subsystems = collection(siteRef, 'subsystems');
      const ref = doc(subsystems, id);
      await setDoc(ref, subsystem);
    },
    /**
     * @param {*} context
     * @param {dials.firestore.Subsystem & DecoratedData} subsystem
     * @return {Promise<void>}
     */
    async updateSubsystem({dispatch}, subsystem) {
      log.debug('updateSubsystem', subsystem);
      /** @type {Device} */
      await updateDoc(subsystem.ref, subsystem);

      // see if this subsystem is in the store, and update that copy
      dispatch('updateRecord', subsystem.ref);
    },
    /**
     * @param {*} context
     * @param {dials.firestore.Subsystem & DecoratedData} subsystem
     * @return {Promise<void>}
     */
    async deleteSubsystem({commit}, subsystem) {
      log.debug('deleteSubsystem', subsystem);
      await deleteDoc(subsystem.ref);
      // see if this subsystem is in the store, and remove that copy
      commit('removeRecordByRef', subsystem.ref);
    }
  }
};
