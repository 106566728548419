import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VForm,{ref:"deviceForm",on:{"submit":_vm.onChange}},[_c(VContainer,[_vm._t("prepend"),_c(VRow,[_c(VTextField,{staticClass:"mr-4",attrs:{"label":"Manufacturer","error-messages":_vm.manufacturerErrors},model:{value:(_vm.productManufacturer),callback:function ($$v) {_vm.productManufacturer=$$v},expression:"productManufacturer"}}),_c(VTextField,{attrs:{"label":"Model","error-messages":_vm.modelErrors},model:{value:(_vm.productModel),callback:function ($$v) {_vm.productModel=$$v},expression:"productModel"}})],1),_c(VRow,[_c(VAutocomplete,{attrs:{"auto-select-first":"","no-filter":"","hide-no-data":"","loading":_vm.isLoading,"items":_vm.types,"item-text":"title","item-value":_vm.productKind,"label":"Device Type","required":"","error-messages":_vm.typeErrors},on:{"update:search-input":_vm.searchInput},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.title))]),(item.hasOwnProperty('group'))?_c(VListItemSubtitle,[_vm._v(_vm._s(item.group))]):_vm._e()],1),_c(VListItemAction,[_c('strong',[_vm._v(_vm._s(item.code))])])]}}]),model:{value:(_vm.deviceType),callback:function ($$v) {_vm.deviceType=$$v},expression:"deviceType"}})],1),_c(VRow,[_c(VCol,{staticClass:"mr-2"},[_c(VRow,[_c(VTextField,{attrs:{"label":"Hardware Version"},model:{value:(_vm.productHardwareVersion),callback:function ($$v) {_vm.productHardwareVersion=$$v},expression:"productHardwareVersion"}})],1),_c(VRow,[_c(VTextField,{attrs:{"label":"Software Version"},model:{value:(_vm.productSoftwareVersion),callback:function ($$v) {_vm.productSoftwareVersion=$$v},expression:"productSoftwareVersion"}})],1)],1),_c(VCol,{staticClass:"ml-2"},[_c(VRow,[_c(VTextField,{attrs:{"label":"Firmware Version"},model:{value:(_vm.productFirmwareVersion),callback:function ($$v) {_vm.productFirmwareVersion=$$v},expression:"productFirmwareVersion"}})],1)],1)],1),_vm._t("append")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }