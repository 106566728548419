/**
 * @param {VueRouter} router
 * @param {Vuex.Store} store
 */
export default function pageTitles(router, store) {
  router.afterEach(to => {
    let title = null;

    if (to.meta.hasOwnProperty('title')) {
      const siteTitle = (store.getters['user/selectedSite/site'] || {}).title || '';
      const organisationTitle = (store.getters['user/selectedOrganisation/organisation'] || {}).title || '';
      title = to.meta.title
          .replace('{Site}', siteTitle)
          .replace('{Organisation}', organisationTitle);
    }

    if (title) {
      document.title = title + ` | DIALS`;
    } else {
      document.title = 'DIALS';
    }
  });
};
