<template>
  <v-app>
    <v-app-bar
        app
        color="primary"
        clipped-left
        v-if="hasAuthUser"
        dark>
      <v-app-bar-nav-icon @click="navDrawer = !navDrawer"/>
      <template v-if="!isMobile || !$route.name.includes('site')">
        <v-toolbar-title @click="$router.push({name: 'Home'})">{{ title }}</v-toolbar-title>
      </template>

      <v-spacer/>
      <router-view name="navbar"/>
    </v-app-bar>

    <nav-drawer v-if="hasAuthUser" v-model="navDrawer"/>

    <v-main>
      <router-view/>
      <router-view name="dialogs"/>
    </v-main>

    <v-snackbar :value="updateAvailable" :timeout="3600000" bottom right dark color="grey">
      <v-icon dark left class="fix-line-height">mdi-update</v-icon>
      A new version is available
      <template #action="{ attrs }">
        <v-btn @click="updateApp" dark text v-bind="attrs" :loading="reloading">Reload</v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>

import {mapActions, mapGetters, mapState} from 'vuex';
import NavDrawer from '@/core/components/NavDrawer';

export default {
  name: 'App',
  components: {NavDrawer},
  data: () => ({
    navDrawer: true,
    reloading: false
  }),
  computed: {
    ...mapGetters('auth', ['hasAuthUser']),
    ...mapGetters('user', ['siteName']),
    ...mapState('serviceWorker', ['updated']),
    updateAvailable() {
      return this.updated;
    },
    title() {
      let title = 'DIALS';
      if (this.siteName) {
        title += ` | ${this.siteName}`;
      }
      return title;
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    }
  },
  methods: {
    ...mapActions('serviceWorker', ['reload', 'update']),
    async updateApp() {
      this.reloading = true;
      try {
        // upgrade the service worker and refresh the page
        await this.reload();
        window.location.reload();
      } finally {
        this.reloading = false;
      }
    }
  }
};
</script>

<style>
.v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {
  /* dense mobile rows */
  min-height: 32px;
}
.v-data-table.v-data-table--mobile .v-data-footer {
  padding: 0;
}
.v-data-table.v-data-table--mobile .v-data-footer__select {
  margin: 0;
}
.v-data-table.v-data-table--mobile .v-data-footer__select .v-select {
  margin-left: 8px;
}

.v-dialog--fullscreen > *,
.v-dialog--fullscreen .v-card {
  min-height: 100%;
  min-width: 100%;
  margin: 0 !important;
  padding: 0 !important;
}
</style>
