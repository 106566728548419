import list from '@/users/store/list';
import {updateDoc, getDoc, doc} from 'firebase/firestore';
import {httpsCallable} from 'firebase/functions';
import {sendPasswordResetEmail} from 'firebase/auth';
import {Logger} from '@vanti/vue-logger';
import {db, functions, auth} from '@/firebase';
import {decorateSnapshot} from '@/util/vuex-firestore-util';
import organisations from '@/users/store/organisations';

const log = Logger.get(`users/store`);

export default {
  namespaced: true,
  modules: {
    organisations,
    site: list(),
    org: list(false)
  },
  actions: {
    async createUser({}, {email, props}) {
      log.debug('createUser', {email, props});
      const createUser = httpsCallable(await functions, 'users-create');
      await createUser({email, props});
      // note this can't be done easily by the function, but should live there
      await sendPasswordResetEmail(await auth, email);
    },
    /**
     * @param {*} context
     * @param {Object} options
     * @param {firebase.firestore.DocumentReference} options.ref
     * @param {*} options.updates
     * @return {Promise<void>}
     */
    async updateUser({state, commit}, {ref, updates}) {
      log.debug('updateUser', {ref: ref.path, updates});
      await updateDoc(ref, updates);

      // see if this user is in the store, and update that copy
      const user = await getDoc(ref);
      const siteUsers = state.site.records;
      const orgUsers = state.org.records;

      const siteUserIndex = siteUsers.findIndex(u => ref.id === u.id);
      const orgUserIndex = orgUsers.findIndex(u => ref.id === u.id);
      if (siteUserIndex !== -1) {
        commit('site/replaceRecordAtIndex', {record: user, index: siteUserIndex});
      }
      if (orgUserIndex !== -1) {
        commit('org/replaceRecordAtIndex', {record: user, index: orgUserIndex});
      }
    },
    async removeUser({state, commit}, {ref, updates}) {
      log.debug('removeUser', {ref: ref.path, updates});
      await updateDoc(ref, updates);

      const isSite = Object.keys(updates).every(u => u.startsWith('sites.'));
      // see if this user is in the store, and remove that copy
      const module = isSite ? 'site' : 'org';
      commit(`${module}/removeRecordByRef`, ref);
    },

    async userByEmail({state}, {email}) {
      log.debug('userByEmail', email);
      // check if we have them locally first
      let user = state.site.records.find(u => u.id === email);
      if (!user) {
        user = state.org.records.find(u => u.id === email);
      }
      if (user) {
        return user;
      }
      const userRef = doc(await db, `users/${email}`);
      user = await getDoc(userRef);
      if (user.exists()) {
        return decorateSnapshot(user);
      }
      return null;
    }
  }
};
