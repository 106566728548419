import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"480","fullscreen":_vm.$vuetify.breakpoint.mobile},on:{"input":function($event){!_vm.open && _vm.close()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":""}},Object.assign({}, on, onTooltip)),[_c(VIcon,[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_vm._v(" Edit "+_vm._s(_vm.contractor.title)+" ")])]}}]),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Edit \""+_vm._s(_vm.site.title)+"\" Access for \""+_vm._s(_vm.contractor.title)+"\"")]),_c(VCardText,[_c(VContainer,[_c(VRow,[_c(VSubheader,{staticClass:"px-0"},[_vm._v("Systems Access")])],1),_c(VRow,[_c('contractor-subsystems-editor',{model:{value:(_vm.subsystems),callback:function ($$v) {_vm.subsystems=$$v},expression:"subsystems"}})],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"disabled":_vm.loading,"text":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c(VBtn,{attrs:{"color":"secondary","loading":_vm.loading,"disabled":!_vm.hasEdits},on:{"click":_vm.save}},[_vm._v(" Submit ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }