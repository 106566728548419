import {Logger} from '@vanti/vue-logger';

const log = Logger.get('router.guard');

/**
 * Redirects to the loading page if the application hasn't loaded yet,
 *
 * @param {VueRouter} router
 * @param {Vuex.Store} store
 */
export default function guard(router, store) {
  router.beforeEach((to, from, next) => {
    log.debug('request to:', to, 'from:', from);

    if (!store.getters['app/loaded']) {
      if (to.name === 'loading') {
        return next();
      }
      log.debug('redirect to: loading');
      return next({name: 'loading', params: {redirect: to}});
    }

    if (store.getters['auth/isAuthenticated']) {
      // if trying to access a /site route without a selected site, redirect to home
      if (!store.getters['user/selectedSite/hasSelectedSite'] && to.name !== 'Home' && to.path.startsWith('/site')) {
        log.debug('redirect to: home');
        return next({name: 'Home', params: {redirect: to}});
      }
      // if trying to access an /organisations route without a selected organisation, redirect to home
      if (!store.getters['user/selectedOrganisation/hasSelectedOrganisation'] &&
          to.name !== 'Home' &&
          to.path.startsWith('/organisations')) {
        log.debug('redirect to: home');
        return next({name: 'Home'});
      }

      return next();
    }

    if (to.name !== 'login') {
      log.debug('redirect to: login');
      return next({name: 'login', params: {force: false, redirect: to}});
    }
    return next();
  });
};
