// Firebase App (the core Firebase SDK) is always required and must be listed first
import {initializeApp} from 'firebase/app';

import {connectAuthEmulator, getAuth, GoogleAuthProvider, OAuthProvider} from 'firebase/auth';
import {connectFirestoreEmulator, getFirestore} from 'firebase/firestore';
import {connectFunctionsEmulator, getFunctions} from 'firebase/functions';
import {connectStorageEmulator, getStorage} from 'firebase/storage';

const hosted = location.hostname !== 'localhost' && process.env.NODE_ENV !== 'development';
const emulated = location.hostname === 'localhost' && process.env.NODE_ENV === 'emulated';

/**
 * @typedef {Object} fb
 * @property {firebase.FirebaseApp} app
 * @property {firebase.auth.Auth} auth
 * @property {firebase.firestore.Firestore} db
 * @property {firebase.functions.Functions} functions
 * @property {firebase.storage.Storage} storage
 */

/**
 * @return {Promise<fb>}
 */
async function initFirebase() {
  let config = {
    apiKey: 'AIzaSyAuoFdEnKPxPDco7L6O5ZF5t63Ao3mZH_c',
    appId: '1:993606808606:web:80aca136951fb29b19ea0f',
    authDomain: 'dials-d884c.firebaseapp.com',
    databaseURL: 'https://dials-d884c.firebaseio.com',
    projectId: 'dials-d884c',
    storageBucket: 'dials-d884c.appspot.com',
    messagingSenderId: '993606808606'
  };
  if (hosted) {
    config = await fetch('/__/firebase/init.json').then(r => r.json());
    if (config.authDomain === 'dials-prod.firebaseapp.com' ||
      config.authDomain === 'dials-d884c.firebaseapp.com') {
      // The auth domain needs to match the host domain so third party cookies aren't blocked.
      // We'd configure this via the firebase console but that doesn't appear to be allowed.
      config.authDomain = location.hostname;
    }
  } else if (emulated) {
    // if we're using emulated mode, make sure we don't connect to anything
    delete config.authDomain;
    delete config.databaseURL;
  }
  const app = initializeApp(config);
  const auth = getAuth(app);
  const db = getFirestore(app);
  const functions = getFunctions(app, 'europe-west1');
  const storage = getStorage(app);
  if (hosted) {
    // initialise the analytics
    // todo: setup analytics
    // app.analytics();
  } else if (emulated) {
    const dbHost = (process.env.FIRESTORE_EMULATOR_HOST || 'localhost:5001').split(':');
    const authHost = process.env.FIREBASE_AUTH_EMULATOR_HOST || '127.0.0.1:9099';
    const storageHost = (process.env.FIREBASE_STORAGE_EMULATOR_HOST || 'localhost:9199').split(':');
    const functionsHost = (process.env.FIREBASE_FUNCTIONS_EMULATOR_HOST || 'localhost:5000').split(':');

    connectFirestoreEmulator(db, dbHost[0], parseInt(dbHost[1]));
    connectAuthEmulator(auth, `http://${authHost}`);
    connectFunctionsEmulator(functions, functionsHost[0], parseInt(functionsHost[1]));
    connectStorageEmulator(storage, storageHost[0], parseInt(storageHost[1]));
  }
  return {app, db, auth, functions, storage};
}

export const fb = initFirebase();
export const auth = fb.then(fb => fb.auth);
export const db = fb.then(fb => fb.db);
export const functions = fb.then(fb => fb.functions);
export const storage = fb.then(fb => fb.storage);

export const authProviders = {
  google: new GoogleAuthProvider(),
  microsoft: new OAuthProvider('microsoft.com')
};
