import {Logger} from '@vanti/vue-logger';
import {doc, onSnapshot} from 'firebase/firestore';
import {Device} from '@/site/Device';
import DeferUtil from '@/util/vuex-defer';
import {decorateSnapshot} from '@/util/vuex-firestore-util';
import {LoadingUtil} from '@/util/vuex-loading';
import {devicesStore} from '@/util/vuex-devices-store';

const log = Logger.get(`site/store/devices`);
const selectedLog = Logger.get(`site/store/devices/selected`);
const devices = devicesStore({}, log);

export default {
  namespaced: true,
  // spread each part so that IntelliJ still finds it all
  state: {
    ...devices.state
  },
  getters: {
    ...devices.getters
  },
  mutations: {
    ...devices.mutations
  },
  actions: {
    ...devices.actions
  },
  modules: {
    selected: {
      namespaced: true,
      state: {
        deviceId: null,
        device: null,
        ...LoadingUtil.state('device')
      },
      getters: {
        ...LoadingUtil.getters(selectedLog),
        selectedDevice(state, getters, rootState, rootGetters) {
          if (state.device) {
            return state.device;
          }
          return rootGetters['site/devices/asDevices'].find(d => d.uuid === state.deviceId);
        }
      },
      mutations: {
        ...DeferUtil.mutations(selectedLog),
        ...LoadingUtil.mutations(),
        setDeviceId(state, id) {
          state.deviceId = id;
        },
        setDevice(state, d) {
          state.device = d;
        },
        clear(state) {
          state.deviceId = null;
          state.device = null;
        }
      },
      actions: {
        async bind({commit, dispatch}, id) {
          commit('loading', 'device');
          commit('setDeviceId', id);
          const devices = await dispatch('site/devices/collection', null, {root: true});
          const ref = doc(devices, id);
          const defer = {};
          defer.device = onSnapshot(ref, {
            next(snap) {
              if (!snap.exists()) {
                commit('setDevice', null);
              } else {
                const decorated = decorateSnapshot(snap);
                decorated.uuid = snap.id;
                const d = new Device(decorated);
                d.firestoreRef = snap.ref;
                commit('setDevice', d);
              }
              commit('loaded', 'device');
            },
            error(err) {
              selectedLog.warn(`${ref.path}.onSnapshot`, err);
              commit('loaded', 'device');
            }
          });
          commit('defer', defer);
        },
        async unbind({commit}) {
          commit('reset');
          commit('clear');
        }
      }
    }
  }
};
