export default {
  namespaced: true,
  getters: {
    organisation(state, getters, rootState) {
      return rootState.user.organisation;
    },
    hasSelectedOrganisation(state, getters) {
      return Boolean(getters.organisation);
    },
    organisationRole(state, getters, rootState, rootGetters) {
      const memberships = rootGetters['user/memberships'];
      const organisation = getters.organisation;
      return (organisation && memberships[organisation.id]) || {};
    },
    organisationAccess(state, getters) {
      return getters.organisationRole && getters.organisationRole.role;
    },
    hasOrganisationAdminAccess(state, getters) {
      return getters.organisationAccess === 'admin';
    },
    organisationIsSiteOwner(state, getters, rootState, rootGetters) {
      if (!getters.organisation) return false;
      const siteOwnerId = rootGetters['user/selectedSite/ownerId'];
      return siteOwnerId === (getters.organisation && getters.organisation.id);
    },
    organisationSites(state, getters) {
      return getters.organisation && getters.organisation.sites || {};
    },
    organisationOwnedSites(state, getters) {
      return getters.organisation && getters.organisation._ownedSites || {};
    },
    organisationAllSites(state, getters) {
      const owned = {};
      // format the owned sites the same as the sites property
      for (const [id, snip] of Object.entries(getters.organisationOwnedSites)) {
        owned[id] = {
          site: snip,
          role: 'owner'
        };
      }
      return {
        ...getters.organisationSites,
        ...owned
      };
    }
  }
};
