<template>
  <div class="font-weight-bold display-name">
    <template v-if="hasDisplayName && !editing && !loading">
      <span class="text-center">{{ displayName }}</span>
      <v-btn @click="editing = true" icon>
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </template>
    <template v-else>
      <v-text-field
          :loading="loading"
          :label="textBoxLabel"
          @click:append="setDisplayName"
          @keydown.enter="setDisplayName"
          append-icon="mdi-check"
          v-model="name"/>
    </template>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'UserTitle',
  data() {
    return {
      editing: false,
      name: '',
      loading: false
    };
  },
  computed: {
    ...mapGetters('user', ['displayName']),
    hasDisplayName() {
      return Boolean(this.displayName);
    },
    textBoxLabel() {
      return this.hasDisplayName ? 'Display Name' : 'Add a display name';
    }
  },
  watch: {
    displayName: {
      handler(value) {
        this.name = value;
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('user', ['updateUserTitle']),
    async setDisplayName() {
      if (this.name === this.displayName) {
        this.editing = false;
        return;
      }
      try {
        this.loading = true;
        await this.updateUserTitle(this.name);
      } catch (e) {
        this.$logger.error('setDisplayName', e);
      } finally {
        this.editing = false;
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
.display-name {
  position: relative;
  min-height: 36px;
}

.display-name span {
  width: calc(100% - 72px);
  margin-top: 7px;
  margin-left: 36px;
  display: inline-block;
}

.display-name button {
  position: absolute;
  right: 0;
}
</style>
