import {db} from '@/firebase';
import {collection, where} from 'firebase/firestore';
import {User} from '@/users/User';
import store from '@/core/store';
import {Logger} from '@vanti/vue-logger';
import {pagedListStore} from '@/util/vuex-firestore-paged-list';

/**
 * Returns a new user-list store
 *
 * @param {boolean} [site]
 * @return {Object}
 */
export default function(site = true) {
  const log = Logger.get(`users/store/${site ? 'site' : 'org'}`);
  const pagedList = pagedListStore({}, log);
  return {
    namespaced: true,
    state: {
      ...pagedList.state
    },
    getters: {
      ...pagedList.getters,
      asUsers(state) {
        return state.records.map(u => new User(u, store));
      },

      siteId(state, getters, rootState, rootGetters) {
        const siteRef = rootGetters['user/siteRef'];
        return siteRef && siteRef.id;
      },
      organisationId(state, getters, rootState, rootGetters) {
        const organisationRef = rootGetters['user/organisationRef'];
        return organisationRef && organisationRef.id;
      },
      whereConstraints(state, getters, rootState, rootGetters) {
        const field = site ? '_siteIds' : '_membershipIds';
        const value = site ? getters.siteId : getters.organisationId;
        if (!value) {
          return null;
        }
        const constraints = [
          where(`${field}.${value}`, '==', true)
        ];
        if (site && !rootGetters['user/selectedSite/hasSiteOwnerAdminAccess']) {
          // only show site users who work for the contractor, unless site owner (then show everyone)
          constraints.push(
              where(`_membershipIds.${getters.organisationId}`, '==', true)
          );
        }
        return constraints;
      }
    },
    mutations: pagedList.mutations,
    actions: {
      ...pagedList.actions,
      init: {
        root: true,
        handler({rootGetters, commit}) {
          if (site) {
            this.watch(
                () => rootGetters['user/siteRef'],
                siteRef => {
                  if (!siteRef) {
                    commit('clear');
                  }
                }
            );
          } else {
            this.watch(
                () => rootGetters['user/organisationRef'],
                organisationRef => {
                  if (!organisationRef) {
                    commit('clear');
                  }
                }
            );
          }
        }
      },
      async collection() {
        return collection(await db, 'users');
      }
    }
  };
}

