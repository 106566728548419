import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"py-2",class:{'small-label': _vm.isSmallLabel}},[_c(VCardTitle,[_c('span',[_vm._v("Print "+_vm._s(_vm.devices.length)+" Label"+_vm._s(_vm.devices.length > 1 ? 's' : ''))]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),(!_vm.validUuid)?_c('div',{staticStyle:{"color":"red","text-align":"center"}},[_c(VIcon,{attrs:{"color":"red"}},[_vm._v("mdi-alert")]),_vm._v(" Invalid GUID detected ")],1):_vm._e(),_c(VContainer,{staticClass:"pa-6 mt-2",attrs:{"id":"label"}},[_c('span',{staticClass:"device-name"},[_vm._v(_vm._s(_vm.previewDeviceName))]),_c('span',{staticClass:"location"},[_vm._v(_vm._s(_vm.previewDeviceLocation))]),_c('vue-qr-code',{staticClass:"qrcode",attrs:{"value":_vm.previewDeviceQrCode,"margin":0}})],1),_c(VCardActions,[(_vm.smallLabelOption && _vm.largeLabelOption)?_c(VBtnToggle,{attrs:{"mandatory":""},model:{value:(_vm.isSmallLabel),callback:function ($$v) {_vm.isSmallLabel=$$v},expression:"isSmallLabel"}},[_c(VBtn,{attrs:{"value":true}},[_vm._v("Small")]),_c(VBtn,{attrs:{"value":false}},[_vm._v("Large")])],1):_vm._e(),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","small":"","disabled":_vm.preview === 0},on:{"click":function($event){_vm.preview--}}},[_c(VIcon,[_vm._v("mdi-chevron-left")])],1),_c('span',[_vm._v(_vm._s(_vm.preview + 1)+"/"+_vm._s(_vm.devices.length))]),_c(VBtn,{attrs:{"icon":"","small":"","disabled":_vm.preview === _vm.devices.length - 1},on:{"click":function($event){_vm.preview++}}},[_c(VIcon,[_vm._v("mdi-chevron-right")])],1),_c(VSpacer),_c(VBtn,{attrs:{"color":"secondary"},on:{"click":_vm.exportCsv}},[_vm._v("Export")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }