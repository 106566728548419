import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,[(_vm.hasAuthUser)?_c(VAppBar,{attrs:{"app":"","color":"primary","clipped-left":"","dark":""}},[_c(VAppBarNavIcon,{on:{"click":function($event){_vm.navDrawer = !_vm.navDrawer}}}),(!_vm.isMobile || !_vm.$route.name.includes('site'))?[_c(VToolbarTitle,{on:{"click":function($event){return _vm.$router.push({name: 'Home'})}}},[_vm._v(_vm._s(_vm.title))])]:_vm._e(),_c(VSpacer),_c('router-view',{attrs:{"name":"navbar"}})],2):_vm._e(),(_vm.hasAuthUser)?_c('nav-drawer',{model:{value:(_vm.navDrawer),callback:function ($$v) {_vm.navDrawer=$$v},expression:"navDrawer"}}):_vm._e(),_c(VMain,[_c('router-view'),_c('router-view',{attrs:{"name":"dialogs"}})],1),_c(VSnackbar,{attrs:{"value":_vm.updateAvailable,"timeout":3600000,"bottom":"","right":"","dark":"","color":"grey"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c(VBtn,_vm._b({attrs:{"dark":"","text":"","loading":_vm.reloading},on:{"click":_vm.updateApp}},'v-btn',attrs,false),[_vm._v("Reload")])]}}])},[_c(VIcon,{staticClass:"fix-line-height",attrs:{"dark":"","left":""}},[_vm._v("mdi-update")]),_vm._v(" A new version is available ")],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }