<template>
  <v-menu :close-on-content-click="false" bottom offset-y v-model="menuOpen">
    <template #activator="{on}">
      <v-tooltip bottom>
        <template #activator="{on: onTooltip}">
          <v-btn icon text v-on="{...onTooltip, ...on}">
            <v-icon color="grey">mdi-account-circle</v-icon>
          </v-btn>
        </template>

        <span>My Account</span>
      </v-tooltip>
    </template>

    <v-card width="300">
      <v-card-title class="justify-center">
        <v-icon size="48">mdi-account-circle</v-icon>
      </v-card-title>
      <v-card-text class="text-center">
        <user-title class="text-left"/>
        <div class="my-2">{{ userEmail }}</div>
        <password-update v-if="isPasswordAccount" :email="userEmail"/>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn @click="logout" depressed block>Logout</v-btn>
      </v-card-actions>
      <about-line class="px-2 pb-2"/>
    </v-card>
  </v-menu>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import AboutLine from './AboutLine';
import UserTitle from '@/core/components/UserTitle';
import PasswordUpdate from '@/core/components/PasswordUpdate';

export default {
  name: 'AccountMenu',
  components: {PasswordUpdate, UserTitle, AboutLine},
  data() {
    return {
      menuOpen: false
    };
  },
  computed: {
    ...mapGetters('auth', [
      'userEmail', 'isPasswordAccount'
    ])
  },
  methods: {
    ...mapActions('auth', [
      'signOut'
    ]),
    logout() {
      try {
        this.signOut();
        this.$router.push({name: 'login'});
      } catch (e) {
        this.$logger.error('route push aborted');
      }
    }
  }
};
</script>

<style scoped>
  .display-name {
    position: relative;
    min-height: 36px;
  }

  .display-name span {
    width: calc(100% - 72px);
    margin-top: 7px;
    margin-left: 36px;
    display: inline-block;
  }

  .display-name button {
    position: absolute;
    right: 0;
  }
</style>
