<template>
  <v-card>
    <v-card-title color="secondary">{{ addNew ? 'Add' : 'Edit' }} Product</v-card-title>
    <v-card-text>
      <product-form
          :product="product"
          :add-new="addNew"
          @input="data = $event"
          @update:canSubmit="canSubmit = $event"/>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn @click="cancel" text>Cancel</v-btn>
      <v-btn @click="submit" color="secondary" :disabled="!canSubmit">Submit</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ProductForm from '@/site/components/edit/ProductForm.vue';

export default {
  name: 'EditProductForm',
  components: {ProductForm},
  props: {
    product: {
      type: Object,
      default: () => {
        return {type: {}, location: {}};
      }
    },
    addNew: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      canSubmit: false,
      data: {}
    };
  },
  methods: {
    submit() {
      if (this.addNew) {
        this.$emit('create', this.data);
      } else {
        this.$emit('update', this.data);
      }
      this.$emit('close', {});
    },
    cancel() {
      this.$emit('close', {});
    }
  }
};
</script>

<style scoped>

</style>
