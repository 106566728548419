/**
 * The NIL value for a UUID
 *
 * @type {string}
 */
export const NIL = '00000000-0000-0000-0000-000000000000';

/**
 * Checks whether a UUID is valid.
 *
 * This differs from standard UUID validation as BIM models (and explicitly Revit) often add an extra 8 digits to the
 * end to represent the ElementID.
 *
 * @param {string} uuid
 * @return {boolean}
 */
export function validate(uuid) {
  if (typeof uuid !== 'string') return false;
  if (uuid === NIL) return true;
  const regex = /^(?:[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})(?:-[0-9a-f]{8}){0,1}$/i;
  return regex.test(uuid);
}

/**
 * Detects the version of the UUID provided (1-5, 0 for nil, or 6 for revit)
 *
 * @param {string} uuid
 * @return {number}
 */
export function getVersion(uuid) {
  if (!validate(uuid)) throw new TypeError('invalid UUID provided');
  if (uuid === NIL) return 0;
  if (uuid.length === 45) return 6;
  return parseInt(uuid.substr(14, 1));
}

/**
 * Converts a standard hex UUID string to an integer byte array.
 *
 * Depending on the version of UUID used, the endianness of the bits is different - this function will return a
 * correctly ordered byte array.
 *
 * @param {string} uuid
 * @return {int[]}
 */
export function uuidToBytes(uuid) {
  const v = getVersion(uuid);
  if (v < 2) {
    return parseBE(uuid);
  } else if (v < 6) {
    return parseME(uuid);
  } else {
    return parseRevit(uuid);
  }
}

/**
 * Parse a uuid into a big-endian byte array - this version is (usually) used by v1 uuids
 *
 * @param {string} uuid
 * @return {*[]}
 */
function parseBE(uuid) {
  const bytes = [];
  uuid.split('-').map((number) => {
    const bytesInChar = number.match(/.{1,2}/g);
    bytesInChar.map((byte) => {
      bytes.push(parseInt(byte, 16));
    });
  });
  return bytes;
}

/**
 * Parse a uuid into a 'mixed-endian' byte array - this version is (usually) used by v2-5 uuids
 * (see https://en.wikipedia.org/wiki/Universally_unique_identifier#Encoding, and
 *   https://uuid.ramsey.dev/en/latest/nonstandard/guid.html)
 *
 * @param {string} uuid
 * @return {*[]}
 */
function parseME(uuid) {
  const bytes = [];
  uuid.split('-').map((number, index) => {
    const bytesInChar = index < 3 ? number.match(/.{1,2}/g).reverse() : number.match(/.{1,2}/g);
    bytesInChar.map((byte) => {
      bytes.push(parseInt(byte, 16));
    });
  });
  return bytes;
}

/**
 * Parse a Revit-style uuid into a byte array
 *
 * @param {string} uuid
 * @return {*[]}
 */
function parseRevit(uuid) {
  const bytes = parseME(uuid.slice(0, 36));
  const bytesInChar = uuid.slice(37).match(/.{1,2}/g);
  bytesInChar.map((byte) => {
    bytes.push(parseInt(byte, 16));
  });
  return bytes;
}

/**
 * Converts a standard hex UUID (composed of 36 chars = 32 digits + 4 hyphens) to a base64-encoded UUID (composed of 22
 * ascii chars). This effectively compresses the string for use in (e.g.) a QR Code.
 *
 * @param {string} uuid
 * @return {string}
 */
export function uuidToBase64(uuid) {
  const bytes = uuidToBytes(uuid);
  const enc = btoa(String.fromCharCode.apply(null, bytes));
  return enc;
}

/**
 *
 * @param {string} base64
 * @return {string}
 */
export function base64ToUuid(base64) {
  const bytes = atob(base64).split('').map(function(c) {
    return c.charCodeAt(0).toString(16).padStart(2, '0');
  });
  if (bytes.length > 16) {
    return toRevitString(bytes);
  }
  const v = parseInt(bytes.slice(7, 8).toString().slice(0, 1));
  if (v > 1 && v < 6) {
    return toMEString(bytes);
  }
  return toBEString(bytes);
}

/**
 * Converts a big-endian encoded byte buffer into UUID string
 *
 * @param {Uint8Array} buffer
 * @return {*}
 */
function toBEString(buffer) {
  let chars = buffer.slice(0, 4).join('');
  chars += '-';
  chars += buffer.slice(4, 6).join('');
  chars += '-';
  chars += buffer.slice(6, 8).join('');
  chars += '-';
  chars += buffer.slice(8, 10).join('');
  chars += '-';
  chars += buffer.slice(10, 22).join('');
  return chars;
}

/**
 * Converts a middle-endian encoded byte buffer into UUID string
 *
 * @param {Uint8Array} buffer
 * @return {*}
 */
function toMEString(buffer) {
  let chars = buffer.slice(0, 4).reverse().join('');
  chars += '-';
  chars += buffer.slice(4, 6).reverse().join('');
  chars += '-';
  chars += buffer.slice(6, 8).reverse().join('');
  chars += '-';
  chars += buffer.slice(8, 10).join('');
  chars += '-';
  chars += buffer.slice(10, 16).join('');
  return chars;
}

/**
 * Converts a byte buffer into a Revit-based UUID string (with the additional 8 digits on the end)
 *
 * @param {Buffer} buffer
 * @return {*}
 */
function toRevitString(buffer) {
  let chars = toMEString(buffer);
  chars += '-';
  chars += buffer.slice(16).join('');
  return chars;
}
