<template>
  <v-tooltip bottom>
    <template #activator="{ on }">
      <v-btn icon v-on="on" @click="remove" :loading="loading">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </template>
    Remove {{ user.title }} from {{ groupTitle }}
  </v-tooltip>
</template>

<script>
import {deleteField} from 'firebase/firestore';
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'UserDelete',
  props: {
    groupTitle: {
      type: String,
      default: ''
    },
    user: {
      type: Object,
      required: true
    },
    org: {
      type: Boolean,
      default: false
    },
    site: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapGetters('user', ['siteRef', 'organisationRef'])
  },
  methods: {
    ...mapActions('users', ['removeUser']),
    async remove() {
      this.loading = true;
      const updates = {};
      if (this.site) {
        const siteId = this.siteRef.id;
        updates[`sites.${siteId}`] = deleteField();
      } else if (this.org) {
        const orgId = this.organisationRef.id;
        updates[`memberships.${orgId}`] = deleteField();
      }
      try {
        await this.removeUser({ref: this.user.ref, updates});
        this.open = false;
      } catch (e) {
        this.$logger.error('save', e);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>

</style>
