import {Logger} from '@vanti/vue-logger';
import {pagedListStore} from '@/util/vuex-firestore-paged-list';
import {collection, where, deleteField, updateDoc, query, getDocs, doc, getDoc, setDoc} from 'firebase/firestore';
import {db} from '@/firebase';
import {decorateSnapshot} from '@/util/vuex-firestore-util';

const log = Logger.get(`users/store/organisations`);
const pagedList = pagedListStore({}, log);

export default {
  namespaced: true,
  state: {
    ...pagedList.state
  },
  getters: {
    ...pagedList.getters,
    whereConstraints(state, getters, rootState, rootGetters) {
      const siteRef = rootGetters['user/siteRef'];
      if (!siteRef) {
        return null;
      }
      return [where(`_siteIds.${siteRef.id}`, '==', true)];
    }
  },
  mutations: {
    ...pagedList.mutations
  },
  actions: {
    ...pagedList.actions,
    async collection() {
      return collection(await db, 'organisations');
    },

    async addNewOrganisation({dispatch}, organisation) {
      log.debug('addNew', {organisation});
      const organisationRef = doc(await dispatch('collection'));
      await setDoc(organisationRef, organisation);
      return organisationRef;
    },

    async fetch({dispatch}, organisationId) {
      log.debug('fetch', {organisationId});
      const organisationRef = doc(await dispatch('collection'), organisationId);
      const organisationDoc = await getDoc(organisationRef);
      return decorateSnapshot(organisationDoc);
    },

    async addAccessToOrganisation({dispatch}, {organisationId, site}) {
      log.debug('addAccessToOrganisation', {organisationId, site: site.ref.path});
      const organisationRef = doc(await dispatch('collection'), organisationId);
      await updateDoc(organisationRef, {
        [`sites.${site.id}`]: {
          site: {
            ref: site.ref,
            title: site.title
          },
          role: 'user'
        }
      });
      await dispatch('updateRecord', organisationRef);
    },

    async updateOrganisationSiteSubsystems({dispatch}, {organisationId, siteId, subsystems}) {
      log.debug('updateOrganisationSiteSubsystems', {organisationId, site: siteId});
      const organisationRef = doc(await dispatch('collection'), organisationId);
      await updateDoc(organisationRef, {
        [`sites.${siteId}.subsystems`]: subsystems
      });
      await dispatch('updateRecord', organisationRef);
    },

    async revokeAccessToOrganisation({commit}, {ref, siteId}) {
      log.debug('revokeAccessToOrganisation', {ref: ref.path, siteId});
      await updateDoc(ref, {[`sites.${siteId}`]: deleteField()});
      commit('removeRecordByRef', ref);
    },

    async searchKeyword({dispatch}, term) {
      const organisations = await dispatch('collection');
      log.debug(`searching for '${term}' in ${organisations.path}`);
      const q = query(organisations, where('_keywords', 'array-contains', term.toLowerCase()));
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map(d => decorateSnapshot(d));
    }
  }
};
