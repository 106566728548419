<template>
  <v-navigation-drawer
      app
      clipped
      @input="$emit('input', $event)"
      :value="value">
    <v-toolbar flat dense class="nav-toolbar">
      {{ displayName }}
      <v-spacer/>
      <account-menu/>

      <template #extension>
        <working-for-header/>
      </template>
    </v-toolbar>

    <v-list dense>
      <v-list-group
          color="secondary"
          :group="i.group"
          :key="i.title"
          v-for="i in menu">
        <template #activator>
          <v-list-item-icon>
            <v-icon>{{ i.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ i.title }}</v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item-group class="sub-menu-item">
          <v-list-item
              color="primary"
              :key="link.title"
              :to="link.to"
              link
              v-for="link in i.links">
            <v-list-item-icon>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ link.title }}
            </v-list-item-title>
            <v-chip v-if="link.badge" small color="accent darken-1" :style="{flexBasis: '35%'}">
              {{ link.badge }}
            </v-chip>
          </v-list-item>
        </v-list-item-group>
      </v-list-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import {mapGetters} from 'vuex';
import AccountMenu from '@/core/components/AccountMenu';
import WorkingForHeader from '@/core/components/WorkingForHeader';

export default {
  name: 'NavDrawer',
  components: {WorkingForHeader, AccountMenu},
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('user', ['organisationName', 'displayName']),
    ...mapGetters('user/selectedSite', [
      'hasSiteOwnerAdminAccess',
      'hasSiteAdminAccess',
      'hasSelectedSite',
      'siteId',
      'site'
    ]),
    ...mapGetters('user/selectedSite/config', [
      'enableMapView'
    ]),
    ...mapGetters('user/selectedOrganisation', [
      'hasOrganisationAdminAccess',
      'organisation'
    ]),
    menu() {
      const menu = [];
      const siteMenu = this.siteMenu;
      if (siteMenu) {
        menu.push(siteMenu);
      }
      const organisationMenu = this.organisationMenu;
      if (organisationMenu) {
        menu.push(organisationMenu);
      }
      return menu;
    },
    organisationMenu() {
      if (!this.hasOrganisationAdminAccess) {
        return null;
      }
      return {
        title: this.organisationName || 'Organisation',
        group: 'organisation',
        icon: 'mdi-account-group',
        links: [
          {
            title: 'Users',
            icon: 'mdi-account-box-multiple',
            to: {name: 'organisation-users', params: {organisationId: this.organisation.id}}
          }
        ]
      };
    },
    siteMenu() {
      if (!this.hasSelectedSite) {
        return null;
      }
      const site = {
        title: this.site.title || 'Site',
        group: 'site',
        icon: 'mdi-domain',
        links: []
      };
      if (this.enableMapView) {
        site.links.push({
          title: 'Map',
          icon: 'mdi-map',
          to: {name: 'site-map', params: {siteId: this.siteId}}
        });
      }
      site.links.push(
          {
            title: 'Devices',
            icon: 'mdi-cellphone-link',
            to: {name: 'site-devices', params: {siteId: this.siteId}}
          },
          {
            title: 'Locations',
            icon: 'mdi-map-marker',
            to: {name: 'site-locations', params: {siteId: this.siteId}}
          },
          {
            title: 'Products',
            icon: 'mdi-format-list-bulleted',
            to: {name: 'site-products', params: {siteId: this.siteId}}
          },
          {
            title: 'Systems',
            icon: 'mdi-format-list-group',
            to: {name: 'site-subsystems', params: {siteId: this.siteId}}
          }
      );
      if (this.hasSiteOwnerAdminAccess) {
        site.links.push({
          title: 'Contractors',
          icon: 'mdi-card-account-details',
          to: {name: 'site-contractors', params: {siteId: this.siteId}}
        });
      }
      if (this.hasSiteAdminAccess) {
        site.links.push({
          title: 'Users',
          icon: 'mdi-account-box-multiple',
          to: {name: 'site-users', params: {siteId: this.siteId}}
        });
      }
      return site;
    }
  }
};
</script>

<style scoped>
.nav-toolbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.sub-menu-item {
  margin-left: 24px;
}

.v-list-item__icon {
  margin-right: 16px !important;
}

.v-list-group >>> .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon {
  min-width: 24px;
}
</style>
