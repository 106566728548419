<template>
  <v-dialog v-model="open" max-width="480" :fullscreen="$vuetify.breakpoint.mobile">
    <template #activator="{on}">
      <v-tooltip bottom>
        <template #activator="{ on: onTooltip }">
          <v-btn icon v-on="{...on, ...onTooltip}">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        Edit {{ user.title }}
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title>Edit {{ user.title }}</v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-text-field label="Name" v-model="title"/>
          </v-row>
          <v-row>
            <v-text-field label="Job Role" v-model="jobRole"/>
          </v-row>
          <v-row>
            <v-checkbox :label="`Admin Access to ${groupTitle}`" v-model="isAdmin"/>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
            @click="open = false"
            :disabled="loading"
            text>
          Cancel
        </v-btn>
        <v-btn
            color="secondary"
            :loading="loading"
            :disabled="!hasEdits"
            @click="save">
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'UserEdit',
  props: {
    groupTitle: {
      type: String,
      default: ''
    },
    user: {
      type: Object,
      required: true
    },
    org: {
      type: Boolean,
      default: false
    },
    site: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      open: false,
      form: {
        title: null,
        jobRole: null,
        isAdmin: null
      },
      loading: false
    };
  },
  computed: {
    ...mapGetters('user', ['siteRef', 'organisationRef']),
    hasEdits() {
      return Object.entries(this.form)
          .some(([k, v]) => v !== null && this.user[k] !== v);
    },
    title: {
      get() {
        return this.form.title || this.user.title;
      },
      set(t) {
        this.form.title = t;
      }
    },
    jobRole: {
      get() {
        return this.form.jobRole || this.user.jobRole;
      },
      set(t) {
        this.form.jobRole = t;
      }
    },
    isAdmin: {
      get() {
        return this.form.isAdmin || this.user.isAdmin;
      },
      set(t) {
        this.form.isAdmin = t;
      }
    }
  },
  methods: {
    ...mapActions('users', ['updateUser']),
    async save() {
      if (!this.hasEdits) return;
      this.loading = true;
      const updates = {};
      const simpleProps = ['title', 'jobRole'];
      for (const prop of simpleProps) {
        if (this.form[prop] !== null && this.form[prop] !== this.user[prop]) {
          updates[prop] = this.form[prop];
        }
      }
      if (this.form.isAdmin !== null && this.form.isAdmin !== this.user.isAdmin) {
        if (this.site) {
          const siteId = this.siteRef.id;
          updates[`sites.${siteId}.role`] = this.form.isAdmin ? 'admin' : 'user';
        } else if (this.org) {
          const orgId = this.organisationRef.id;
          updates[`memberships.${orgId}.role`] = this.form.isAdmin ? 'admin' : 'user';
        }
      }
      try {
        await this.updateUser({ref: this.user.ref, updates});
        this.open = false;
      } catch (e) {
        this.$logger.error('save', e);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>

</style>
