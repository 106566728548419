import {Logger} from '@vanti/vue-logger';
import {pagedListStore} from '@/util/vuex-firestore-paged-list';
import {collection, deleteDoc, doc, getDocs, query, setDoc, updateDoc, where} from 'firebase/firestore';
import {SiteLocation} from '@/site/location';
import {decorateSnapshot} from '@/util/vuex-firestore-util';
import {compareByPath} from '@/util/compare';
import Vue from 'vue';

const log = Logger.get(`site/store/locations`);
const transform = snap => new SiteLocation(decorateSnapshot(snap));
const compareByTitle = (a, b) => compareByPath(a, b, 'title');
const pagedList = pagedListStore({perPage: false, transform, sort: compareByTitle}, log);

export default {
  namespaced: true,
  state: {
    ...pagedList.state
  },
  getters: {
    ...pagedList.getters,
    whereConstraints() {
      return [where('_hasParent', '==', false)];
    }
  },
  mutations: {
    ...pagedList.mutations,
    replaceRecordAtIndex(state, {record, index}) {
      const decorated = transform(record);
      // copy any loaded children
      decorated.children = state.records[index].children;
      Vue.set(state.records, index, decorated);
    },
    removeRecordByRef(state, ref) {
      // remove from records
      pagedList.mutations.removeRecordByRef(state, ref);
      // also check children
      for (const record of state.records) {
        const index = record.children.findIndex(c => ref.id === c.id);
        if (index !== -1) {
          record.children.splice(index, 1);
          break;
        }
      }
    }
  },
  actions: {
    ...pagedList.actions,
    async collection({rootGetters}) {
      const siteRef = rootGetters['user/siteRef'];
      return collection(siteRef, 'locations');
    },

    async loadChildren({dispatch}, location) {
      log.debug('loadChildren', {location, ref: location.ref.path});
      const collection = await dispatch('collection');
      const constraints = [
        where('parentLocation.ref', '==', location.ref)
      ];
      const q = query(collection, ...constraints);
      const snap = await getDocs(q);
      return snap.docs
          .map(d => new SiteLocation(decorateSnapshot(d)))
          .sort(compareByTitle);
    },

    async searchKeyword({state, rootGetters, dispatch}, keyWord) {
      const col = await dispatch('collection');
      log.debug(`searching for '${keyWord}' in ${col.path}`);
      const q = query(col, where('_keywords', 'array-contains', keyWord.toLowerCase()));
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs;
    },

    /**
     * @param {*} context
     * @param {dials.firestore.Location} location
     * @return {Promise<void>}
     */
    async addLocation({rootGetters}, location) {
      log.debug('addLocation', location);
      const siteRef = rootGetters['user/siteRef'];
      const locations = collection(siteRef, 'locations');
      const ref = doc(locations);
      await setDoc(ref, location);
    },
    /**
     * @param {*} context
     * @param {dials.firestore.Location & DecoratedData} location
     * @return {Promise<void>}
     */
    async updateLocation({dispatch}, location) {
      log.debug('updateLocation', location);
      /** @type {Device} */
      await updateDoc(location.ref, location);

      if (location.parentLocation) {
        // if editing the parent location field, reload the store
        dispatch('refresh');
      } else {
        // see if this location is in the store, and update that copy
        dispatch('updateRecord', location.ref);
      }
    },
    /**
     * @param {*} context
     * @param {SiteLocation} location
     * @return {Promise<void>}
     */
    async deleteLocation({commit}, location) {
      log.debug('deleteLocation', location);
      await deleteDoc(location.ref);
      // see if this location is in the store, and remove that copy
      commit('removeRecordByRef', location.ref);
    }
  }
};
