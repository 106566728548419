<template>
  <v-container fluid>
    <v-toolbar flat>
      <v-toolbar-title>Locations for {{ siteName }}</v-toolbar-title>
      <v-spacer/>
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn icon color="secondary" class="mx-2" v-on="on" @click="doRefresh">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
        Refresh the list
      </v-tooltip>
      <v-tooltip bottom v-if="hasSiteAdminAccess">
        <template #activator="{ on }">
          <v-btn class="ml-2" fab depressed small color="secondary" v-on="on" @click="create()">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        Add a location to {{ siteName }}
      </v-tooltip>
    </v-toolbar>
    <v-treeview
        open-on-click
        :items="records"
        :load-children="loadLocationsChildren"
        item-text="title">
      <template #append=" { item }" v-if="hasSiteAdminAccess">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn class="ml-2" fab depressed small icon v-on="on" @click.stop="create(item)">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          Add a location to {{ item.title }}
        </v-tooltip>
        <v-btn class="ml-2" fab depressed small icon @click.stop="edit(item)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
    </v-treeview>
  </v-container>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';

export default {
  name: 'LocationTree',
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapState('site/locations', ['records']),
    ...mapGetters('user', ['siteRefPath', 'siteName']),
    ...mapGetters('user/selectedSite', ['hasSiteAdminAccess'])
  },
  watch: {
    siteRefPath: {
      immediate: true,
      handler(site) {
        if (site) {
          this.load();
        }
      }
    }
  },
  methods: {
    ...mapActions('site/locations', ['loadChildren']),
    setPerPage(p) {
      this.$store.commit(`site/products/setPerPage`, p);
    },
    async load() {
      this.loading = true;
      try {
        this.$logger.debug('loading next page');
        await this.$store.dispatch(`site/locations/nextPage`);
      } catch (e) {
        this.$logger.error('next page', e);
      }
      this.loading = false;
    },
    async doRefresh() {
      this.loading = true;
      try {
        this.$logger.debug('refresh');
        await this.$store.dispatch(`site/locations/refresh`);
      } catch (e) {
        this.$logger.error('refresh', e);
      }
      this.loading = false;
    },
    create(parent) {
      this.$root.$emit('newLocation', {parent});
    },
    edit(location) {
      this.$root.$emit('editLocation', location);
    },
    async loadLocationsChildren(location) {
      const children = await this.loadChildren(location);
      this.$logger.debug('loadLocationsChildren', children.length);
      location.children = children;
    }
  }
};
</script>

<style scoped>

</style>
