<template>
  <v-form @submit="onChange" ref="deviceForm">
    <v-container>
      <slot name="prepend"/>
      <v-row>
        <v-text-field
            v-model="productManufacturer"
            label="Manufacturer"
            class="mr-4"
            :error-messages="manufacturerErrors"/>
        <v-text-field
            v-model="productModel"
            label="Model"
            :error-messages="modelErrors"/>
      </v-row>
      <v-row>
        <v-autocomplete
            auto-select-first
            no-filter
            hide-no-data
            v-model="deviceType"
            :loading="isLoading"
            @update:search-input="searchInput"
            :items="types"
            item-text="title"
            :item-value="productKind"
            label="Device Type"
            required
            :error-messages="typeErrors">
          <template #item="{item}">
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
              <v-list-item-subtitle v-if="item.hasOwnProperty('group')">{{ item.group }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action><strong>{{ item.code }}</strong></v-list-item-action>
          </template>
        </v-autocomplete>
      </v-row>
      <v-row>
        <v-col class="mr-2">
          <v-row>
            <v-text-field
                v-model="productHardwareVersion"
                label="Hardware Version"/>
          </v-row>
          <v-row>
            <v-text-field
                v-model="productSoftwareVersion"
                label="Software Version"/>
          </v-row>
        </v-col>
        <v-col class="ml-2">
          <v-row>
            <v-text-field
                v-model="productFirmwareVersion"
                label="Firmware Version"/>
          </v-row>
        </v-col>
      </v-row>
      <slot name="append"/>
    </v-container>
  </v-form>
</template>

<script>
import {validationMixin} from 'vuelidate';
import {required} from 'vuelidate/lib/validators';
import {mapActions} from 'vuex';
import {debounce} from 'lodash';

export default {
  name: 'ProductForm',
  mixins: [validationMixin],
  props: {
    product: {
      type: Object,
      default: () => {
        return {type: {}, location: {}};
      }
    },
    addNew: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dateMenu: false,
      saveData: {},
      isLoading: false,
      types: []
    };
  },
  validations: {
    saveData: {
      manufacturer: {
        required
      },
      model: {
        required
      },
      kind: {
        code: {
          required
        },
        title: {
          required
        }
      }
    }
  },
  computed: {
    productManufacturer: {
      get() {
        return this.product.manufacturer;
      },
      set(value) {
        this.$set(this.saveData, 'manufacturer', value);
        this.$v.saveData.manufacturer.$touch();
        this.onChange();
      }
    },
    productModel: {
      get() {
        return this.product.model;
      },
      set(value) {
        this.$set(this.saveData, 'model', value);
        this.$v.saveData.model.$touch();
        this.onChange();
      }
    },
    productHardwareVersion: {
      get() {
        return this.product.hardwareVersion;
      },
      set(value) {
        this.$set(this.saveData, 'hardwareVersion', value);
        this.onChange();
      }
    },
    productSoftwareVersion: {
      get() {
        return this.product.softwareVersion;
      },
      set(value) {
        this.$set(this.saveData, 'softwareVersion', value);
        this.onChange();
      }
    },
    productFirmwareVersion: {
      get() {
        return this.product.firmwareVersion;
      },
      set(value) {
        this.$set(this.saveData, 'firmwareVersion', value);
        this.onChange();
      }
    },
    deviceType: {
      get() {
        if (this.saveData.hasOwnProperty('kind')) {
          return this.saveData.kind;
        }
        return {
          title: this.product.type,
          code: this.product.typeCode
        };
      },
      set(value) {
        this.$set(this.saveData, 'kind', value);
        this.$v.saveData.kind.$touch();
        this.onChange();
      }
    },
    manufacturerErrors() {
      const errors = [];
      if (!this.$v.saveData.manufacturer.$dirty) return errors;
      if (!this.$v.saveData.manufacturer.required) errors.push('Manufacturer is required.');
      return errors;
    },
    modelErrors() {
      const errors = [];
      if (!this.$v.saveData.model.$dirty) return errors;
      if (!this.$v.saveData.model.required) errors.push('Model is required.');
      return errors;
    },
    typeErrors() {
      const errors = [];
      if (!this.$v.saveData.kind.$dirty) return errors;
      if (!this.$v.saveData.kind.code.required) errors.push('Type is required.');
      return errors;
    },
    canSubmit() {
      // can submit if:
      // - if new product, must be valid
      // - there is some data to save; and
      // - if the manufacturer or model has changed, this must be valid
      if (this.addNew) {
        return !this.$v.$invalid;
      }
      return Object.values(this.saveData).length > 0 &&
          !(this.saveData.hasOwnProperty('manufacturer') && this.$v.saveData.manufacturer.$invalid) &&
          !(this.saveData.hasOwnProperty('model') && this.$v.saveData.model.$invalid);
    }
  },
  watch: {
    canSubmit() {
      this.$emit('update:canSubmit', this.canSubmit);
    }
  },
  mounted() {
    if (!this.addNew) {
      this.types.push(this.deviceType);
    }
  },
  methods: {
    ...mapActions('site/schema', ['searchKeyword']),
    searchInput(val) {
      if (!val || val.length < 2) {
        return;
      }
      this.debounceDoSearch(val);
    },
    debounceDoSearch: debounce(function(val) {
      // eslint-disable-next-line no-invalid-this
      this.doSearch(val);
    }, 200),
    async doSearch(val) {
      // Items have already been requested
      if (this.isLoading) return;
      this.isLoading = true;
      try {
        const results = await this.searchKeyword({property: 'device.type', key: val});
        const docs = results.map(doc => doc.data());
        if (this.deviceType) {
          docs.push(this.deviceType);
        }
        this.types = docs;
        this.$logger.debug('search', this.types);
      } catch (e) {
        this.$logger.error('search', e);
      } finally {
        this.isLoading = false;
      }
    },
    productKind(item) {
      return {
        title: item.title,
        code: item.code
      };
    },
    onChange() {
      if (!this.canSubmit) {
        return;
      }
      if (!this.addNew) {
        Object.defineProperty(this.saveData, 'firestoreRef', {value: this.product.firestoreRef});
      }
      this.$emit('input', this.saveData);
    }
  }
};
</script>

<style scoped>

</style>
