const emptyChildren = [];

export class SiteLocation {
  /** @type {DecoratedData<dials.firestore.Location|firebase.firestore.DocumentData>} */
  #data;
  /**
   * @param {DecoratedData<dials.firestore.Location|firebase.firestore.DocumentData>} data
   */
  constructor(data) {
    this.#data = data;
    /** @type {SiteLocation[]} */
    this.children = emptyChildren;

    Object.defineProperty(this, 'title', {
      enumerable: true,
      get() {
        return data.title;
      }
    });
  }

  /**
   * @return {null|firebase.firestore.DocumentReference<T>}
   */
  get parentLocationRef() {
    return this.parentLocationSnippet && this.parentLocationSnippet.ref;
  }

  /**
   *
   * @return {dials.firestore.DocumentSnippet<dials.firestore.Location>|null}
   */
  get parentLocationSnippet() {
    return this.#data.parentLocation || null;
  }

  get ref() {
    return this.#data.ref;
  }

  get id() {
    return this.#data.ref.id;
  }
}
