<template>
  <v-menu offset-y :close-on-content-click="false" v-model="open" :content-class="isMobile ? 'is-mobile' : ''">
    <template #activator="{on: onMenu}">
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn icon color="secondary" class="ml-2" v-on="{...on, ...onMenu}">
            <v-icon>mdi-filter-variant</v-icon>
          </v-btn>
        </template>
        More filters
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title>All Device Filters</v-card-title>
      <v-card-text class="filters-container">
        <v-text-field label="Search" v-model="search" clearable hide-details class="search"/>
        <v-list dense>
          <template v-for="f in filterOptions">
            <v-list-item
                :key="f.text"
                class="pa-0"
                ripple
                @click="setFilter({prop: f.value[0], value: f.value[2], remove: hasFilter(f.value)})">
              <v-list-item-title>{{ f.text }}</v-list-item-title>
              <v-list-item-action class="ma-0 pa-0">
                <v-checkbox
                    :input-value="hasFilter(f.value)"
                    dense
                    class="pa-0 ma-0"/>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>
        <div/>
        <template v-for="f in fieldFilters">
          <v-autocomplete
              no-filter
              v-model="fields[f.prop]"
              :key="f.label"
              :items="f.items(f.prop, f.field)"
              :label="f.label"
              @change="setFilter({prop: f.prop, value: $event})"
              @update:search-input="f.onSearch"
              :item-text="f.field"
              :item-value="f.field"
              hide-details
              clearable/>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="clear">Clear All</v-btn>
        <v-spacer/>
        <v-btn text @click="open = false">Done</v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import {mapActions} from 'vuex';
import {deviceFilterOptions} from '@/site/components/device-filters';
import {debounce} from 'lodash';
import {decorateSnapshot} from '@/util/vuex-firestore-util';

export default {
  name: 'DeviceFilters',
  props: {
    storePath: {
      type: String,
      default: 'site/devices'
    },
    tooltip: {
      type: String,
      default: 'More filters'
    },
    floorView: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      filterOptions: deviceFilterOptions,
      open: false,
      location: {
        items: [],
        loading: false
      },
      product: {
        items: [],
        loading: false
      },
      subsystem: {
        items: [],
        loading: false
      },
      fields: {}
    };
  },
  computed: {
    filterValues() {
      return this.$store.getters[this.storePath + '/filterValues'];
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    search: {
      get() {
        const filter = this.filterValues.find(v => v[0] === '_keywords');
        return filter && filter[2];
      },
      set(v) {
        this.setFilter({value: v, prop: '_keywords', op: 'array-contains'});
      }
    },
    fieldFilters() {
      return [
        {
          label: 'Manufacturer',
          items: this.itemsWithFallback(this.product.items),
          onSearch: this.productSearch,
          field: 'manufacturer',
          prop: 'product.manufacturer'
        },
        {
          label: 'Model',
          items: this.itemsWithFallback(this.product.items),
          onSearch: this.productSearch,
          field: 'model',
          prop: 'product.model'
        },
        {
          label: 'Location',
          items: this.itemsWithFallback(this.location.items),
          onSearch: this.locationSearch,
          field: 'title',
          prop: 'location.title'
        },
        {
          label: 'Parent Location',
          items: this.itemsWithFallback(this.location.items),
          onSearch: this.locationSearch,
          field: 'title',
          prop: 'location.parentLocation.title'
        },
        {
          label: 'Location (architectural)',
          items: this.itemsWithFallback(this.location.items),
          onSearch: this.locationSearch,
          field: 'architecturalReference',
          prop: 'location.architecturalReference'
        },
        {
          label: 'Parent Location (architectural)',
          items: this.itemsWithFallback(this.location.items),
          onSearch: this.locationSearch,
          field: 'architecturalReference',
          prop: 'location.parentLocation.architecturalReference'
        },
        {
          label: 'System',
          items: this.itemsWithFallback(this.subsystem.items),
          onSearch: this.subsystemSearch,
          field: 'title',
          prop: 'subsystem.title'
        }
      ];
    }
  },
  mounted() {
    for (const fv of this.filterValues) {
      this.fields[fv[0]] = fv[2];
    }
  },
  methods: {
    ...mapActions('site/locations', {
      searchLocationByKeyword: 'searchKeyword'
    }),
    ...mapActions('site/products', {
      searchProductByKeyword: 'searchKeyword'
    }),
    ...mapActions('site/subsystems', {
      searchSubsystemsByKeyword: 'searchKeyword'
    }),
    setFilter({prop, value, op = '==', remove = false}) {
      const filters = this.filterValues.filter(v => v[0] !== prop);
      if (value !== undefined && value !== null && !remove) {
        filters.push([prop, op, value]);
      }
      this.setFilters(filters);
    },
    setFilters(filters) {
      this.$store.commit(this.storePath + '/setFilters', filters);
    },
    hasFilter(value) {
      return this.filterValues.some(f => f.every((v, i) => value[i] === v));
    },
    clear() {
      let filters = [];
      if (this.floorView) {
        filters = this.filterValues.filter(v => v[0] === '_location_ancestors');
      }
      this.setFilters(filters);
      this.fields = {};
    },
    locationSearch(val) {
      if (!val || val.length < 2) {
        return;
      }
      this.debounceDoLocationSearch(val);
    },
    debounceDoLocationSearch: debounce(function(val) {
      // eslint-disable-next-line no-invalid-this
      this.doLocationSearch(val);
    }, 200),
    async doLocationSearch(val) {
      // Items have already been requested
      if (this.location.loading) return;
      this.location.loading = true;
      try {
        const results = await this.searchLocationByKeyword(val);
        const locations = results.map(decorateSnapshot);
        if (this.locationSnippet) {
          locations.push(this.locationSnippet);
        }
        this.location.items = locations;
        this.$logger.debug('search', this.location.items);
      } catch (e) {
        this.$logger.error('search', e);
      } finally {
        this.location.loading = false;
      }
    },
    productSearch(val) {
      if (!val || val.length < 2) {
        return;
      }
      this.debounceDoProductSearch(val);
    },
    debounceDoProductSearch: debounce(function(val) {
      // eslint-disable-next-line no-invalid-this
      this.doProductSearch(val);
    }, 200),
    async doProductSearch(val) {
      // Items have already been requested
      if (this.product.loading) return;
      this.product.loading = true;
      try {
        const results = await this.searchProductByKeyword(val);
        const products = results.map(decorateSnapshot);
        if (this.productSnippet) {
          products.push(this.productSnippet);
        }
        this.product.items = products;
        this.$logger.debug('search', this.product.items);
      } catch (e) {
        this.$logger.error('search', e);
      } finally {
        this.product.loading = false;
      }
    },
    subsystemSearch(val) {
      if (!val || val.length < 2) {
        return;
      }
      this.debounceDoSubsystemSearch(val);
    },
    debounceDoSubsystemSearch: debounce(function(val) {
      // eslint-disable-next-line no-invalid-this
      this.doSubsystemSearch(val);
    }, 200),
    async doSubsystemSearch(val) {
      // Items have already been requested
      if (this.subsystem.loading) return;
      this.subsystem.loading = true;
      try {
        const results = await this.searchSubsystemsByKeyword(val);
        const subsystems = results.map(decorateSnapshot);
        if (this.subsystemSnippet) {
          subsystems.push(this.subsystemSnippet);
        }
        this.subsystem.items = subsystems;
        this.$logger.debug('search', this.subsystem.items);
      } catch (e) {
        this.$logger.error('search', e);
      } finally {
        this.subsystem.loading = false;
      }
    },
    itemsWithFallback(items) {
      return (prop, field) => {
        if (items.length === 0 && this.fields.hasOwnProperty(prop)) {
          return [{
            [field]: this.fields[prop]
          }];
        }
        return items;
      };
    }
  }
};
</script>

<style scoped>
.v-list-item {
  cursor: pointer;
  min-height: unset;
}
.filters-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 8px;
}
.search {
  grid-column: 1 / 3;
}
.is-mobile {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  height: 100vh;
  width: 100vw;
  max-width: 100vw;
}
.is-mobile .v-card {
  height: 100%;
  width: 100%;
}
</style>
