import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_vm._v("Add a New Contractor to "+_vm._s(_vm.site.title))]),_c(VCardText,[_c(VContainer,[_c(VRow,[_c(VTextField,{attrs:{"label":"Company Name"},model:{value:(_vm.form.organisation.title),callback:function ($$v) {_vm.$set(_vm.form.organisation, "title", $$v)},expression:"form.organisation.title"}})],1),_c(VRow,[_c('h3',[_vm._v("First User Details for "+_vm._s(_vm.form.organisation.title || 'New Contractor'))])]),_c(VRow,[_c(VTextField,{attrs:{"label":"Email","value":_vm.form.email,"loading":_vm.existingUserSearch,"error-messages":_vm.emailErrors},on:{"change":_vm.setEmail}})],1),_c(VRow,[_c(VTextField,{attrs:{"label":"Name","disabled":_vm.existingUser !== null},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1),_c(VRow,[_c(VTextField,{attrs:{"label":"Job Role","disabled":_vm.existingUser !== null},model:{value:(_vm.jobRole),callback:function ($$v) {_vm.jobRole=$$v},expression:"jobRole"}})],1),_c(VRow,[_c(VCheckbox,{attrs:{"label":("Admin Access to " + (_vm.form.organisation.title)),"input-value":_vm.form.user.admin,"readonly":""}})],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"disabled":_vm.loading,"text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Cancel ")]),_c(VBtn,{attrs:{"color":"secondary","loading":_vm.loading,"disabled":!_vm.valid},on:{"click":_vm.add}},[_vm._v(" Add ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }