import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VToolbar,{attrs:{"flat":""}},[_c(VToolbarTitle,[_vm._v(_vm._s(_vm.title))]),_c(VSpacer),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"mx-2",attrs:{"icon":"","color":"secondary"},on:{"click":_vm.doRefresh}},on),[_c(VIcon,[_vm._v("mdi-refresh")])],1)]}}])},[_vm._v(" Refresh the list ")]),_c('user-add',{attrs:{"group-title":_vm.groupTitle,"site":_vm.isSiteUsers,"org":!_vm.isSiteUsers}})],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.usersPage,"disable-sort":"","items-per-page":_vm.perPage,"options":_vm.options,"server-items-length":_vm.asUsers.length,"footer-props":{'items-per-page-options': [25, 50, 100, 200]},"loading":_vm.loading},on:{"update:items-per-page":_vm.setPerPage,"update:options":function($event){_vm.options=$event},"pagination":_vm.onPaginationUpdate},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [(item.title)?_c('span',[_vm._v(_vm._s(item.title))]):_c('span',[_vm._v(_vm._s(item.email))])]}},{key:"item.isAdmin",fn:function(ref){
var item = ref.item;
return [_c(VSimpleCheckbox,{attrs:{"ripple":false,"value":item.isAdmin,"disabled":""}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('user-edit',{attrs:{"user":item,"group-title":_vm.groupTitle,"site":_vm.isSiteUsers,"org":!_vm.isSiteUsers}}),_c('user-delete',{attrs:{"user":item,"group-title":_vm.groupTitle,"site":_vm.isSiteUsers,"org":!_vm.isSiteUsers}})]}},{key:"footer.page-text",fn:function(ref){
var pageStart = ref.pageStart;
var pageStop = ref.pageStop;
return [_vm._v(" "+_vm._s(pageStart)+" - "+_vm._s(pageStop)+" of "+_vm._s(_vm.itemCount)+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }