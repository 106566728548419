<template>
  <div>
    <v-dialog v-model="devices.form" persistent max-width="600px" :fullscreen="isMobile">
      <edit-device-form
          :device="devices.device"
          v-if="devices.form"
          @close="devices.form=false"
          @create="onCreateDevice"
          @update="onUpdateDevice"
          @delete="onDeleteDevice"/>
    </v-dialog>
    <v-dialog v-model="devices.bulk.form" persistent max-width="600px" :fullscreen="isMobile">
      <edit-device-form-bulk
          :devices="devices.bulk.devices"
          v-if="devices.bulk.form"
          @close="devices.bulk.form=false"
          @update="onUpdateDeviceBulk"
          @delete="onDeleteDeviceBulk"/>
    </v-dialog>
    <v-dialog v-model="print.dialog" max-width="480px" :fullscreen="isMobile">
      <label-printer
          v-if="print.dialog"
          :devices="print.devices"
          :label-sizes="labelSizes"
          @close="print.dialog=false"/>
    </v-dialog>
    <v-dialog v-model="locations.form" max-width="600" :fullscreen="isMobile">
      <location-form
          v-if="locations.form"
          :add-new="locations.new"
          :location="locations.location"
          :parent="locations.parent"
          @close="locations.form = false"
          @create="onCreateLocation"
          @update="onUpdateLocation"
          @delete="onDeleteLocation"/>
    </v-dialog>
    <v-dialog v-model="subsystem.form" max-width="600" :fullscreen="isMobile">
      <subsystem-form
          v-if="subsystem.form"
          :add-new="subsystem.new"
          :subsystem="subsystem.subsystem"
          @close="subsystem.form = false"
          @create="onCreateSubsystem"
          @update="onUpdateSubsystem"
          @delete="onDeleteSubsystem"/>
    </v-dialog>
  </div>
</template>

<script>
import {Device} from '@/site/Device';
import EditDeviceForm from '@/site/components/edit/EditDeviceForm';
import LabelPrinter from '@/site/components/LabelPrinter';
import {mapActions, mapGetters} from 'vuex';
import cloneDeep from 'lodash.clonedeep';
import {Product} from '@/site/Product.js';
import LocationForm from '@/site/components/edit/LocationForm';
import SubsystemForm from '@/site/components/edit/SubsystemForm';
import EditDeviceFormBulk from '@/site/components/edit/EditDeviceFormBulk';

export default {
  name: 'SiteDialogs',
  components: {EditDeviceFormBulk, SubsystemForm, LocationForm, LabelPrinter, EditDeviceForm},
  data() {
    return {
      hasRegistered: false,
      devices: {
        form: false,
        device: new Device(),
        bulk: {
          form: false,
          devices: []
        }
      },
      locations: {
        form: false,
        new: false,
        location: {},
        parent: null
      },
      subsystem: {
        form: false,
        new: false,
        subsystem: {}
      },
      print: {
        dialog: false,
        devices: []
      }
    };
  },
  computed: {
    ...mapGetters('user/selectedSite/config', ['labelSizes']),
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    }
  },
  mounted() {
    // hot-reload can cause multiple calls
    if (this.hasRegistered) return;
    this.hasRegistered = true;
    // add global event listeners
    this.$root.$on('newDevice', this.newDevice);
    this.$root.$on('editDevice', this.editDevice);
    this.$root.$on('bulkEditDevice', this.bulkEditDevice);

    this.$root.$on('newLocation', this.newLocation);
    this.$root.$on('editLocation', this.editLocation);

    this.$root.$on('newSubsystem', this.newSubsystem);
    this.$root.$on('editSubsystem', this.editSubsystem);

    this.$root.$on('printLabels', this.printLabels);

    this.$root.$on('searchByUuid', this.searchByUuid);
  },
  methods: {
    ...mapActions('site/devices', [
      'getDevice',
      'addDevice',
      'updateDevice',
      'deleteDevice',
      'updateDevices',
      'deleteDevices'
    ]),
    ...mapActions('site/products', ['createProduct']),
    ...mapActions('site/locations', ['addLocation', 'updateLocation', 'deleteLocation']),
    ...mapActions('site/subsystems', ['addSubsystem', 'updateSubsystem', 'deleteSubsystem']),
    printLabels(devices) {
      this.print.devices = devices;
      this.print.dialog = true;
    },
    async searchByUuid(uuid) {
      const device = await this.getDevice(uuid);
      if (device) {
        this.$logger.debug('searchByUuid', {uuid, device});
        await this.$router.push({
          name: 'site-device',
          params: {id: uuid}
        });
      }
    },

    newDevice() {
      this.devices.device = cloneDeep(this.blankDevice);
      this.devices.form = true;
    },
    editDevice(device) {
      this.devices.device = device;
      this.devices.form = true;
    },
    bulkEditDevice(devices) {
      this.devices.bulk.devices = devices;
      this.devices.bulk.form = true;
    },
    async onCreateDevice({device, newProduct}) {
      if (newProduct) {
        const p = new Product(newProduct);
        const productRef = await this.createProduct(p);
        device.product = newProduct;
        device.product.ref = productRef;
      }
      await this.addDevice(device);
    },
    async onUpdateDevice({device, newProduct}) {
      if (newProduct) {
        const p = new Product(newProduct);
        const productRef = await this.createProduct(p);
        device.product = newProduct;
        device.product.ref = productRef;
      }
      await this.updateDevice(device);
    },
    onDeleteDevice(device) {
      this.deleteDevice(device);
    },
    async onUpdateDeviceBulk({updates, devices, newProduct}) {
      if (newProduct) {
        const p = new Product(newProduct);
        const productRef = await this.createProduct(p);
        updates.product = newProduct;
        updates.product.ref = productRef;
      }
      await this.updateDevices(devices.map(d => {
        const toApply = {...updates};
        Object.defineProperty(toApply, 'firestoreRef', {
          get() {
            return d.firestoreRef;
          }
        });
        return toApply;
      }));
    },
    onDeleteDeviceBulk(devices) {
      this.deleteDevices(devices);
    },

    newLocation({parent}) {
      this.locations.parent = parent;
      this.locations.location = {};
      this.locations.new = true;
      this.locations.form = true;
    },
    editLocation(location) {
      this.locations.location = location;
      this.locations.parent = null;
      this.locations.new = false;
      this.locations.form = true;
    },
    async onCreateLocation(location) {
      await this.addLocation(location);
    },
    async onUpdateLocation(location) {
      await this.updateLocation(location);
    },
    async onDeleteLocation(location) {
      await this.deleteLocation(location);
    },

    newSubsystem() {
      this.subsystem.new = true;
      this.subsystem.subsystem = {};
      this.subsystem.form = true;
    },
    editSubsystem(subsystem) {
      this.subsystem.subsystem = subsystem;
      this.subsystem.new = false;
      this.subsystem.form = true;
    },
    async onCreateSubsystem(subsystem) {
      await this.addSubsystem(subsystem);
    },
    async onUpdateSubsystem(subsystem) {
      await this.updateSubsystem(subsystem);
    },
    async onDeleteSubsystem(subsystem) {
      await this.deleteSubsystem(subsystem);
    }
  }
};
</script>

<style scoped>

</style>
