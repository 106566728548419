/**
 * @typedef {string | Date | number | {toDate: function(): Date}} CanBeDate
 */

/**
 * Finds the first possible date out of the given values
 *
 * @param {...(CanBeDate|function():CanBeDate)} dates
 * @return {Date}
 */
export function toDate(...dates) {
  for (let date of dates) {
    if (typeof date === 'function') {
      date = date();
    }
    if (date instanceof Date) {
      return date;
    }
    if (date === null || typeof date === 'undefined') {
      continue;
    }
    if (typeof date.toDate === 'function') return date.toDate();

    try {
      return new Date(date);
    } catch (e) {
      log.warn('Failed to create date from', date);
    }
  }

  return null;
}


/**
 * Return the date string YYYY-MM-DD, in the local timezone of the date
 *
 * @param {CanBeDate} date
 * @return {string|null}
 */
export function toYearMonthDay(date) {
  if (!date) return null;
  const d = toDate(date);
  const day = d.getDate().toString().padStart(2, '0');
  const month = (d.getMonth() + 1).toString().padStart(2, '0');
  const year = d.getFullYear();
  return [year, month, day].join('-');
}

/**
 * Create a date from the given YYYY-MM-DD string.
 *
 * Note: calling `new Date('YYYY-MM-DD')` will (or may) be interpreted as midnight UTC on that date.
 * Instead, call `new Date(year, month, day)` to get it at midnight in the local timezone.
 *
 * @param {string} ymd - YYYY-MM-DD
 * @return {Date|null}
 */
export function fromYearMonthDay(ymd) {
  if (!ymd) return null;
  let [year, month, day] = ymd.split('-');
  year = parseInt(year);
  month = parseInt(month) - 1;
  day = parseInt(day);
  return new Date(year, month, day);
}
