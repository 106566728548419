<template>
  <v-container fluid>
    <template v-if="userOrganisations.length > 1">
      <v-row>
        <v-col>
          <h3>Who are you working for today?</h3>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col v-for="(c, i) in userOrganisations" :key="i" cols="12" sm="6" md="4" lg="3">
          <v-card flat outlined :class="{selected: isSelectedOrganisation(c)}">
            <v-card-title>{{ c.organisation.title }}</v-card-title>
            <v-card-actions>
              <template v-if="isPreferredOrganisation(c)">
                <v-btn text disabled>Default</v-btn>
              </template>
              <v-btn v-else text @click="setPreferredOrganisation(c)">
                Set Default
              </v-btn>
              <v-spacer/>
              <v-btn
                  depressed
                  :disabled="isSelectedOrganisation(c)"
                  color="secondary"
                  @click="chooseOrganisation(c)">
                Select
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </template>

    <template v-if="organisation">
      <v-row>
        <v-col>
          <h3>Select a Site</h3>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col v-for="(s, i) in organisationAllSites" :key="i" cols="12" sm="6" md="4" lg="3">
          <v-card flat outlined :class="{selected: isSelectedSite(s)}">
            <v-card-title>{{ s.site.title }}</v-card-title>
            <v-card-actions>
              <template v-if="isPreferredSite(s)">
                <v-btn text disabled>Default</v-btn>
              </template>
              <v-btn v-else-if="preferredOrganisationSelected" text @click="setPreferredSite(s)">
                Set Default
              </v-btn>
              <v-spacer/>
              <v-btn
                  depressed
                  :disabled="isSelectedSite(s)"
                  color="secondary"
                  @click="choose(s)">
                Select
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col v-if="Object.keys(organisationAllSites).length === 0" cols="12" sm="6" md="4" lg="3">
          <v-card flat outlined><v-card-title class="grey--text">No sites</v-card-title></v-card>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import {refEqual} from 'firebase/firestore';

export default {
  name: 'Home',
  computed: {
    ...mapGetters('user', [
      'userSites',
      'siteRef',
      'userOrganisations',
      'organisationRef',
      'userPreferredOrganisation',
      'userPreferredSite'
    ]),
    ...mapGetters('user/selectedOrganisation', ['organisationAllSites']),
    ...mapState('user', ['site', 'organisation']),
    redirect() {
      return this.$route.params.redirect;
    },
    preferredOrganisationSelected() {
      return refEqual(this.organisationRef, this.userPreferredOrganisation);
    }
  },
  async mounted() {
    const redirect = this.redirect;
    this.$logger.debug('redirect', this.redirect);
    if (redirect && redirect.params && redirect.params.siteId) {
      const siteId = redirect.params.siteId;
      await this.$store.dispatch('user/chooseSiteById', siteId);
      if (this.organisation) {
        await this.$router.push(redirect);
      }
    }
  },
  methods: {
    async choose(site) {
      await this.$store.dispatch('user/chooseSite', site.site.ref);
      if (this.organisation) {
        await this.doRedirect();
      }
    },
    async chooseOrganisation(membership) {
      await this.$store.dispatch('user/chooseOrganisation', membership.organisation.ref);
      if (this.site) {
        await this.doRedirect();
      }
    },
    async doRedirect() {
      const to = this.redirect ? this.redirect : {name: 'site-devices', params: {siteId: this.site.id}};
      await this.$router.push(to);
    },
    async setPreferredOrganisation(membership) {
      await this.$store.dispatch('user/updateUserPreferredOrganisation', membership.organisation.ref);
    },
    async setPreferredSite(site) {
      await this.$store.dispatch('user/updateUserPreferredSite', site.site.ref);
    },
    isPreferredOrganisation(membership) {
      return refEqual(membership.organisation.ref, this.userPreferredOrganisation);
    },
    isPreferredSite(site) {
      return refEqual(site.site.ref, this.userPreferredSite);
    },
    isSelectedOrganisation(membership) {
      return refEqual(membership.organisation.ref, this.organisationRef);
    },
    isSelectedSite(site) {
      return refEqual(site.site.ref, this.siteRef);
    }
  }
};
</script>

<style scoped>
.selected {
  border: 2px solid var(--v-primary-base);
}
</style>
