import {toDate, toYearMonthDay} from '@/util/dates';
import {NIL, uuidToBase64} from '@/util/uuid.mjs';
import {capitalize, get} from 'lodash';

/**
 * @augments dials.firestore.Device
 */
export class Device {
  #data;
  #ref;

  /*
   * The following properties/fields array can be used for props that don't need any translation/computation when
   * getting/setting. Basic getters & setters will be created for these on init
   */
  static basicFields = ['title', 'serialNumber', 'nics', 'planLocation', '_labelType', 'aliases'];

  static productFields = [
    'manufacturer',
    'model'
  ];

  static productOverrideFields = [
    'hardwareVersion',
    'softwareVersion',
    'firmwareVersion'
  ];

  constructor(data) {
    this.#data = data || {};

    // generate standard getters/setters for basic properties
    Device.basicFields.forEach(prop => {
      Object.defineProperty(this, prop, {
        get: () => {
          return this.#data[prop] || '';
        },
        set: (value) => {
          this.#data[prop] = value;
        }
      });
    });

    // generate standard getters/setters for product properties
    Device.productFields.forEach(prop => {
      Object.defineProperty(this, `product${capitalize(prop)}`, {
        get: () => {
          const product = this.productSnippet;
          return this.#data[prop] || product && product[prop] || '';
        }
      });
    });

    // generate standard getters/setters for product properties which can be overridden
    Device.productOverrideFields.forEach(prop => {
      Object.defineProperty(this, prop, {
        get: () => {
          const product = this.productSnippet;
          return this.#data[prop] || product && product[prop] || '';
        },
        set: (value) => {
          this.#data[prop] = value;
        }
      });
    });
  }

  /**
   * Update this device based on the key:value pairs in the provided object
   *
   * @param {Object} data
   */
  update(data) {
    this.#data = Object.assign({}, this.#data, data);
  }

  /**
   * A reference to the firestore document for this device (if it exists)
   *
   * @return {firebase.firestore.DocumentReference}
   */
  get firestoreRef() {
    return this.#ref;
  }

  set firestoreRef(ref) {
    this.#ref = ref;
  }

  /**
   * [Read-only] the shorter version of the title, without the suffix (e.g. 'AHU-001')
   *
   * @return {string|string}
   */
  get shortTitle() {
    if (!this.title) return '';
    const regex = /[0-9A-Z]{0,4}-\d{0,5}/;
    const match = regex.exec(this.title);
    return match && match[0] || '';
  }

  /**
   * The title for the type of this device (e.g. 'Air Handling Unit'). If none set for the device, uses the product type
   *
   * @return {string}
   */
  get type() {
    return this.#data.kind && this.#data.kind.title ||
        this.productSnippet && this.productSnippet.kind && this.productSnippet.kind.title ||
        '';
  }

  /**
   * Sets the device type, in the form {title: '', code: ''}
   *
   * @param {Object} typeObj
   */
  set type(typeObj) {
    this.#data.kind = typeObj;
  }

  /**
   * Returns the shortcode for the type of this device (e.g. 'AHU')
   *
   * @return {string}
   */
  get typeCode() {
    return (this.#data.kind && this.#data.kind.code) ||
        this.productSnippet && this.productSnippet.kind && this.productSnippet.kind.code ||
        '';
  }

  /**
   * @return {null|firebase.firestore.DocumentReference<T>}
   */
  get locationRef() {
    return this.locationSnippet && this.locationSnippet.ref;
  }

  /**
   * [Read-only] the title of the location of this device (e.g '1.014')
   *
   * @return {string}
   */
  get location() {
    let title = this.locationTitle;
    if (title && this.locationArcRef && title !== this.locationArcRef) {
      title += ` (${this.locationArcRef})`;
    }
    return title || '';
  }

  /**
   * [Read-only] the title of the location of this device (e.g '1.014')
   *
   * @return {string}
   */
  get locationTitle() {
    return get(this.#data, 'location.title') || '';
  }

  get parentLocation() {
    let title = this.parentLocationTitle;
    if (title && this.parentLocationArcRef && title !== this.parentLocationArcRef) {
      title += ` (${this.parentLocationArcRef})`;
    }
    return title || '';
  }

  get parentLocationTitle() {
    return get(this.#data, 'location.parentLocation.title') || '';
  }
  get parentLocationId() {
    return get(this.#data, 'location.parentLocation.ref.id') || '';
  }

  /**
   * [Read-only] the architecturalReference of the location of this device (e.g '1.014')
   *
   * @return {string}
   */
  get locationArcRef() {
    return get(this.#data, 'location.architecturalReference') || '';
  }

  get parentLocationArcRef() {
    return get(this.#data, 'location.parentLocation.architecturalReference') || '';
  }

  /**
   *
   * @return {dials.firestore.DocumentSnippet<dials.firestore.Location>|null}
   */
  get locationSnippet() {
    return this.#data.location || null;
  }

  /**
   *
   * @param {dials.firestore.DocumentSnippet} location
   */
  set locationSnippet(location) {
    this.#data.location = location;
  }

  /**
   * @return {null|firebase.firestore.DocumentReference<T>}
   */
  get subsystemRef() {
    return this.subsystemSnippet && this.subsystemSnippet.ref;
  }

  /**
   * [Read-only] the title of the subsystem of this device (e.g '1.014')
   *
   * @return {string}
   */
  get subsystem() {
    return (this.#data.subsystem && this.#data.subsystem.title) || '';
  }

  /**
   *
   * @return {dials.firestore.DocumentSnippet<dials.firestore.Location>|null}
   */
  get subsystemSnippet() {
    return this.#data.subsystem || null;
  }

  /**
   *
   * @param {dials.firestore.DocumentSnippet} subsystem
   */
  set subsystemSnippet(subsystem) {
    this.#data.subsystem = subsystem;
  }

  /**
   * Returns the UUID of this device (e.g. '7330F811-F47F-41BC-A4FF-E792D073F41F')
   *
   * @return {*|string}
   */
  get uuid() {
    return this.#data.uuid || NIL;
  }
  set uuid(value) {
    this.#data.uuid = value;
  }

  /**
   * Returns the base64 encoded UUID of this device (e.g. 'Efgwc3/0vEGk/+eS0HP0Hw==');
   *
   * @return {string}
   */
  get compressedUuid() {
    return uuidToBase64(this.uuid);
  }

  /**
   * @return {null|firebase.firestore.DocumentReference<T>}
   */
  get productRef() {
    return this.productSnippet && this.productSnippet.ref;
  }

  /**
   *
   * @return {dials.firestore.DocumentSnippet<dials.firestore.Product>|null}
   */
  get productSnippet() {
    return this.#data.product || null;
  }
  /**
   *
   * @param {dials.firestore.DocumentSnippet<dials.firestore.Product>} product
   */
  set productSnippet(product) {
    this.#data.product = product;
  }

  /**
   * The manufacture date of this device
   *
   * @return {Date|null}
   */
  get manufactureDate() {
    return toDate(this.#data.manufactureDate);
  }

  set manufactureDate(value) {
    this.#data.manufactureDate = value;
  }

  get manufactureDateString() {
    return (this.manufactureDate && toYearMonthDay(this.manufactureDate)) || '';
  }

  /**
   * The install date of this device, or null if not yet installed
   *
   * @return {Date|null}
   */
  get installationDate() {
    return toDate(this.#data.installationDate);
  }

  set installationDate(value) {
    this.#data.installationDate = value;
  }

  get installationDateString() {
    return (this.installationDate && toYearMonthDay(this.installationDate)) || '';
  }

  /**
   * [Read-only] is this device installed?
   *
   * @return {boolean}
   */
  get isInstalled() {
    return this.installationDate !== null;
  }

  /**
   * The install date of this device, or null if not yet installed
   *
   * @return {Date|null}
   */
  get labelledDate() {
    return toDate(this.#data.labelledDate);
  }

  set labelledDate(value) {
    this.#data.labelledDate = value;
  }

  get labelledDateString() {
    return (this.labelledDate && toYearMonthDay(this.labelledDate)) || '';
  }

  /**
   * [Read-only] is this device installed?
   *
   * @return {boolean}
   */
  get isLabelled() {
    return this.labelledDate !== null;
  }

  /**
   * Is this device label-ready?
   *
   * @return {boolean}
   */
  get isLabelReady() {
    return this.#data._isLabelReady;
  }

  /**
   * Is this device not label-ready?
   *
   * @return {boolean}
   */
  get notLabelReady() {
    return !this.isLabelReady && this.notLabelReadySummary;
  }

  /**
   * Reasons for not being device ready
   *
   * @return {string}
   */
  get notLabelReadySummary() {
    if (this.isLabelReady) {
      return '';
    }
    const reasons = [];
    if (!this.title) {
      reasons.push('device name');
    }
    if (!this.locationRef) {
      reasons.push('location');
    }
    return reasons.join(', ');
  }

  /**
   *
   * @return {dials.firestore.DocumentSnippet<dials.firestore.Organisation>[]}
   */
  get organisations() {
    return this.#data.organisations;
  }
  set organisations(value) {
    this.#data.organisations = value;
  }
}

export const deviceConverter = {

  /**
   *
   * @param {Device} device
   * @return {dials.firestore.Device}
   */
  toFirestore: (device) => {
    /** @type {dials.firestore.Device} */
    const d = {};
    if (device.locationSnippet) d.location = device.locationSnippet;
    if (device.productSnippet) d.product = device.productSnippet;
    if (device.installationDate) d.installationDate = device.installationDate;
    if (device.organisations.length > 0) d.organisations = device.organisations;

    Device.basicFields.forEach(prop =>{
      if (device[prop] !== '') d[prop] = device[prop];
    });

    return d;
  },
  /**
   *
   * @param {QueryDocumentSnapshot} snapshot
   * @param {SnapshotOptions} options
   * @return {Device}
   */
  fromFirestore: (snapshot, options) => {
    /** @type {dials.firestore.Device} */
    const d = snapshot.data(options);
    d.uuid = snapshot.id;
    const dev = new Device(d);
    dev.firestoreRef = snapshot.ref;
    return dev;
  }
};
