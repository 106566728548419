import {collection, deleteDoc, doc, getDoc, setDoc, updateDoc, where, writeBatch} from 'firebase/firestore';
import {Device, deviceConverter} from '@/site/Device';
import Vue from 'vue';
import {db} from '@/firebase';
import {pagedListStore} from '@/util/vuex-firestore-paged-list';

/**
 * @param {PagedListOptions} opts
 * @param {Logger} log
 * @return {Object}
 */
export function devicesStore(opts, log) {
  const pagedList = pagedListStore(opts, log);
  return {
    namespaced: true,
    state: {
      ...pagedList.state,
      filters: {}
    },
    getters: {
      ...pagedList.getters,
      whereConstraints(state, getters, rootState, rootGetters) {
        const organisationRef = rootGetters['user/organisationRef'];
        const organisationIsSiteOwner = rootGetters['user/selectedOrganisation/organisationIsSiteOwner'];
        const constraints = [];
        if (!organisationIsSiteOwner) { // if not the site owner, only show devices the organisation can manage
          constraints.push(where(`_organisationIds.${organisationRef.id}`, '==', true));
        }
        for (const [field, condition, value] of getters.filterValues) {
          constraints.push(where(field, condition, value));
        }
        return constraints;
      },

      asDevices(state) {
        return state.records.map(r => {
          r.uuid = r.id;
          const d = new Device(r);
          d.firestoreRef = r.ref;
          return d;
        });
      },

      filterValues(state) {
        return Object.values(state.filters);
      }
    },
    mutations: {
      ...pagedList.mutations,
      setFilters(state, filters) {
        state.filters = {};
        for (const filter of filters) {
          const key = filter[0] + filter[1];
          Vue.set(state.filters, key, filter);
        }
      }
    },
    actions: {
      ...pagedList.actions,
      async collection({rootGetters}) {
        const siteRef = rootGetters['user/siteRef'];
        return collection(siteRef, 'devices');
      },

      /**
       *
       * @param {*} [rootGetters]
       * @param {string} uuid
       * @return {Promise<Device>}
       */
      async getDevice({rootGetters}, uuid) {
        log.debug('getDevice', uuid);
        const siteRef = rootGetters['user/siteRef'];
        const ref = doc(siteRef, 'devices', uuid).withConverter(deviceConverter);
        const docSnap = await getDoc(ref);
        if (docSnap.exists()) {
          return docSnap.data();
        } else {
          return new Device({title: 'Not Found', uuid});
        }
      },

      /**
       *
       * @param {*} [context]
       * @param {Device} device
       * @return {Promise<void>}
       */
      async addDevice({rootGetters}, device) {
        log.debug('addDevice', device);
        if (!device.uuid) {
          throw new Error(`no device uuid`);
        }
        const siteRef = rootGetters['user/siteRef'];
        if (!device.organisations) {
          const organisationRef = rootGetters['user/organisationRef'];
          device.organisations = [{ref: organisationRef}];
        }
        const ref = doc(siteRef, 'devices', device.uuid);
        await setDoc(ref, device);
      },
      /**
       *
       * @param {*} [context]
       * @param {Device} device
       * @return {Promise<void>}
       */
      async updateDevice({dispatch}, device) {
        log.debug('updateDevice', device);
        /** @type {Device} */
        await updateDoc(device.firestoreRef, device);

        // see if this device is in the store, and update that copy
        dispatch('updateRecord', device.firestoreRef);
      },
      /**
       *
       * @param {*} [context]
       * @param {Device} device
       * @return {Promise<void>}
       */
      async deleteDevice({commit}, device) {
        log.debug('deleteDevice', device);
        await deleteDoc(device.firestoreRef);
        // see if this device is in the store, and remove that copy
        commit('removeRecordByRef', device.firestoreRef);
      },
      /**
       *
       * @param {*} [context]
       * @param {Device[]} devices
       * @return {Promise<void>}
       */
      async updateDevices({dispatch}, devices) {
        log.debug('updateDevice', devices);
        const batch = writeBatch(await db);
        for (const device of devices) {
          batch.update(device.firestoreRef, device);
        }
        await batch.commit();

        // see if this device is in the store, and update that copy
        dispatch('updateRecords', devices.map(d => d.firestoreRef));
      },
      /**
       *
       * @param {*} [context]
       * @param {Device[]} devices
       * @return {Promise<void>}
       */
      async deleteDevices({commit}, devices) {
        log.debug('deleteDevices', devices);
        const refs = devices.map(d => d.firestoreRef);
        const batch = writeBatch(await db);
        for (const ref of refs) {
          batch.delete(ref);
        }
        await batch.commit();
        // see if these devices are in the store, and remove those copies
        commit('removeRecordsByRefs', refs);
      }
    }
  };
}
