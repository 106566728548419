import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VAutocomplete,{attrs:{"auto-select-first":"","chips":"","no-filter":"","loading":_vm.subsystemsSearch.loading,"label":"System (type to search)","no-data-text":"No systems found","items":_vm.subsystemsSearch.items,"item-text":"title","item-value":function (l) { return l; }},on:{"change":_vm.addSubsystem,"update:search-input":_vm.subsystemSearchInput},model:{value:(_vm.subsystemsSearch.selected),callback:function ($$v) {_vm.$set(_vm.subsystemsSearch, "selected", $$v)},expression:"subsystemsSearch.selected"}})],1),_c(VCol,{staticClass:"px-0",attrs:{"cols":"12"}},[_c(VList,{attrs:{"dense":""}},_vm._l((_vm.subsystems),function(s){return _c(VListItem,{key:s.id},[_c(VListItemTitle,[_vm._v(_vm._s(s.title))]),_c(VListItemAction,[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteInterface(s.id)}}},[_c(VIcon,[_vm._v("mdi-delete")])],1)],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }