import {decorateSnapshot} from '@/util/vuex-firestore-util';
import Vue from 'vue';
import {getDoc, getDocs, limit, orderBy, query, startAfter} from 'firebase/firestore';

/**
 * @typedef {Object} PagedListOptions
 * @property {string} [orderBy]
 * @property {number|boolean} [perPage]
 * @property {function(firebase.firestore.DocumentSnapshot): *} [transform]
 * @property {function(*,*): number} [sort]
 */

/**
 * @param {PagedListOptions} opts
 * @param {Logger} log
 * @return {Object}
 */
export function pagedListStore(opts, log) {
  /**
   * @type {function(firebase.firestore.DocumentSnapshot): *}
   */
  const transform = opts.transform || decorateSnapshot;
  const perPage = opts.hasOwnProperty('perPage') ? opts.perPage : 25;
  return {
    namespaced: true,
    state: {
      perPage,
      orderBy: opts.orderBy,
      records: [],
      /** @type {null|number} */
      lastQueryCount: null,
      /** @type {null|firebase.firestore.QueryDocumentSnapshot} */
      lastQueryDoc: null,
      // what did we use for perPage for the last query? may be different to our perPage setting
      lastQueryPerPage: perPage
    },
    getters: {
      hasMore(state) {
        if (!state.lastQueryCount) {
          return true;
        }
        // was the last page a full page?
        return (state.lastQueryPerPage + 1) === state.lastQueryCount;
      },
      hasResults(state) {
        return state.lastQueryCount !== null;
      }
    },
    mutations: {
      /**
       * @param {Object} state
       * @param {firebase.firestore.QuerySnapshot} querySnap
       */
      applySnapshot(state, querySnap) {
        state.records = state.records.concat(querySnap.docs.map(d => transform(d)));
        if (opts.sort) {
          state.records.sort(opts.sort);
        }
        state.lastQueryCount = querySnap.size;
        if (querySnap.size > 0) {
          state.lastQueryDoc = querySnap.docs[querySnap.docs.length - 1];
        } else {
          state.lastQueryDoc = null;
        }
      },
      replaceRecordAtIndex(state, {record, index}) {
        const decorated = transform(record);
        Vue.set(state.records, index, decorated);
        if (opts.sort) {
          state.records.sort(opts.sort);
        }
      },
      replaceRecordsByRefs(state, recordRefs) {
        for (const {record, ref} of recordRefs) {
          const decorated = transform(record);
          const index = state.records.findIndex(p => ref.id === p.id);
          Vue.set(state.records, index, decorated);
        }
        if (opts.sort) {
          state.records.sort(opts.sort);
        }
      },
      removeRecordAtIndex(state, {index}) {
        state.records.splice(index, 1);
      },
      removeRecordByRef(state, ref) {
        const index = state.records.findIndex(p => ref.id === p.id);
        if (index !== -1) {
          state.records.splice(index, 1);
        }
      },
      removeRecordsByRefs(state, refs) {
        for (const ref of refs) {
          const index = state.records.findIndex(p => ref.id === p.id);
          if (index !== -1) {
            state.records.splice(index, 1);
          }
        }
      },
      setPerPage(state, perPage) {
        state.perPage = perPage;
      },
      setLastQueryPerPage(state, perPage) {
        state.lastQueryPerPage = perPage;
      },
      clear(state) {
        state.records = [];
        state.lastQueryCount = null;
        state.lastQueryDoc = null;
      }
    },
    actions: {
      async nextPage({state, getters, commit, dispatch}) {
        const whereConstraints = getters.whereConstraints;
        if (!whereConstraints || !getters.hasMore) {
          return;
        }
        const col = await dispatch('collection');
        const constraints = [
          ...whereConstraints
        ];
        if (state.orderBy) {
          constraints.push(orderBy(state.orderBy));
        }
        log.debug('nextPage');
        if (state.lastQueryDoc) {
          log.debug('nextPage startAfter', state.lastQueryDoc.ref.path);
          constraints.push(startAfter(state.lastQueryDoc));
        }
        let perPage;
        if (state.perPage) {
          perPage = state.perPage;
          constraints.push(limit(perPage + 1)); // get the first from the next page)
        }
        const q = query(col, ...constraints);
        /** @type {firebase.firestore.QuerySnapshot} */
        const records = await getDocs(q);
        log.debug(`got ${records.size} records`);
        commit('applySnapshot', records);
        commit('setLastQueryPerPage', perPage);
      },

      async updateRecord({commit, state}, ref) {
        // see if this record is in the store, and update that copy
        const index = state.records.findIndex(p => ref.id === p.id);
        if (index !== -1) {
          const record = await getDoc(ref);
          commit('replaceRecordAtIndex', {record, index});
        }
      },

      async updateRecords({commit, state}, refs) {
        const recordRefs = refs.map(async ref => {
          const record = await getDoc(ref);
          return {record, ref};
        });
        commit('replaceRecordsByRefs', await Promise.all(recordRefs));
      },

      /**
       * Clear the list and fetch the first page again
       *
       * @param {*} context
       * @return {Promise}
       */
      refresh({commit, dispatch}) {
        commit('clear');
        return dispatch('nextPage');
      }
    }
  };
}
