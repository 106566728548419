import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"close-on-content-click":false,"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":"","text":""}},Object.assign({}, onTooltip, on)),[_c(VIcon,{attrs:{"color":"grey"}},[_vm._v("mdi-account-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("My Account")])])]}}]),model:{value:(_vm.menuOpen),callback:function ($$v) {_vm.menuOpen=$$v},expression:"menuOpen"}},[_c(VCard,{attrs:{"width":"300"}},[_c(VCardTitle,{staticClass:"justify-center"},[_c(VIcon,{attrs:{"size":"48"}},[_vm._v("mdi-account-circle")])],1),_c(VCardText,{staticClass:"text-center"},[_c('user-title',{staticClass:"text-left"}),_c('div',{staticClass:"my-2"},[_vm._v(_vm._s(_vm.userEmail))]),(_vm.isPasswordAccount)?_c('password-update',{attrs:{"email":_vm.userEmail}}):_vm._e()],1),_c(VCardActions,{staticClass:"justify-center"},[_c(VBtn,{attrs:{"depressed":"","block":""},on:{"click":_vm.logout}},[_vm._v("Logout")])],1),_c('about-line',{staticClass:"px-2 pb-2"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }