import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VToolbar,{staticClass:"elevation-0"},[(_vm.selectedDevices.length > 0)?_c('div',{staticClass:"d-flex flex-column"},[_c('div',[(!_vm.$vuetify.breakpoint.mobile)?_c(VBtn,{staticClass:"mr-2",attrs:{"outlined":""},on:{"click":_vm.printAll}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-printer")]),_vm._v(" Print All ")],1):_vm._e(),_c(VBtn,{attrs:{"outlined":""},on:{"click":_vm.bulkEdit}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v(" Bulk Edit ")],1)],1),_c('div',{staticClass:"grey--text"},[(_vm.selectedDevices.length === _vm.perPage)?[_vm._v(" All "+_vm._s(_vm.selectedDevices.length)+" devices on this page are selected. ")]:[_vm._v(" "+_vm._s(_vm.selectedDevices.length)+" devices selected ")]],2)]):_vm._e(),(!_vm.$vuetify.breakpoint.mobile)?_c(VSpacer):_vm._e(),(!_vm.$vuetify.breakpoint.mobile || _vm.selectedDevices.length === 0)?[_c(VSelect,{staticStyle:{"max-width":"220px"},attrs:{"multiple":"","value":_vm.filterValues,"items":_vm.filterOptions,"placeholder":"All Devices","hide-details":"","clearable":"","prepend-inner-icon":"mdi-check-circle-outline"},on:{"change":_vm.setFilters}}),_c('device-filters'),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"mx-2",attrs:{"icon":"","color":"secondary"},on:{"click":_vm.doRefresh}},on),[_c(VIcon,[_vm._v("mdi-refresh")])],1)]}}],null,false,3489094962)},[_vm._v(" Refresh the list ")]),(!_vm.disableAddDevices)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"ml-2",attrs:{"fab":"","depressed":"","small":"","color":"secondary"},on:{"click":function($event){return _vm.$root.$emit('newDevice')}}},on),[_c(VIcon,[_vm._v("mdi-plus")])],1)]}}],null,false,3136601534)},[_vm._v(" Add a Device to "+_vm._s(_vm.siteName)+" ")]):_vm._e()]:_vm._e()],2),_c(VDataTable,{class:{desktop: !_vm.$vuetify.breakpoint.mobile},attrs:{"dense":_vm.$vuetify.breakpoint.mobile,"headers":_vm.headers,"items":_vm.pageItems,"disable-sort":"","items-per-page":_vm.perPage,"options":_vm.options,"server-items-length":_vm.asDevices.length,"footer-props":{'items-per-page-options': [25, 50, 100, 200, 1000]},"loading":_vm.loading,"show-select":"","item-key":"uuid"},on:{"update:items-per-page":_vm.setPerPage,"update:options":function($event){_vm.options=$event},"pagination":_vm.onPaginationUpdate,"click:row":function($event){return _vm.$router.push({name: 'site-device', params: {id: $event.uuid}})}},scopedSlots:_vm._u([(_vm.$vuetify.breakpoint.mobile)?{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('div',{staticClass:"d-flex align-center"},[_c(VCheckbox,{staticClass:"pa-0 ma-0",attrs:{"value":isSelected,"hide-details":"","color":"grey darken-1"},on:{"click":function($event){$event.stopPropagation();},"change":select}}),_c(VSpacer),(item.isInstalled)?_c(VChip,{staticClass:"mx-1",attrs:{"color":"green","small":"","dark":""}},[_c(VIcon,{attrs:{"left":"","small":""}},[_vm._v("mdi-check-circle")]),_vm._v(" Installed ")],1):_vm._e(),(item.isLabelled)?_c(VChip,{staticClass:"ml-1",attrs:{"color":"green","small":"","dark":""}},[_c(VIcon,{attrs:{"left":"","small":""}},[_vm._v("mdi-label")]),_vm._v(" Labelled ")],1):_vm._e()],1)]}}:null,{key:"item.isInstalled",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.isInstalled)?_c(VIcon,_vm._g({attrs:{"color":"green"}},on),[_vm._v(" mdi-check-circle ")]):_vm._e()]}}],null,true)},[_vm._v(" Installed on: "+_vm._s(item.installationDateString)+" ")])]}},{key:"item.isLabelled",fn:function(ref){
var item = ref.item;
return [(item.isLabelled)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"color":"green"}},on),[_vm._v(" mdi-label ")])]}}],null,true)},[_vm._v(" Labelled on: "+_vm._s(item.labelledDateString)+" ")]):(item.notLabelReady)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"color":"warning"}},on),[_vm._v(" mdi-alert ")])]}}],null,true)},[_vm._v(" Device is missing information: "+_vm._s(item.notLabelReadySummary)+" ")]):_vm._e()]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"devicename"},[_vm._v(_vm._s(item.title))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.$root.$emit('editDevice', item)}}},[_vm._v(" mdi-pencil ")]),(!_vm.$vuetify.breakpoint.mobile)?_c(VIcon,{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.print(item)}}},[_vm._v(" mdi-printer ")]):_vm._e()]}},{key:"footer.page-text",fn:function(ref){
var pageStart = ref.pageStart;
var pageStop = ref.pageStop;
return [_vm._v(" "+_vm._s(pageStart)+"-"+_vm._s(pageStop)+" of "+_vm._s(_vm.itemCount)+" ")]}}],null,true),model:{value:(_vm.selectedDevices),callback:function ($$v) {_vm.selectedDevices=$$v},expression:"selectedDevices"}}),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }