import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{class:{'is-mobile': _vm.$vuetify.breakpoint.mobile},attrs:{"fluid":""}},[(_vm.loaded)?[_c(VToolbar,{attrs:{"flat":""}},[_c(VToolbarTitle,{staticClass:"d-flex flex-column"},[_c('span',[_vm._v(_vm._s(_vm.floor && _vm.floor.title || 'Map')+" at "+_vm._s(_vm.siteName))]),_c('span',{staticClass:"grey--text text-subtitle-2"},[_vm._v("Showing "+_vm._s(_vm.deviceMarkers.length)+" devices")])]),_c(VSpacer),(!_vm.$vuetify.breakpoint.mobile)?_c(VSelect,{staticClass:"floor-selector",attrs:{"hide-details":"","items":_vm.floorsWithPlan,"item-value":"id","item-text":"title"},model:{value:(_vm.selectedFloorId),callback:function ($$v) {_vm.selectedFloorId=$$v},expression:"selectedFloorId"}}):_vm._e(),_c('device-filters',{attrs:{"tooltip":"Device filters","store-path":"site/floors/selected/devices","floor-view":""}})],1),(_vm.floorPlan && _vm.floor)?_c('div',{staticClass:"floor-plan__container"},[(_vm.loading)?_c(VProgressLinear,{attrs:{"indeterminate":""}}):_vm._e(),_c('pinch-zoom',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var scale = ref.scale;
return [_c('div',{ref:"svgContainer",style:(Object.assign({}, {'--map-scale': scale}, _vm.floorPlanStyles)),domProps:{"innerHTML":_vm._s(_vm.floorPlan)}})]}}],null,false,2006960905)})],1):_vm._e(),(_vm.$vuetify.breakpoint.mobile)?_c(VSelect,{staticClass:"floor-selector",attrs:{"hide-details":"","items":_vm.floorsWithPlan,"item-value":"id","item-text":"title"},model:{value:(_vm.selectedFloorId),callback:function ($$v) {_vm.selectedFloorId=$$v},expression:"selectedFloorId"}}):_vm._e()]:[_c(VRow,[_c(VCol,{staticClass:"text-center",attrs:{"cols":"12"}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"48","color":"accent"}})],1)],1)],_c('router-view')],2)}
var staticRenderFns = []

export { render, staticRenderFns }